/* eslint-disable no-unused-vars */
import { Box, Button, Card, Grid, Tab, Tabs, TextField, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import activitiesTableData from "layouts/activities/data/activitiesTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCountries } from "redux/Actions/Countries";
import { fetchAllActivity, changeActivityPage } from "redux/Actions/Activities";
import { useNavigate } from "react-router-dom";
import { fetchActivityCategories } from "redux/Actions/ActivityCategory";
import ConfirmationDialog from "components/Confirmation";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Activity() {
  const dispatch = useDispatch();
  const activities = useSelector((state) => state?.activities);
  const activityPageIndex = useSelector((state) => state?.activity_page_index);
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [Columns, setColumns] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    dispatch(fetchAllActivity({ unmount: false }));
    dispatch(fetchActivityCategories({ unmount: false }));

    return () => {
      dispatch(fetchAllActivity({ unmount: true }));
    };
  }, []);

  useEffect(() => {
    if (activities?.length > 0) {
      const { columns, rows } = activitiesTableData(activities, navigate, dispatch, Rows, setRows);
      setRows(rows);
      setColumns(columns);
    }
  }, [activities]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const arr =
      activities?.length > 0
        ? [...activities]?.filter((val) =>
            val?.name_en.toLowerCase().includes(search?.toLowerCase())
          )
        : [];

    if (arr?.length > 0) {
      const { rows } = activitiesTableData(arr, navigate, dispatch);
      return setRows(rows);
    }
  }, [search]);

  useEffect(() => {
    if (Rows?.length > 0) {
      dispatch(changeActivityPage({ unmount: true }));
    }
  }, [Rows]);

  // const rows = [];
  const columns = [
    { Header: "author", accessor: "author", width: "45%", align: "left" },
    { Header: "function", accessor: "function", align: "left" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "employed", accessor: "employed", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  return (
    <Box py={5}>
      {/* <DashboardNavbar /> */}
      <MDBox>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Activities
                </MDTypography>
                <Box
                  sx={{ width: "30%", display: "flex", justifyContent: "space-between", gap: 2 }}
                >
                  <TextField
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search..."
                    InputProps={{
                      sx: {
                        "& input": {
                          background: "#FFF",
                          borderRadius: "8px",
                        },
                      },
                    }}
                  />

                  <Button
                    sx={{ background: "#000", ":hover": { background: "#212121" } }}
                    onClick={() => navigate("/activities/new")}
                  >
                    New
                  </Button>
                </Box>
              </MDBox>
              {Rows?.length > 0 && (
                <MDBox pt={3}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Russia" {...a11yProps(0)} />
                    <Tab label="UAE" {...a11yProps(1)} />
                    <Tab label="Other" {...a11yProps(2)} />
                  </Tabs>
                  <CustomTabPanel value={value} index={0}>
                    <DataTable
                      table={{
                        columns: Columns,
                        rows: Rows?.filter((val) => val?.country_id === 2),
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <DataTable
                      table={{
                        columns: Columns,
                        rows: Rows?.filter((val) => val?.country_id === 1),
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <DataTable
                      table={{
                        columns: Columns,
                        rows: Rows?.filter((val) => val?.country_id !== 2 && val?.country_id !== 1),
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </CustomTabPanel>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Box>
  );
}

export default Activity;
