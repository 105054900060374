/* eslint-disable no-unused-vars */
import { Box, Card, Grid, Tab, Tabs, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import plansTableData from "layouts/user-plans/data/plansTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCountries } from "redux/Actions/Countries";
import { fetchAllPlans } from "redux/Actions/Plans";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Country() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const plans = useSelector((state) => state?.plans);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [Columns, setColumns] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Columns, setColumns] = useState([]);

  useEffect(() => {
    dispatch(fetchAllPlans({ unmount: false }));

    return () => {
      dispatch(fetchAllPlans({ unmount: true }));
    };
  }, []);

  useEffect(() => {
    if (plans?.length > 0) {
      const { columns, rows } = plansTableData(plans, navigate);
      setRows(rows);
      setColumns(columns);
      // console.log(rows);
      // console.log(columns);
    }
  }, [plans]);

  // const rows = [];

  return (
    // <DashboardLayout>
    //   <DashboardNavbar />
    //   <MDBox py={3}>
    <Grid container py={3}>
      <Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              plans Table
            </MDTypography>
          </MDBox>
          {Rows?.length > 0 && (
            <MDBox pt={3}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    centered
                    sx={{
                      width: "50%",
                      margin: "0 auto",
                    }}
                  >
                    <Tab label="All" {...a11yProps(0)} />
                    <Tab label="Paid" {...a11yProps(1)} />
                    <Tab label="Un-paid" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <DataTable
                    table={{ columns: Columns, rows: Rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <DataTable
                    table={{ columns: Columns, rows: Rows?.filter((val) => val?.is_paid) }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <DataTable
                    table={{ columns: Columns, rows: Rows?.filter((val) => !val?.is_paid) }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </CustomTabPanel>
              </Box>
            </MDBox>
          )}
        </Card>
      </Grid>
    </Grid>
    //   </MDBox>
    // </DashboardLayout>
  );
}

export default Country;
