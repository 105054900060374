import { FETCH_ALL_ORDERS } from "../types";

export default function OrderReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_ORDERS:
      return action.payload;

    default:
      return state;
  }
}
