/* eslint-disable no-nested-ternary */
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchActivityCategory } from "redux/Actions/ActivityCategory";
import { fetchMainCategories } from "redux/Actions/MainCategory";
import Select from "react-select";
import {
  fetchQuestionnaireQuestion,
  updateQuestionnaireQuestion,
  deleteQuestionnaireAnswer,
} from "redux/Actions/Questionnaire";
import ConfirmationDialog from "components/Confirmation";
import Answers from "./Answers";
import NewAnswer from "./NewAnswerComponent";

function Edit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [Question, setQuestion] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [Options, setOptions] = useState([{ value: "select", label: "select" }]);
  const [NewAnswerUp, setNewAnswerUp] = useState([]);

  const activityCategory = useSelector((state) => state?.activity_category);
  //   const mainCategories = useSelector((state) => state?.main_categories);
  const question = useSelector((state) => state?.question);

  // console.log(mainCategories);
  useEffect(() => {
    dispatch(fetchMainCategories());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchActivityCategory({ activity_category_id: id, unmount: false }));
      dispatch(fetchQuestionnaireQuestion({ questionnaire_question_id: id }));
    }
    return () => {
      dispatch(fetchActivityCategory({ unmount: true }));
    };
  }, [id]);

  useEffect(() => {
    if (Object.keys(question)?.length > 0) {
      setQuestion({
        questionnaire_question_id: question?.questionnaire_question_id,
        question_en: question?.question_en,
        question_ar: question?.question_ar,
        type: question?.type,
        weight: question?.weight,
        // eslint-disable-next-line no-unsafe-optional-chaining
        answers: question?.answers?.length > 0 ? [...question?.answers] : [],
      });
    }
  }, [question]);

  const handleQuestionChange = (e) => {
    Question[`${e.target.name}`] = e.target.value;
    setQuestion({ ...Question });
  };

  const handleMainCategoryChange = (option) => {
    Question.main_category_id = option?.value;
    setQuestion({ ...Question });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateQuestionnaireQuestion(Question));
  };

  // eslint-disable-next-line no-shadow
  const handleDeleteAnswer = (isNew, id) => {
    if (isNew) {
      const Arr = [...NewAnswerUp];
      Arr.pop();
      setNewAnswerUp(Arr);
    } else if (!isNew) {
      // eslint-disable-next-line no-restricted-globals
      const confirmed = confirm("Are you sure you want to delete questionnaire answer ? ");
      if (confirmed) {
        dispatch(deleteQuestionnaireAnswer({ questionnaire_answer_id: id }));
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {question !== null && (question?.length > 0 || Object?.keys(question).length > 0) ? (
          <Box component="form" onSubmit={handleSubmit}>
            <Typography>Edit Question</Typography>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="filled"
                  label="Question English"
                  name="question_en"
                  type="text"
                  value={Question?.question_en}
                  onChange={(e) => handleQuestionChange(e)}
                  size="medium"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="filled"
                  label="Question Arabic"
                  name="question_ar"
                  type="text"
                  inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
                  value={Question?.question_ar}
                  onChange={(e) => handleQuestionChange(e)}
                  size="medium"
                  fullWidth
                />
              </Grid>
              {Options?.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Select
                    options={Options}
                    placeholder="Select Type..."
                    onChange={handleMainCategoryChange}
                    value={{ val: Question?.type, label: Question?.type }}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  variant="filled"
                  label="Weight"
                  name="weight"
                  value={Question?.weight}
                  onChange={(e) => handleQuestionChange(e)}
                  size="medium"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Box sx={{ width: "100%" }} py={3}>
                <Button
                  size="large"
                  sx={{
                    background: "#3E89D6",
                    color: "#FFF",
                    ":hover": {
                      background: "#7baadb",
                    },
                  }}
                  fullWidth
                  color="error"
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
              <Grid container spacing={5} pt={2}>
                {Question?.answers &&
                  Question?.answers?.length > 0 &&
                  Question?.answers?.map((val) => (
                    <Answers val={val} handleDeleteAnswer={handleDeleteAnswer} />
                  ))}
                {NewAnswerUp?.length > 0 &&
                  NewAnswerUp?.map(() => (
                    <NewAnswer
                      question={Question?.questionnaire_question_id}
                      handleDeleteAnswer={handleDeleteAnswer}
                    />
                  ))}
                <Grid item xs={12} md={6}>
                  <Button
                    onClick={() => {
                      const arr = [...NewAnswerUp, NewAnswerUp?.length];
                      setNewAnswerUp(arr);
                    }}
                  >
                    Add new answer
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : typeof activityCategory === "object" && activityCategory !== null ? (
          <Box
            sx={{
              width: "100%",
              hieght: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Loading ...</Typography>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              hieght: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Not found</Typography>
          </Box>
        )}
      </MDBox>
      <ConfirmationDialog message="Are you sure you want to close this page?" />
    </DashboardLayout>
  );
}

export default Edit;
