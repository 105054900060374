import axios from "axios";
import { FETCH_ALL_MAIN_CATEGORIES, FETCH_MAIN_CATEGORY } from "../types";
import instance, { baseURL } from "../../helpers/axiosInstance";

export const fetchMainCategories =
  (condition = {}) =>
  async (dispatch) => {
    try {
      const { data } = await instance.post("/main-categories/fetch-all", condition);

      return dispatch({
        type: FETCH_ALL_MAIN_CATEGORIES,
        payload:
          data?.main_categories.length > 0 || Object.keys(data?.main_categories).length > 0
            ? data?.main_categories
            : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_ALL_MAIN_CATEGORIES,
        payload: null,
      });
    }
  };

export const createMainCategory =
  (condition = {}, mainImage, icon) =>
  // eslint-disable-next-line consistent-return
  async () => {
    try {
      const formData = new FormData();
      const formData2 = new FormData();

      console.log(mainImage);
      if (mainImage?.length > 0) {
        formData.append(`images`, mainImage[0], mainImage[0]?.name);

        const img = await axios({
          method: "POST",
          url: `${baseURL}/upload?img_dir=activity`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });

        // eslint-disable-next-line no-unused-vars, no-param-reassign
        condition = { ...condition, image: img?.data?.upload[0] };

        // alert("Updated image successfully");
      } else {
        return alert("No image was provided");
      }

      if (icon?.length > 0) {
        formData2.append(`images`, icon[0], icon[0]?.name);

        const img = await axios({
          method: "POST",
          url: `${baseURL}/upload/svg?img_dir=svg`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData2,
        });

        // eslint-disable-next-line no-unused-vars, no-param-reassign
        condition = { ...condition, icon: img?.data?.upload[0] };

        // alert("Updated image successfully");
      } else {
        return alert("No icon was provided");
      }
      const { data } = await instance.post("/main-categories", condition);

      if (data?.status >= 200 && data?.status <= 205) {
        return alert("Created main category successfully");
      }
    } catch (error) {
      return alert("Something went wrong");
    }
  };

export const fetchMainCategory =
  (condition = {}) =>
  async (dispatch) => {
    try {
      if (condition.unmount) {
        return dispatch({
          type: FETCH_MAIN_CATEGORY,
          payload: {},
        });
      }
      const { data } = await instance.post("/main-categories/fetch", condition);

      return dispatch({
        type: FETCH_MAIN_CATEGORY,
        payload:
          data?.main_categories.length > 0 || Object.keys(data?.main_categories).length > 0
            ? data?.main_categories
            : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_MAIN_CATEGORY,
        payload: null,
      });
    }
  };

export const deleteMainCategory =
  (condition = {}) =>
  async (dispatch) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await instance.delete("/main-categories", { data: { ...condition } });

      dispatch(fetchMainCategories());
      alert("Main category deleted successfully");
    } catch (error) {
      alert("Something went wrong");
    }
  };

export const updateMainCategory =
  (condition = {}) =>
  // eslint-disable-next-line consistent-return
  async (dispatch) => {
    try {
      if (condition.unmount) {
        return dispatch({
          type: FETCH_MAIN_CATEGORY,
          payload: {},
        });
      }

      // eslint-disable-next-line no-param-reassign
      delete condition.image;
      // eslint-disable-next-line no-param-reassign
      delete condition.icon;
      const { data } = await instance.put("/main-categories", condition);

      if (data?.status >= 200 && data?.status <= 205) {
        alert("Updated main category successfully");
      }
    } catch (error) {
      alert("Something went wrong");
    }
  };

export const updateMainCategoryImage = (condition, mainImage) => async () => {
  try {
    const formData = new FormData();

    if (mainImage?.length > 0) {
      formData.append(`images`, mainImage[0], mainImage[0]?.name);

      const img = await axios({
        method: "POST",
        url: `${baseURL}/upload?img_dir=activity`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // eslint-disable-next-line no-unused-vars
      const { data } = await instance.put("/main-categories", {
        ...condition,
        image: img.data.upload[0],
      });

      alert("Updated image successfully");
    } else {
      alert("No image was provided");
    }
  } catch (error) {
    alert("Something went wrong");
  }
};

export const updateMainCategoryIcon = (condition, mainIcon) => async () => {
  try {
    const formData = new FormData();

    if (mainIcon?.length > 0) {
      formData.append(`images`, mainIcon[0], mainIcon[0]?.name);

      const img = await axios({
        method: "POST",
        url: `${baseURL}/upload/svg?img_dir=svg`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // eslint-disable-next-line no-unused-vars
      const { data } = await instance.put("/main-categories", {
        ...condition,
        icon: img.data.upload[0],
      });
      console.log(data);

      alert("Updated icon successfully");
    } else {
      alert("No icon was provided");
    }
  } catch (error) {
    alert("Something went wrong");
  }
};
export const none = () => {};
