/* eslint-disable no-nested-ternary */
import { Close } from "@mui/icons-material";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import ConfirmationDialog from "components/Confirmation";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateMainCategoryIcon,
  updateMainCategoryImage,
  updateMainCategory,
  fetchMainCategory,
} from "redux/Actions/MainCategory";

function Edit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const mainCategory = useSelector((state) => state?.main_category);

  const [Main, setMain] = useState({});
  const [Image, setImage] = useState([]);
  const [ImageFile, setImageFile] = useState(null);

  const [Icon, setIcon] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [IconFile, setIconFile] = useState(null);

  useEffect(() => {
    dispatch(fetchMainCategory({ main_category_id: id, unmount: false }));

    return () => {
      dispatch(fetchMainCategory({ main_category_id: id, unmount: true }));
    };
  }, []);

  useEffect(() => {
    if (Object.keys(mainCategory)?.length > 0) {
      setMain({
        main_category_id: mainCategory?.main_category_id,
        name_en: mainCategory?.name_en,
        name_ar: mainCategory?.name_ar,
        image: mainCategory?.image,
        icon: mainCategory?.icon,
      });
    }
  }, [mainCategory]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateMainCategory(Main));
  };

  const handleChange = (e) => {
    Main[`${e.target.name}`] = e.target.value;
    setMain({ ...Main });
  };

  const handleUpdateImage = (e) => {
    e.preventDefault();
    dispatch(updateMainCategoryImage({ main_category_id: Main?.main_category_id }, ImageFile));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }

          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setImage(image);
        setImageFile(files.filter((val) => allowedTypes.includes(val?.type)));
      })
      .catch((error) => console.error(error));
  };

  const handleUpdateIcon = (e) => {
    e.preventDefault();
    dispatch(updateMainCategoryIcon({ main_category_id: Main?.main_category_id }, IconFile));
  };

  const handleIconChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/svg+xml"];
    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types is: svg");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setIcon(image);
        setIconFile(files.filter((val) => allowedTypes.includes(val?.type)));
      })
      .catch((error) => console.error(error));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box>
        {Main?.image ? (
          <Box
            sx={{
              background: `url("${Main?.image}") center center / cover no-repeat`,
              height: "300px",
              width: "100%",
              position: "relative",
              borderRadius: "15px",
            }}
          >
            <Box
              sx={{
                background: "#212121",
                position: "absolute",
                right: "10px",
                top: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "2px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setMain({ ...Main, image: null });
              }}
            >
              <Close color="error" />
            </Box>
          </Box>
        ) : !Main?.image && Image?.length > 0 ? (
          Image?.map((val) => (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: "300px",
                  borderRadius: "20px",
                  background: `url("${val}") center center / cover no-repeat`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  cursor: "pointer",
                }}
                //   onClick={() => handleShowImage(val)}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    background: "#212121",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "15px",
                    padding: "2px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setImage([]);
                    setImageFile(null);
                  }}
                >
                  <Close color="error" />
                </Box>
              </Box>
              <Button
                size="large"
                sx={{
                  background: "#3E89D6",
                  color: "#FFF",
                  ":hover": {
                    background: "#7baadb",
                  },
                  mt: 3,
                }}
                fullWidth
                color="error"
                onClick={handleUpdateImage}
              >
                Change image
              </Button>
            </>
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "300px",
              borderRadius: "20px",
              background: `linear-gradient(to right, #2193b0, #6dd5ed)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Typography color="#FFF" sx={{ position: "absolute", top: "45%", left: "43%" }}>
              Upload Image
            </Typography>
            <input
              type="file"
              accept="image/jpeg,image/jpg,image/png"
              style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
              onChange={handleImageChange}
            />
          </Box>
        )}

        <Box sx={{ mt: "50px" }} component="form" onSubmit={handleSubmit}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <TextField
                name="name_en"
                required
                label="Name English"
                size="medium"
                variant="filled"
                fullWidth
                value={Main?.name_en}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="name_ar"
                required
                size="medium"
                variant="filled"
                label="Name Arabic"
                inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
                value={Main?.name_ar}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
            size="large"
            sx={{
              background: "#3E89D6",
              color: "#FFF",
              ":hover": {
                background: "#7baadb",
              },
              mt: 3,
            }}
            fullWidth
            color="error"
            type="submit"
          >
            Submit
          </Button>
        </Box>
        {Main?.icon ? (
          <Box sx={{ padding: "10px" }}>
            <Typography>Icon</Typography>
            <Box sx={{ height: "150px", width: "150px", mt: 1, position: "relative" }}>
              <img src={Main?.icon} alt="" width="140px" height="140px" />
              <Box
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "#212121",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "15px",
                  padding: "2px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setMain({ ...Main, icon: null });
                }}
              >
                <Close color="error" />
              </Box>
            </Box>
          </Box>
        ) : !Main?.icon && Icon?.length > 0 ? (
          Icon?.map((val) => (
            <Box sx={{ padding: "10px" }}>
              <Typography>Icon</Typography>
              <Box sx={{ height: "150px", width: "150px", mt: 1, position: "relative" }}>
                <img src={val} alt="" width="140px" height="140px" />
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    background: "#212121",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "15px",
                    padding: "2px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIcon([]);
                    setIconFile(null);
                  }}
                >
                  <Close color="error" />
                </Box>
                <Button
                  size="large"
                  sx={{
                    background: "#3E89D6",
                    color: "#FFF",
                    ":hover": {
                      background: "#7baadb",
                    },
                    mt: 3,
                  }}
                  fullWidth
                  color="error"
                  onClick={handleUpdateIcon}
                >
                  Change icon
                </Button>
              </Box>
            </Box>
          ))
        ) : (
          <Box sx={{ padding: "10px" }}>
            <Box
              sx={{
                width: "150px",
                height: "150px",
                borderRadius: "20px",
                background: `linear-gradient(to right, #2193b0, #6dd5ed)`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                mt: 1,
              }}
            >
              <Typography color="#FFF" sx={{ position: "absolute", top: "30%", left: "30%" }}>
                Upload Icon
              </Typography>
              <input
                type="file"
                accept="image/svg+xml"
                style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
                onChange={handleIconChange}
              />
            </Box>
          </Box>
        )}
      </Box>
      <ConfirmationDialog message="Are you sure you want to close this page?" />
    </DashboardLayout>
  );
}

export default Edit;
