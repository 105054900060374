import { FETCH_ALL_CITIES } from "../types";
import instance from "../../helpers/axiosInstance";

export const fetchAllCities =
  (condition = {}) =>
  async (dispatch) => {
    try {
      if (condition.unmount) {
        return dispatch({
          type: FETCH_ALL_CITIES,
          payload: {},
        });
      }

      const { data } = await instance.get("/cities");

      return dispatch({
        type: FETCH_ALL_CITIES,
        payload:
          data?.cities.length > 0 || Object.keys(data?.cities).length > 0 ? data?.cities : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_ALL_CITIES,
        payload: null,
      });
    }
  };

export const fetchCity = () => {};
