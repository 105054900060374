import { Box, IconButton, TablePagination, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosInstance from "helpers/axiosInstance";
import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Download } from "@mui/icons-material";

function HotelOrders() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsTotal, setRowsTotal] = React.useState(null);
  const [rows, setRows] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleInvoiceDownload = async (id) => {
    try {
      const { data } = await axiosInstance.post("/pdf/crm", {
        order_id: id,
      });

      console.log(data)

      var newWindow = window.open(data?.url, "_blank");

      newWindow?.focus();
    } catch (error) {
      console.log(error);
      alert("Something went wrong");
    }
  };

  const fetchRateHawkOrders = async () => {
    setLoading(true);

    let reqBody = {
      ordering: {
        ordering_type: "desc",
        ordering_by: "created_at",
      },
      pagination: {
        page_size: rowsPerPage,
        page_number: page + 1,
      },
    };

    try {
      const { data } = await axiosInstance.post(`/ratehawk/order-information`, reqBody);

      if (data?.data?.orders?.length > 0) {
        const orders = data?.data?.orders;
        let orderRows = orders?.map((val, i) => {
          const guest = val?.rooms_data[0]?.guest_data?.guests[0];

          return createData({
            id: i + 1,
            guest: `${guest?.first_name} ${guest?.last_name}`,
            hotel_id: val?.hotel_data?.id,
            price: val?.amount_payable?.amount,
            order_id: val?.order_id,
            status: val?.status,
          });
        });

        let mapRes = await Promise.all(orderRows);

        setRows(mapRes);
        setRowsTotal(data?.data?.found_orders);
        return setLoading(false);
      }
    } catch (error) {
      alert("Something went wrong");
      return setLoading(false);
    }
  };

  useEffect(() => {
    fetchRateHawkOrders();
  }, []);

  useEffect(() => {
    fetchRateHawkOrders();
  }, [rowsPerPage, page]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box py={3}>
        <Typography variant="h3" my={2}>
          Hotel Orders
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow component={Paper}>
                <TableCell>ID</TableCell>
                <TableCell>Guest</TableCell>
                <TableCell align="left">Hotel ID</TableCell>
                <TableCell align="left">Price</TableCell>
                <TableCell align="left">Order ID</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Invoice</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography>Loading...</Typography>
              </Box>
            ) : (
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.guest}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.hotel_id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.price}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.order_id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.status}
                    </TableCell>
                    <TableCell align="left">
                      <IconButton onClick={() => handleInvoiceDownload(row.order_id)}>
                        <Download />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component={Paper}
            sx={{ margin: "10px 0px", borderRadius: "10px" }}
            count={rowsTotal}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default HotelOrders;

function createData({ id, guest, hotel_id, price, order_id, status, is_paid, invoice }) {
  return { id, guest, hotel_id, price, order_id, status, is_paid, invoice };
}
