import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import instance from "helpers/axiosInstance";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { createBooking } from "redux/Actions/Bookings";

function New() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [booking, setBooking] = useState({
    name: "",
    email: "",
    phone: "",
    checkIn: "",
    checkOut: "",
    bookedFrom: "",
    type: "",
    link: "",
    refundable: false,
    payment_id: "",
    isPaid: false,
    providerPrice: "",
    customerPrice: "",
    message: "",
  });

  const options = [
    { value: "rateHawk", label: "Rate Hawk" },
    { value: "parto_crs", label: "Parto CRS" },
    { value: "booking", label: "Booking.com" },
    { value: "other", label: "Other" },
  ];

  const options2 = [
    { value: "flight", label: "Flight" },
    { value: "hotel", label: "Hotel" },
    { value: "both", label: "Both" },
  ];

  const handleBookingChange = (e) => {
    let newBooking = { ...booking };
    newBooking[`${e.target.name}`] = e.target.value;
    setBooking({ ...newBooking });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createBooking(booking, navigate));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box py={5}>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Typography variant="h5">Customer information</Typography>
          <Grid container py={1} spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Full name"
                name="name"
                type="text"
                fullWidth
                value={booking?.name}
                size="medium"
                onChange={handleBookingChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="email"
                name="email"
                type="email"
                fullWidth
                value={booking?.email}
                size="medium"
                onChange={handleBookingChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Phone"
                name="phone"
                type="text"
                fullWidth
                value={booking?.phone}
                size="medium"
                onChange={handleBookingChange}
              />
            </Grid>
          </Grid>
          <Typography variant="h5" py={1}>
            Booking information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Check in date"
                  renderInput={(props) => <TextField {...props} variant="filled" fullWidth />}
                  onChange={(date) => setBooking({ ...booking, checkIn: date })}
                  value={booking?.checkIn}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Check out date"
                  renderInput={(props) => <TextField {...props} variant="filled" fullWidth />}
                  onChange={(date) => setBooking({ ...booking, checkOut: date })}
                  value={booking?.checkOut}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Description"
                name="description"
                type="text"
                multiline
                rows={3}
                fullWidth
                value={booking?.description}
                onChange={handleBookingChange}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                options={options}
                placeholder="Booked from"
                onChange={(e) => setBooking({ ...booking, bookedFrom: e.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                options={options2}
                placeholder="Flight or Hotel"
                onChange={(e) => setBooking({ ...booking, type: e.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Hotel or room Link"
                name="link"
                type="text"
                fullWidth
                value={booking?.link}
                // value={Activity?.name_en}
                onChange={handleBookingChange}
                // onChange={(e) => handleActivityChange(e)}
                size="small"
                // sx={{ width: "50%" }}
              />
            </Grid>
            <Box sx={{ width: "fit-content", px: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={booking.refundable}
                    onChange={(e) => {
                      setBooking((prev) => ({ ...prev, refundable: !prev?.refundable }));
                    }}
                  />
                }
                label="refundable"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={booking.isPaid}
                    onChange={(e) => setBooking((prev) => ({ ...prev, isPaid: !prev.isPaid }))}
                  />
                }
                label="is paid"
              />
            </Box>
          </Grid>
          <Typography variant="h5" py={1}>
            Payment information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Payment message"
                name="message"
                type="text"
                fullWidth
                value={booking?.message}
                onChange={handleBookingChange}
                // onChange={(e) => handleActivityChange(e)}
                size="medium"
                // sx={{ width: "50%" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Provider price"
                name="providerPrice"
                type="number"
                fullWidth
                value={booking?.providerPrice}
                onChange={handleBookingChange}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Customer price"
                name="customerPrice"
                type="number"
                fullWidth
                value={booking?.customerPrice}
                onChange={handleBookingChange}
                size="medium"
              />
            </Grid>
          </Grid>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
            pt={3}
          >
            <Button
              type="submit"
              sx={{
                width: "50%",
                background: "#3E89D6",
                color: "#fff",
                ":hover": {
                  background: "rgba(62, 137, 214, 0.7)",
                  color: "#fff",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default New;
