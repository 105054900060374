import instance from "helpers/axiosInstance";
import { FETCH_ALL_BOOKINGS, FETCH_BOOKING } from "redux/types";
import {} from "redux/types";

export const fetchBooking =
  (condition = {}) =>
  async (dispatch) => {
    try {
      if (condition.unmount) {
        return dispatch({
          type: FETCH_BOOKING,
          payload: {},
        });
      }

      const { data } = await instance.post("/bookings/fetch", condition);
      return dispatch({
        type: FETCH_BOOKING,
        payload:
          data?.booking.length > 0 || Object.keys(data?.booking).length > 0 ? data?.booking : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_BOOKING,
        payload: null,
      });
    }
  };

export const updateBooking = (condition) => async (dispatch) => {
  try {
    const { data } = await instance.put("/bookings", condition);
    if (!condition?.isPaid) {
      alert("Updated booking successfully");
      dispatch(fetchBooking({ id: condition?.id }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const createBooking = (condition, navigate) => async (dispatch) => {
  try {
    if (!condition?.isPaid) {
      const { data } = await instance.post("/ziina/create-payment", {
        message: condition?.message,
        amount: Number(`${condition?.customerPrice}00`),
        version: "1.1.3",
        currency_code: "AED",
      });

      await instance.post("/bookings", { ...condition, payment_id: data?.payment?.id });
      alert("Created booking successfully");
      navigate("/booking");
    } else {
      await instance.post("/bookings", { ...condition, payment_id: null });
      alert("Created booking successfully");
      navigate("/booking");
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchAllBookings =
  (condition = {}) =>
  async (dispatch) => {
    try {
      if (condition.unmount) {
        return dispatch({
          type: FETCH_ALL_BOOKINGS,
          payload: {},
        });
      }

      const { data } = await instance.post("/bookings/fetch-all");

      return dispatch({
        type: FETCH_ALL_BOOKINGS,
        payload:
          data?.booking.length > 0 || Object.keys(data?.booking).length > 0 ? data?.booking : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_ALL_BOOKINGS,
        payload: null,
      });
    }
  };
