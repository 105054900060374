import { FETCH_ALL_BOOKINGS, FETCH_BOOKING } from "../types";

export default function BookingReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_BOOKINGS:
      return action.payload;

    default:
      return state;
  }
}
