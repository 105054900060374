import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import instance from "../../helpers/axiosInstance";
import React, { useEffect, useState } from "react";
import Activity from ".";

function ActivityDashboard() {
  const [activitiesCount, setActivitiesCount] = useState(0);

  useEffect(() => {
    instance.post("/dashboard/activities/count").then(({ data }) => {
      setActivitiesCount(data?.activities);
    });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="rowing"
                title="Activities"
                count={activitiesCount || 0}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Activity />
      </MDBox>
    </DashboardLayout>
  );
}

export default ActivityDashboard;
