import { FETCH_ALL_ACTIVITY, MODIFY_ACTIVITY_STATE } from "../types";

export default function ActivityReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_ACTIVITY:
      return action.payload;
    case MODIFY_ACTIVITY_STATE:
      const newState = state?.map((val) => {
        if (val?.activity_id === action?.payload?.activity_id) {
          return action?.payload;
        } else {
          return val;
        }
      });

      return newState;

    default:
      return state;
  }
}
