import { FETCH_PLAN } from "../types";

export default function UserPlanReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_PLAN:
      return action.payload;
    default:
      return state;
  }
}
