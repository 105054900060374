/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  // FormControl,
  Grid,
  Switch,
  // InputLabel,
  // MenuItem,
  // Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import Select from "react-select";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../css/index.css";
import { fetchAllCities } from "redux/Actions/Cities";

// import { createBranchWithWorkingHours } from "redux/Actions/Branch";
// import { updateBranch } from "redux/Actions/Branch";
// import { updateWorkingHoursBulk } from "redux/Actions/WorkingHours";

function BranchComponent({ Branch, setBranch, branchIdx, oneBranch }) {
  const dispatch = useDispatch();
  const [CitiesOptions, setCitiesOptions] = useState(null);
  const [WorkingHours, setWorkingHours] = useState([
    {
      day_of_week: 1,
      opening_time: "09:00:00",
      closing_time: "16:00:00",
    },
    {
      day_of_week: 2,
      opening_time: "09:00:00",
      closing_time: "16:00:00",
    },
    {
      day_of_week: 3,
      opening_time: "09:00:00",
      closing_time: "16:00:00",
    },
    {
      day_of_week: 4,
      opening_time: "09:00:00",
      closing_time: "16:00:00",
    },
    {
      day_of_week: 5,
      opening_time: "09:00:00",
      closing_time: "16:00:00",
    },
    {
      day_of_week: 6,
      opening_time: "09:00:00",
      closing_time: "16:00:00",
    },
    {
      day_of_week: 7,
      opening_time: "09:00:00",
      closing_time: "16:00:00",
    },
  ]);
  const [Options, setOptions] = useState({});
  const [DayToAdd, setDayToAdd] = useState(null);
  const [BoolAddDay, setBoolAddDay] = useState(null);
  const [ChangeAll, setChangeAll] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const cities = useSelector((state) => state.cities);
  // eslint-disable-next-line no-unused-vars
  const [days, setDays] = useState([
    { id: 1, day: "Sunday", day_of_week: 1 },
    { id: 2, day: "Monday", day_of_week: 2 },
    { id: 3, day: "Tuesday", day_of_week: 3 },
    { id: 4, day: "Wednesday", day_of_week: 4 },
    { id: 5, day: "Thursday", day_of_week: 5 },
    { id: 6, day: "Friday", day_of_week: 6 },
    { id: 7, day: "Saturday", day_of_week: 7 },
  ]);

  useEffect(() => {
    if (cities?.length > 0) {
      setCitiesOptions(
        cities?.map((val) => ({
          value: val?.city_id,
          label: val?.name_en,
        }))
      );
    }
  }, [cities]);

  useEffect(() => {
    dispatch(fetchAllCities({ unmount: false }));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // dispatch(
    //   createBranchWithWorkingHours({
    //     ...Branch,
    //     activity_id: activity?.activity_id,
    //     working_days: WorkingHours,
    //     city_id: 1,
    //   })
    // );
    // dispatch(updateWorkingHoursBulk(WorkingHours));
  };

  const deleteDay = (day) => {
    // const newDays = [...days];
    // setDays(newDays.filter((val) => val?.id !== day));
    const newWorkingHours = [...WorkingHours];
    setWorkingHours(newWorkingHours?.filter((value) => value?.day_of_week !== day));
    // console.log(newDays?.indexOf((val) => val?.id === day));
    // eslint-disable-next-line dot-notation, no-param-reassign
    Branch[branchIdx]["working_days"] = newWorkingHours?.filter(
      (value) => value?.day_of_week !== day
    );
    setBranch([...Branch]);
  };

  const handleChangeWorkingHoursOpening = (date, day) => {
    if (!moment(date, "YYYY-MM-DD").isValid()) {
      return;
    }
    if (ChangeAll) {
      // eslint-disable-next-line array-callback-return
      const newArray = WorkingHours?.map((val) => ({
        ...val,
        opening_time: moment(date).format("HH:mm:ss"),
      }));
      setWorkingHours(newArray);
      // eslint-disable-next-line no-param-reassign
      Branch[branchIdx].working_days = newArray;
      // eslint-disable-next-line consistent-return
      return setBranch([...Branch]);
    }
    const index = WorkingHours?.findIndex((val) => val?.day_of_week === day);
    const objArr = WorkingHours?.filter((val) => val?.day_of_week === day);

    if (index < 0) return;

    objArr[0].opening_time = moment(date).format("HH:mm:ss");

    // eslint-disable-next-line no-unsafe-optional-chaining
    const newArray = [...WorkingHours?.filter((val) => val?.day_of_week !== day), objArr[0]];
    setWorkingHours(newArray);
    // eslint-disable-next-line dot-notation, no-param-reassign
    Branch[branchIdx]["working_days"] = newArray;
    setBranch([...Branch]);
  };

  // console.log(Branch);

  const handleChangeWorkingHoursClosing = (date, day) => {
    if (!moment(date, "YYYY-MM-DD").isValid()) {
      return;
    }

    if (ChangeAll) {
      // eslint-disable-next-line array-callback-return
      const newArray = WorkingHours?.map((val) => ({
        ...val,
        closing_time: moment(date).format("HH:mm:ss"),
      }));
      setWorkingHours(newArray);
      // eslint-disable-next-line no-param-reassign
      Branch[branchIdx].working_days = newArray;
      // eslint-disable-next-line consistent-return
      return setBranch([...Branch]);
    }

    const index = WorkingHours?.findIndex((val) => val?.day_of_week === day);
    const objArr = WorkingHours?.filter((val) => val?.day_of_week === day);

    let obj = {};

    if (index < 0) return;
    if (day?.length > 0) {
      // eslint-disable-next-line prefer-destructuring, no-unused-vars
      obj = objArr[0];
    }
    objArr[0].closing_time = moment(date).format("HH:mm:ss");

    // eslint-disable-next-line no-unsafe-optional-chaining
    const newArray = [...WorkingHours?.filter((val) => val?.day_of_week !== day), objArr[0]];
    setWorkingHours(newArray);
    // eslint-disable-next-line dot-notation, no-param-reassign
    Branch[branchIdx]["working_days"] = newArray;
    setBranch([...Branch]);
  };

  useEffect(() => {
    const arr = days?.filter((val) => {
      const index = WorkingHours?.findIndex((workDay) => val?.day_of_week === workDay?.day_of_week);
      return !(index >= 0);
    });
    if (arr?.length > 0) {
      const mapArr = arr.map((newValue) => ({
        value: newValue?.day_of_week,
        label: newValue?.day,
      }));
      setOptions(mapArr);
    }
  }, [WorkingHours]);

  const handleDayToAddChange = (option) => {
    const obj = {
      day_of_week: option?.value,
      opening_time: "09:00:00",
      closing_time: "16:00:00",
    };

    setDayToAdd(obj);
  };

  const renderInput = (props) => (
    <TextField fullWidth variant="filled" label="Name english" {...props} />
  );

  const handleBranchChange = (e) => {
    // eslint-disable-next-line no-param-reassign
    Branch[branchIdx][`${e.target.name}`] = e.target.value;
    setBranch([...Branch]);
  };

  const handleBranchChangeSwitch = (e) => {
    // eslint-disable-next-line no-param-reassign
    Branch[branchIdx][`${e.target.name}`] = e.target.checked;
    setBranch([...Branch]);
  };

  const handleTimeChange = (date, name) => {
    if (!moment(date, "HH:mm:ss").isValid()) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    Branch[branchIdx][`${name}`] = date;
    setBranch([...Branch]);
  };

  // const handleSelectChange = (e) => {
  //   Branch[`${name}`] = date;
  //   setBranch({ ...Branch });
  // };

  const options = [
    { value: "all", label: "All" },
    { value: "winter", label: "Winter" },
    { value: "summer", label: "Summer" },
  ];

  const handlePreferSeasonChange = (option) => {
    // eslint-disable-next-line no-param-reassign, dot-notation
    Branch[branchIdx]["prefered_season"] = option?.value;
    setBranch([...Branch]);
  };

  const handleCityChange = (option) => {
    // eslint-disable-next-line no-param-reassign, dot-notation
    Branch[branchIdx]["city_id"] = option?.value;
    setBranch([...Branch]);
  };
  // eslint-disable-next-line camelcase, no-unused-vars
  const renderDays = (day, startTime, endTime) => (
    <Grid container spacing={2} pt={2}>
      {days?.filter((value) => value?.id === day)?.length > 0 && (
        <>
          <Grid item xs={12} md={6}>
            <Typography>{days.filter((val) => val?.id === day)[0]?.day}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  value={moment(startTime, "HH:mm:ss").toDate()}
                  defaultValue={moment()}
                  renderInput={renderInput}
                  onChange={(date) => handleChangeWorkingHoursOpening(date, day)}
                  label="Start time"
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  value={moment(endTime, "HH:mm:ss").toDate()}
                  defaultValue={moment("07:00:00 PM", "HH:mm:ss").toDate()}
                  renderInput={renderInput}
                  onChange={(date) => handleChangeWorkingHoursClosing(date, day)}
                  label="End time"
                />
              </LocalizationProvider>
              <Box
                sx={{
                  background: "#212121",
                  display: "flex",
                  alignItems: "center",
                  padding: "2px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => deleteDay(day)}
              >
                <Delete color="error" />
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );

  const handleNewChangeWorkingHoursOpening = (date) => {
    if (!moment(date, "YYYY-MM-DD").isValid()) {
      return;
    }

    const obj = { ...DayToAdd, opening_time: moment(date).format("HH:mm:ss") };
    setDayToAdd(obj);
  };

  const handleNewChangeWorkingHoursClosing = (date) => {
    if (!moment(date, "YYYY-MM-DD").isValid()) {
      return;
    }
    const obj = { ...DayToAdd, closing_time: moment(date).format("HH:mm:ss") };
    setDayToAdd(obj);
  };

  const renderAddedDays = (day, startTime, endTime) => (
    <Grid container spacing={2} pt={2}>
      {days?.filter((value) => value?.id === day)?.length > 0 && (
        <>
          <Grid item xs={12} md={6}>
            <Typography>{days.filter((val) => val?.id === day)[0]?.day}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  value={moment(startTime, "HH:mm:ss").toDate()}
                  defaultValue={moment()}
                  renderInput={renderInput}
                  onChange={(date) => handleNewChangeWorkingHoursOpening(date, day)}
                  label="Start time"
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  value={moment(endTime, "HH:mm:ss").toDate()}
                  defaultValue={moment("07:00:00 PM", "HH:mm:ss").toDate()}
                  renderInput={renderInput}
                  onChange={(date) => handleNewChangeWorkingHoursClosing(date, day)}
                  label="End time"
                />
              </LocalizationProvider>
              <Box
                sx={{
                  background: "#212121",
                  display: "flex",
                  alignItems: "center",
                  padding: "2px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => deleteDay(day)}
              >
                <Delete color="error" />
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );

  const handleAddWorkDays = () => {
    if (!DayToAdd) {
      return;
    }

    setWorkingHours([...WorkingHours, DayToAdd]);
    setDayToAdd(null);
    setBoolAddDay(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography>Details</Typography>
      <Grid container pt={2} spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="name_en"
            value={oneBranch?.name_en}
            onChange={(e) => handleBranchChange(e)}
            variant="filled"
            label="Address in english"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="name_ar"
            fullWidth
            value={oneBranch?.branch_name_ar}
            onChange={(e) => handleBranchChange(e)}
            variant="filled"
            label="Address in arabic"
            inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
          />
        </Grid>
      </Grid>
      {/* <Typography pt={2}>Time</Typography> */}
      {/* <Grid container spacing={2} pt={2}>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileTimePicker
              value={moment(Branch?.prefered_start_time, "HH:mm:ss").toDate()}
              name="prefered_start_time"
              renderInput={(props) => <TextField {...props} variant="filled" fullWidth />}
              onChange={(date) =>
                handleTimeChange(moment(date).format("HH:mm:ss"), "prefered_start_time")
              }
              label="Prefered start time"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileTimePicker
              value={moment(Branch?.prefered_end_time, "HH:mm:ss").toDate()}
              name="prefered_end_time"
              renderInput={(props) => <TextField {...props} variant="filled" fullWidth />}
              onChange={(date) =>
                handleTimeChange(moment(date).format("HH:mm:ss"), "prefered_end_time")
              }
              label="Prefered end time"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth sx={{ height: "50px" }}>
            <InputLabel id="demo-simple-select-label">Prefered season</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={Branch?.prefered_season}
              label="Prefered season"
              size="medium"
              variant="filled"
              name="prefered_season"
              sx={{ height: "100%" }}
              onChange={(e) => handleBranchChange(e)}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="winter">Winter</MenuItem>
              <MenuItem value="summer">Summer</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid> */}
      <Typography pt={2}>For CRM Usage</Typography>
      <Grid container pt={1} spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="supplier_website"
            value={Branch?.supplier_website}
            onChange={(e) => handleBranchChange(e)}
            variant="filled"
            label="Supplier website"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="supplier_phone_number"
            fullWidth
            value={Branch?.supplier_phone_number}
            onChange={(e) => handleBranchChange(e)}
            variant="filled"
            label="Supplier phone number"
            // inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
          />
        </Grid>
      </Grid>

      <Typography pt={2}>Working days</Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Checkbox
          checked={ChangeAll}
          onChange={() => setChangeAll(!ChangeAll)}
          inputProps={{ "aria-label": "controlled" }}
          id="change-all-working-days"
        />
        <label htmlFor="change-all-working-days">Change all working days together</label>
      </Box>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12}>
          {WorkingHours?.length < 7 && (
            <Box py={2}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={() => setBoolAddDay(!BoolAddDay)}>
                  {BoolAddDay ? "Cancel" : "Add work day"}
                </Button>
              </Box>
              {BoolAddDay && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Select
                        placeholder="Select a day"
                        options={Options}
                        onChange={(option) => handleDayToAddChange(option)}
                      />
                    </Grid>
                    {DayToAdd && (
                      <Grid item xs={12}>
                        {renderAddedDays(
                          DayToAdd?.day_of_week,
                          DayToAdd?.opening_time,
                          DayToAdd?.closing_time
                        )}
                      </Grid>
                    )}
                  </Grid>
                  {DayToAdd && (
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} pt={2}>
                      <Button
                        size="large"
                        sx={{
                          width: "30%",
                          background: "#3E89D6",
                          color: "#FFF",
                          ":hover": {
                            background: "#7baadb",
                          },
                        }}
                        fullWidth
                        color="error"
                        onClick={() => handleAddWorkDays()}
                      >
                        Save work day
                      </Button>
                    </Box>
                  )}
                  <Divider />
                </>
              )}
            </Box>
          )}

          {WorkingHours.length > 0 &&
            WorkingHours?.sort((a, b) => a.day_of_week - b.day_of_week)?.map((val) =>
              renderDays(
                val?.day_of_week,
                val?.opening_time || moment("09:00:00", "HH:mm:ss").toDate(),
                val?.closing_time || moment("16:00:00", "HH:mm:ss").toDate()
              )
            )}
        </Grid>
      </Grid>
      <Typography pt={2}>Price</Typography>
      <Grid container spacing={2} sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            type="number"
            value={oneBranch?.children_price}
            variant="filled"
            name="children_price"
            label="Price for children"
            onChange={(e) => handleBranchChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={oneBranch?.allowed_for_children}
                onChange={handleBranchChangeSwitch}
                defaultChecked
                name="allowed_for_children"
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Allowed for children"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            type="number"
            value={oneBranch?.teenager_price}
            variant="filled"
            name="teenager_price"
            label="Price for teenagers"
            onChange={(e) => handleBranchChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={oneBranch?.allowed_for_teenagers}
                onChange={handleBranchChangeSwitch}
                defaultChecked
                name="allowed_for_teenagers"
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Allowed for teenagers"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={oneBranch?.adult_price}
            type="number"
            variant="filled"
            name="adult_price"
            label="Price for adults"
            onChange={(e) => handleBranchChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={oneBranch?.allowed_for_adults}
                onChange={handleBranchChangeSwitch}
                defaultChecked
                name="allowed_for_adults"
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Allowed for adults"
          />
        </Grid>
      </Grid>
      <Typography pt={2}>Time</Typography>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileTimePicker
              value={moment(oneBranch?.prefered_start_time, "HH:mm:ss").toDate()}
              name="prefered_start_time"
              renderInput={(props) => (
                <TextField {...props} variant="filled" fullWidth error={false} />
              )}
              onChange={(date) =>
                handleTimeChange(moment(date).format("HH:mm:ss"), "prefered_start_time")
              }
              label="Prefered start time"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileTimePicker
              value={moment(oneBranch?.prefered_end_time, "HH:mm:ss").toDate()}
              name="prefered_end_time"
              renderInput={(props) => (
                <TextField {...props} variant="filled" fullWidth error={false} />
              )}
              onChange={(date) =>
                handleTimeChange(moment(date).format("HH:mm:ss"), "prefered_end_time")
              }
              label="Prefered end time"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>Prefered Season</label>
          <Select
            options={options}
            placeholder="Select Prefered Season..."
            onChange={handlePreferSeasonChange}
          />
        </Grid>
      </Grid>
      <Typography pt={2}>Location</Typography>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="latitude"
            type="number"
            value={oneBranch?.latitude}
            variant="filled"
            label="Latitude"
            onChange={(e) => handleBranchChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="longitude"
            type="number"
            value={oneBranch?.longitude}
            variant="filled"
            label="Longitude"
            onChange={(e) => handleBranchChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={oneBranch?.google_map_link}
            variant="filled"
            name="google_map_link"
            label="Google map link"
            onChange={(e) => handleBranchChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={oneBranch?.phone_number}
            variant="filled"
            name="phone_number"
            label="Branch phone number"
            onChange={(e) => handleBranchChange(e)}
          />
        </Grid>
        {CitiesOptions?.length > 0 && (
          <Grid item xs={12} md={6} pt={2}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>City</label>
            <Select
              id="city"
              options={CitiesOptions}
              placeholder="Select a city..."
              onChange={handleCityChange}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default BranchComponent;
