/* eslint-disable no-unused-vars */
import { Box, Button, Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import usersTableData from "layouts/users/data/usersTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCountries } from "redux/Actions/Countries";
import { fetchMainCategories } from "redux/Actions/MainCategory";
import { useNavigate } from "react-router-dom";
import { fetchUsers } from "redux/Actions/User";

function Country() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state?.users);

  // const [Columns, setColumns] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    dispatch(fetchUsers({ unmount: false }));

    return () => {
      dispatch(fetchUsers({ unmount: true }));
    };
  }, []);

  useEffect(() => {
    if (users?.length > 0) {
      const { columns, rows } = usersTableData(users, navigate, dispatch);
      setRows(rows);
      setColumns(columns);
      // console.log(rows);
      // console.log(columns);
    }
  }, [users]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const arr =
      users?.length > 0
        ? [...users]?.filter(
            (val) =>
              val?.first_name.toLowerCase().includes(search?.toLowerCase()) ||
              val?.last_name.toLowerCase().includes(search?.toLowerCase()) ||
              val?.username.toLowerCase().includes(search?.toLowerCase())
          )
        : [];

    if (arr?.length > 0) {
      const { rows } = usersTableData(arr, navigate, dispatch);
      return setRows(rows);
    }
  }, [search]);

  return (
    <Grid container py={5}>
      <Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <MDTypography variant="h6" color="white">
              Users Table
            </MDTypography>
            <Box sx={{ width: "30%", display: "flex", justifyContent: "space-between", gap: 2 }}>
              <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search..."
                InputProps={{
                  sx: {
                    "& input": {
                      background: "#FFF",
                      borderRadius: "8px",
                    },
                  },
                }}
              />
              <Button
                sx={{ background: "#000", ":hover": { background: "#212121" } }}
                onClick={() => navigate("/users/new")}
              >
                New
              </Button>
            </Box>
          </MDBox>
          {Rows?.length > 0 && (
            <MDBox pt={3}>
              <DataTable
                table={{ columns: Columns, rows: Rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

export default Country;
