/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Delete } from "@mui/icons-material";
import { deleteMainCategory } from "redux/Actions/MainCategory";

export default function data(categories, navigate, dispatch) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );
  const handleDeleteMainCategory = (id) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      "Are you sure you want to delete this main category? , this will delete all data associated with it"
    );
    if (confirmed) {
      dispatch(deleteMainCategory({ main_category_id: id }));
    }
  };

  return {
    columns: [
      { Header: "ID", accessor: "id", width: "10%", align: "left" },
      { Header: "Name", accessor: "name", width: "44%", align: "left" },
      { Header: "Edit", accessor: "edit", align: "center" },
      { Header: "Delete", accessor: "delete", align: "center" },
    ],

    rows: categories?.map((value) => ({
      id: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.main_category_id}
        </MDTypography>
      ),
      name: (
        <Author
          image={value?.image}
          name={`${value?.name_en} - ${value?.name_ar}`}
          email={value?.city?.map((city) => city?.name_en)?.join()}
        />
      ),
      edit: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={() => navigate(`/main_categories/${value?.main_category_id}`)}
          sx={{ cursor: "pointer" }}
        >
          Edit
        </MDTypography>
      ),
      delete: (
        <Delete
          color="error"
          fontSize="medium"
          onClick={() => handleDeleteMainCategory(value?.main_category_id)}
          sx={{ cursor: "pointer" }}
        />
      ),
    })),
  };
}
