/* eslint-disable no-unused-vars */
import { Button, Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import questionnaireTableData from "layouts/questionnaire/data/questionnaireTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCountries } from "redux/Actions/Countries";
import { fetchMainCategories } from "redux/Actions/MainCategory";
import { fetchActivityCategories } from "redux/Actions/ActivityCategory";
import { fetchQuestionnaire } from "redux/Actions/Questionnaire";
import { useNavigate } from "react-router-dom";

function Country() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const questionnaire = useSelector((state) => state?.questionnaire);

  // const [Columns, setColumns] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Columns, setColumns] = useState([]);

  useEffect(() => {
    dispatch(fetchQuestionnaire({ unmount: false }));

    return () => {
      dispatch(fetchQuestionnaire({ unmount: true }));
    };
  }, []);

  useEffect(() => {
    if (questionnaire?.length > 0) {
      const { columns, rows } = questionnaireTableData(questionnaire, navigate, dispatch);
      setRows(rows);
      setColumns(columns);
      // console.log(rows);
      // console.log(columns);
    }
  }, [questionnaire]);

  // console.log(Rows);

  // const rows = [];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Questionnaire Table
                </MDTypography>
                <Button
                  sx={{ background: "#000", ":hover": { background: "#212121" } }}
                  onClick={() => navigate("/questionnaire/new")}
                >
                  New
                </Button>
              </MDBox>
              {Rows?.length > 0 && (
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: Columns, rows: Rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Country;
