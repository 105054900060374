import { FETCH_ALL_CITIES } from "../types";

export default function CityReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_CITIES:
      return action.payload;
    default:
      return state;
  }
}
