/* eslint-disable import/no-unresolved */
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchPost, deletePost } from "redux/Actions/Posts";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Box, Button, Typography } from "@mui/material";
import CardMediaComponent from "./CardMediaComponent";

function Index() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const post = useSelector((state) => state?.post);

  useEffect(() => {
    dispatch(fetchPost({ post_id: id, unmount: false }));
    return () => {
      dispatch(fetchPost({ unmount: true }));
    };
  }, []);

  // eslint-disable-next-line consistent-return
  const handleDeletePost = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete this post?");
    if (confirmed) {
      return dispatch(deletePost({ post_id: id }));
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={2}>
        {Object?.keys(post)?.length > 0 && (
          <>
            <Typography variant="h4">{`caption: ${post?.caption}`}</Typography>
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              // onSwiper={(swiper) => console.log(swiper)}
              // onSlideChange={() => console.log("slide change")}
            >
              {post?.PostMedia?.length > 0 &&
                post?.PostMedia?.map((val) => (
                  <SwiperSlide>
                    <CardMediaComponent post={val} />
                  </SwiperSlide>
                ))}
            </Swiper>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }} pt={2}>
              <Button
                sx={{
                  background: "transparent",
                  color: "#d63d34",
                  border: "solid 1px #53252c",
                  ":hover": { color: "#d63d34", background: "#53252c" },
                }}
                onClick={handleDeletePost}
              >
                Delete Post
              </Button>
            </Box>
          </>
        )}
      </Box>
    </DashboardLayout>
  );
}

export default Index;
