import { FETCH_REPORTS } from "../types";
import instance from "../../helpers/axiosInstance";

export const fetchReports =
  (condition = {}) =>
  async (dispatch) => {
    try {
      const { data } = await instance.post("/reports/fetch-all", condition);

      // console.log(data);
      return dispatch({
        type: FETCH_REPORTS,
        payload:
          data?.reports.length > 0 || Object.keys(data?.reports).length > 0 ? data?.reports : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_REPORTS,
        payload: null,
      });
    }
  };

export const none = () => {};
