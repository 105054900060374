import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import User from "./index";
import { Box, Grid, Typography } from "@mui/material";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import instance from "../../helpers/axiosInstance";
import moment from "moment";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Box
          sx={{ width: "100%", cursor: "pointer", textAlign: "center" }}
          onClick={() => navigate("/users/dashboard")}
        >
          <Typography>Show Dashboard</Typography>
        </Box>

        <User />
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
