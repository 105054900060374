import instance from "../../helpers/axiosInstance";
import { fetchActivity } from "./Activities";

export const deleteACR =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.delete("/activity-category-relations", { data: condition });

      console.log(data);

      // dispatch(fetchActivity({ activity_id: condition?.activity_id }));

      alert("Activity category relation deleted successfully");
    } catch (error) {
      alert("Something went wrong");
    }
  };

export const updateACR =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.put("/activity-category-relations", condition);

      console.log(data);

      // dispatch(fetchActivity({ activity_id: condition?.activity_id }));

      alert("Updated Activity category relation successfully");
    } catch (error) {
      alert("Something went wrong");
    }
  };

export const createACR =
  (condition = {}) =>
  async (dispatch) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await instance.post("/activity-category-relations", condition);

      dispatch(fetchActivity({ activity_id: condition?.activity_id }));

      alert("Created Activity category relation successfully");
    } catch (error) {
      alert("Something went wrong");
    }
  };
