/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Close, Delete, Edit, FlightTakeoff, KingBed } from "@mui/icons-material";
// import { useDispatch } from "react-redux";
import { changeActivityPage, deleteActivity, hideActivity } from "redux/Actions/Activities";
import { Box, Chip, IconButton, Switch, Typography } from "@mui/material";
import moment from "moment";

export default function data(bookings, navigate, dispatch, parentRows, setRows) {
  const handleDeleteActivity = (id) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      "Are you sure you want to delete this activity? , This will delete all data associated with it"
    );
    if (confirmed) {
      dispatch(deleteActivity({ activity_id: id }));
    }
  };

  return {
    columns: [
      { Header: "ID", accessor: "id", width: "10%", align: "left" },
      { Header: "Customer", accessor: "name", width: "40%", align: "left" },
      { Header: "URL", accessor: "url", width: "40%", align: "center" },
      { Header: "Booked from", accessor: "bookedFrom", width: "10%", align: "left" },
      { Header: "Type", accessor: "type", width: "10%", align: "left" },
      { Header: "Price", accessor: "price", align: "center" },
      { Header: "isPaid", accessor: "isPaid", align: "center" },
      { Header: "Edit", accessor: "edit", align: "center" },
    ],

    rows: bookings?.map((value, index) => ({
      id: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.id}
        </MDTypography>
      ),
      name: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.name}
        </MDTypography>
      ),
      url: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          sx={{
            cursor: value?.payment_id ? "pointer" : "not-allowed",
            textDecoration: "underline",
          }}
          onClick={() => {
            if (!value?.payment_id) {
              return alert("Booking doesn't have payment id");
            }
            let textToCopy = `https://pay.ziina.com/payment_intent/${value?.payment_id}`;
            navigator.clipboard.writeText(textToCopy).then(() => {
              alert("URL Has been copied");
            });
          }}
        >
          {value?.payment_id ? "Copy the link" : "No link to copy"}
        </MDTypography>
      ),
      bookedFrom: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.bookedFrom}
        </MDTypography>
      ),
      type: (
        <>
          {value?.type === "flight" ? (
            <FlightTakeoff />
          ) : value?.type === "hotel" ? (
            <KingBed />
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              both
            </MDTypography>
          )}
        </>
      ),
      isPaid: (
        <>
          {value?.isPaid ? (
            <Chip label="paid" color="success" />
          ) : (
            <Chip label="unpaid" color="warning" />
          )}
        </>
      ),
      edit: (
        <>
          <IconButton
            onClick={() => {
              navigate(`/booking/${value?.id}`);
            }}
          >
            <Edit
              fontSize="medium"
              color="primary"
              fontWeight="medium"
              sx={{
                cursor: "pointer",
              }}
            />
          </IconButton>
        </>
      ),

      price: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.customerPrice} AED
        </MDTypography>
      ),
    })),
  };
}
