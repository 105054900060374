import { FETCH_ACTIVITY } from "../types";

export default function OneActivityReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ACTIVITY:
      return action.payload;
    default:
      return state;
  }
}
