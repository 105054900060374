import { FETCH_ALL_COUNTRIES } from "../types";

export default function CountryReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_COUNTRIES:
      return action.payload;
    default:
      return state;
  }
}
