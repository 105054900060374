/* eslint-disable consistent-return */
import axios from "axios";
import { FETCH_QUESTION, FETCH_QUESTIONNAIRE } from "../types";
import instance, { baseURL } from "../../helpers/axiosInstance";

export const fetchQuestionnaire = () => async (dispatch) => {
  try {
    const { data } = await instance.get("/questionnaire-questions");

    return dispatch({
      type: FETCH_QUESTIONNAIRE,
      payload:
        data?.questionnaire_questions.length > 0 ||
        Object.keys(data?.questionnaire_questions).length > 0
          ? data?.questionnaire_questions
          : null,
    });
  } catch (error) {
    return dispatch({
      type: FETCH_QUESTIONNAIRE,
      payload: null,
    });
  }
};
export const fetchQuestionnaireQuestion = (condition) => async (dispatch) => {
  try {
    const { data } = await instance.post("/questionnaire-questions/fetch", condition);

    return dispatch({
      type: FETCH_QUESTION,
      payload:
        data?.questionnaire_questions.length > 0 ||
        Object.keys(data?.questionnaire_questions).length > 0
          ? data?.questionnaire_questions
          : null,
    });
  } catch (error) {
    return dispatch({
      type: FETCH_QUESTION,
      payload: null,
    });
  }
};

export const updateQuestionnaireQuestion = (condition) => async () => {
  try {
    const { data } = await instance.put("/questionnaire-questions", condition);

    if (data?.status >= 200 && data?.status <= 205) {
      return alert("Updated questionnaire question successfully");
    }
    return alert("Something went wrong");
  } catch (error) {
    return alert("Something went wrong");
  }
};

export const createQuestionnaireQuestion = (condition) => async () => {
  try {
    const { data } = await instance.post("/questionnaire-questions", condition);

    if (data?.status >= 200 && data?.status <= 205) {
      return alert("Created questionnaire question successfully");
    }
    return alert("Something went wrong");
  } catch (error) {
    return alert("Something went wrong");
  }
};

export const updateQuestionnaireAnswer = (condition) => async () => {
  try {
    const { data } = await instance.put("/questionnaire-answers", condition);

    if (data?.status >= 200 && data?.status <= 205) {
      return alert("Deleted questionnaire answer successfully");
    }
    return alert("Something went wrong");
  } catch (error) {
    return alert("Something went wrong");
  }
};
export const deleteQuestionnaireAnswer = (condition) => async () => {
  try {
    const { data } = await instance.delete("/questionnaire-answers", { data: condition });

    if (data?.status >= 200 && data?.status <= 205) {
      return alert("Deleted questionnaire answer successfully");
    }
    return alert("Something went wrong");
  } catch (error) {
    return alert("Something went wrong");
  }
};

export const deleteQuestionnaireQuestion = (condition) => async () => {
  try {
    const { data } = await instance.delete("/questionnaire-questions", { data: condition });

    if (data?.status >= 200 && data?.status <= 205) {
      return alert("Deleted questionnaire question successfully");
    }
    return alert("Something went wrong");
  } catch (error) {
    return alert("Something went wrong");
  }
};
export const createQuestionnaireAnswer = (condition, icon) => async () => {
  try {
    const formData = new FormData();

    if (icon?.length > 0) {
      formData.append(`images`, icon[0], icon[0]?.name);

      const img = await axios({
        method: "POST",
        url: `${baseURL}/upload/svg?img_dir=svg`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // eslint-disable-next-line no-unused-vars
      const { data } = await instance.post("/questionnaire-answers", {
        ...condition,
        icon: img.data.upload[0],
      });
      console.log(data);

      alert("Created answer successfully");
    } else {
      alert("No icon was provided");
    }
  } catch (error) {
    return alert("Something went wrong");
  }
};

export const updateQuestionnaireAnswerIcon = (condition, icon) => async () => {
  try {
    const formData = new FormData();

    if (icon?.length > 0) {
      formData.append(`images`, icon[0], icon[0]?.name);

      const img = await axios({
        method: "POST",
        url: `${baseURL}/upload/svg?img_dir=svg`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // eslint-disable-next-line no-unused-vars
      const { data } = await instance.put("/questionnaire-answers", {
        ...condition,
        icon: img.data.upload[0],
      });
      console.log(data);

      alert("Updated icon successfully");
    } else {
      alert("No icon was provided");
    }
  } catch (error) {
    alert("Something went wrong");
  }
};

export const none = () => {};
