import { FETCH_ALL_ACTIVITY_CATEGORIES } from "../types";

export default function ActivityCategoriesReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_ACTIVITY_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
}
