import { FETCH_BOOKING } from "../types";

export default function OneBookingReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_BOOKING:
      return action.payload;
    default:
      return state;
  }
}
