import { createStore, combineReducers, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import ActivitiesReducer from "./Reducers/Activities";
import ActivitiesPageReducer from "./Reducers/ActivitiesPage";
import CountriesReducer from "./Reducers/Countries";
import OneActivityReducer from "./Reducers/Activity";
import ActivityCategoriesReducer from "./Reducers/ActivityCategories";
import ActivityCategoryReducer from "./Reducers/ActivityCategory";
import CityReducer from "./Reducers/Cities";
import UserReducer from "./Reducers/User";
import UserProfileReducer from "./Reducers/UserProfile";
import UsersReducer from "./Reducers/Users";
import PlansReducer from "./Reducers/Plans";
import MainCategoriesReducer from "./Reducers/MainCategories";
import UserPlanReducer from "./Reducers/UserPlan";
import MainCategoryReducer from "./Reducers/MainCategoryRed";
import QuestionnaireReducer from "./Reducers/Questionnaire";
import QuestionReducer from "./Reducers/Question";
import ReportsReducer from "./Reducers/Reports";
import PostReducer from "./Reducers/Post";
import BookingsReducer from "./Reducers/Bookings";
import RHOrderReducer from "./Reducers/RHOrder";
import BookingReducer from "./Reducers/Booking";
import UtilsReducer from "./Reducers/Utils";

const rootReducer = combineReducers({
  activities: ActivitiesReducer,
  countries: CountriesReducer,
  cities: CityReducer,
  activity: OneActivityReducer,
  activity_categories: ActivityCategoriesReducer,
  user: UserReducer,
  users: UsersReducer,
  plans: PlansReducer,
  main_categories: MainCategoriesReducer,
  main_category: MainCategoryReducer,
  user_plan: UserPlanReducer,
  questionnaire: QuestionnaireReducer,
  reports: ReportsReducer,
  activity_category: ActivityCategoryReducer,
  profile: UserProfileReducer,
  question: QuestionReducer,
  post: PostReducer,
  activity_page_index: ActivitiesPageReducer,
  bookings: BookingsReducer,
  orders: RHOrderReducer,
  booking: BookingReducer,
  utils: UtilsReducer,
});
// eslint-disable-next-line import/prefer-default-export
export const store = createStore(rootReducer, applyMiddleware(thunk));
