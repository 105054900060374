import { FETCH_UTILS } from "redux/types";
import instance from "../../helpers/axiosInstance";

export const fetchAllUtils =
  (condition = {}) =>
  async (dispatch) => {
    try {
      const { data } = await instance.get("/utils");

      return dispatch({
        type: FETCH_UTILS,
        payload: data?.utils,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

export const updateUtil = (condition) => async (dispatch) => {
  try {
    await instance.put("/utils/admin", { ...condition });

    setTimeout(async () => {
      const { data } = await instance.get("/utils");

      return dispatch({
        type: FETCH_UTILS,
        payload: data?.utils,
      });
    }, 200);
  } catch (error) {
    alert("something went wrong");
  }
};
