import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosInstance from "helpers/axiosInstance";
import { baseURL } from "helpers/axiosInstance";
import React, { useState } from "react";

function cancellation() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [bookingEmail, setBookingEmail] = useState({
    rate_hawk_order_id: null,
    status: "completed",
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks

  //   console.log(voucherFile);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data: resData } = await axiosInstance?.post("/ratehawk-orders/payment-re", {
        data: {
          ...bookingEmail,
        },
      });

      alert("Email was sent successfully");
    } catch (error) {
      return alert("Something went wrong");
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box py={5}>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Typography variant="h1">Resend Booking Email</Typography>
          <Grid container spacing={2} pt={5}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Order ID"
                type="number"
                onChange={(e) =>
                  setBookingEmail({ ...bookingEmail, rate_hawk_order_id: e.target.value })
                }
                required
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="center" pt={5}>
            <Button type="submit" variant="contained" sx={{ color: "#FFF" }}>
              Re-send Email
            </Button>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default cancellation;
