import { FETCH_ACTIVITY_PAGE } from "../types";

export default function ActivityPageReducer(state = null, action) {
  switch (action.type) {
    case FETCH_ACTIVITY_PAGE:
      return action.payload;
    default:
      return state;
  }
}
