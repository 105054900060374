/* eslint-disable no-unused-vars */
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import reportsTableData from "layouts/reports/data/reportsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReports } from "redux/Actions/Reports";
import { useNavigate } from "react-router-dom";

function Country() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reports = useSelector((state) => state?.reports);

  // const [Columns, setColumns] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Columns, setColumns] = useState([]);

  useEffect(() => {
    dispatch(fetchReports({ unmount: false }));

    return () => {
      dispatch(fetchReports({ unmount: true }));
    };
  }, []);

  useEffect(() => {
    if (reports?.length > 0) {
      // console.log(reports);
      const { columns, rows } = reportsTableData(reports, navigate);
      // console.log(rows);
      setRows(rows);
      setColumns(columns);
      // console.log(rows);
      // console.log(columns);
    }
  }, [reports]);

  // console.log(Rows);

  // const rows = [];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Reports Table
                </MDTypography>
              </MDBox>
              {Rows?.length > 0 && (
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: Columns, rows: Rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Country;
