import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";
import instance from "helpers/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { fetchBooking } from "redux/Actions/Bookings";
import moment from "moment";
import { updateBooking } from "redux/Actions/Bookings";

function Edit() {
  let { id } = useParams();
  const dispatch = useDispatch();

  const bookingRed = useSelector((state) => state?.booking);

  const [booking, setBooking] = useState({
    name: "",
    email: "",
    phone: "",
    checkIn: "",
    checkOut: "",
    bookedFrom: "",
    type: "",
    link: "",
    refundable: "",
    payment_id: "",
    isPaid: false,
    providerPrice: "",
    customerPrice: "",
  });

  useEffect(() => {
    dispatch(fetchBooking({ id }));
  }, []);

  useEffect(() => {
    if (bookingRed) {
      setBooking({
        ...booking,
        name: bookingRed?.name,
        email: bookingRed?.email,
        phone: bookingRed?.phone,
        checkIn: bookingRed?.checkIn,
        checkOut: bookingRed?.checkOut,
        description: bookingRed?.description,
        bookedFrom: bookingRed?.bookedFrom,
        type: bookingRed?.type,
        link: bookingRed?.link,
        refundable: bookingRed?.refundable,
        payment_id: bookingRed?.payment_id,
        isPaid: bookingRed?.isPaid,
        providerPrice: bookingRed?.providerPrice,
        customerPrice: bookingRed?.customerPrice,
      });
    }
  }, [bookingRed]);

  useEffect(() => {
    if (booking?.payment_id && !booking?.isPaid) {
      instance
        .post("/ziina/check-payment", { payment_id: booking?.payment_id })
        .then(({ data }) => {
          if (data?.payment?.status === "completed") {
            dispatch(updateBooking({ isPaid: true, id }));
          }
        })
        .catch((err) => {
          alert("Something went wrong");
        });
    }
  }, [booking?.payment_id]);

  const options = [
    { value: "rateHawk", label: "Rate Hawk" },
    { value: "parto_crs", label: "Parto CRS" },
    { value: "booking", label: "Booking.com" },
    { value: "other", label: "Other" },
  ];

  const options2 = [
    { value: "flight", label: "Flight" },
    { value: "hotel", label: "Hotel" },
    { value: "both", label: "Both" },
  ];

  const handleBookingChange = (e) => {
    let newBooking = { ...booking };
    newBooking[`${e.target.name}`] = e.target.value;
    setBooking({ ...newBooking });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateBooking({ ...booking, id }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box py={5}>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Typography variant="h5">Customer information</Typography>
          <Grid container py={1} spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Full name"
                name="name"
                type="text"
                fullWidth
                value={booking?.name}
                onChange={handleBookingChange}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="email"
                name="email"
                type="email"
                fullWidth
                value={booking?.email}
                onChange={handleBookingChange}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Phone"
                name="phone"
                type="text"
                fullWidth
                value={booking?.phone}
                onChange={handleBookingChange}
                size="medium"
                // sx={{ width: "50%" }}
              />
            </Grid>
          </Grid>
          <Typography variant="h5" py={1}>
            Booking information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Check in date"
                  renderInput={(props) => <TextField {...props} variant="filled" fullWidth />}
                  onChange={(date) => setBooking({ ...booking, checkIn: moment(date).format("") })}
                  value={booking?.checkIn}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Check out date"
                  renderInput={(props) => <TextField {...props} variant="filled" fullWidth />}
                  onChange={(date) => setBooking({ ...booking, checkOut: moment(date).format("") })}
                  value={booking?.checkOut}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Description"
                name="description"
                type="text"
                multiline
                rows={3}
                fullWidth
                value={booking?.description}
                onChange={handleBookingChange}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                options={options}
                placeholder="Booked from"
                value={options?.filter((val) => val?.value === booking?.bookedFrom)[0]}
                onChange={(e) => setBooking({ ...booking, bookedFrom: e.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                options={options2}
                placeholder="Flight or Hotel"
                value={options2?.filter((val) => val?.value === booking?.type)[0]}
                onChange={(e) => setBooking({ ...booking, type: e.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Hotel or room Link"
                name="link"
                type="text"
                fullWidth
                value={booking?.link}
                onChange={handleBookingChange}
                size="small"
              />
            </Grid>
            <Box sx={{ width: "fit-content", px: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={booking?.refundable}
                    onChange={(e) =>
                      setBooking((prev) => ({ ...prev, refundable: !prev.refundable }))
                    }
                  />
                }
                label="refundable"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={booking.isPaid}
                    onChange={(e) => setBooking((prev) => ({ ...prev, isPaid: !prev.isPaid }))}
                  />
                }
                label="is paid"
              />
            </Box>
            {/* </Grid> */}
          </Grid>
          <Typography variant="h5" py={1}>
            Payment information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Payment id"
                name="payment_id"
                type="text"
                fullWidth
                value={booking?.payment_id}
                onChange={handleBookingChange}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Provider price"
                name="providerPrice"
                type="number"
                fullWidth
                value={booking?.providerPrice}
                onChange={handleBookingChange}
                size="medium"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                label="Customer price"
                name="customerPrice"
                type="number"
                fullWidth
                value={booking?.customerPrice}
                onChange={handleBookingChange}
                size="medium"
              />
            </Grid>
          </Grid>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
            pt={3}
          >
            <Button
              type="submit"
              sx={{
                width: "50%",
                background: "#3E89D6",
                color: "#fff",
                ":hover": {
                  background: "rgba(62, 137, 214, 0.7)",
                  color: "#fff",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default Edit;
