import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import UserPlan from "./index";
import instance from "../../helpers/axiosInstance";

function Dashboard() {
  const [plansCount, setPlansCount] = useState(0);
  const [paidPlansCount, setPaidPlansCount] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [generate, setGenerate] = useState(0);

  useEffect(() => {
    instance.post("/dashboard/plans/count").then(({ data }) => {
      setPlansCount(data?.plans);
    });

    instance.post("/dashboard/paid-plans/count").then(({ data }) => {
      setPaidPlansCount(data?.plans);
    });

    instance.post("/dashboard/total").then(({ data }) => {
      setTotalRevenue(data?.plans?.plans);
    });

    instance.post("/dashboard/plans/ai").then(({ data }) => {
      setGenerate(data?.plans);
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard icon="luggage" title="All plans" count={plansCount || 0} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="credit_card"
                title="Paid Plans"
                count={paidPlansCount}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="credit_card"
                title="Total Revenue"
                count={`${totalRevenue ? totalRevenue.toFixed(2) : 0} AED`}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="smart_toy"
                title="Generated Plans"
                count={generate}
              />
            </MDBox>
          </Grid>
        </Grid>
        <UserPlan />
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
