import { FETCH_ALL_PLANS } from "../types";

export default function PlansReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_PLANS:
      return action.payload;
    default:
      return state;
  }
}
