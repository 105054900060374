import { Replay } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useNavigate } from "react-router-dom";

function index() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box py={5}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box
                sx={gridStyle}
                onClick={() => {
                  navigate("/emails-booking/booking");
                }}
              >
                <img
                  alt="New Booking"
                  src="/assets/schedule-svgrepo-com.svg"
                  width={100}
                  height={100}
                />
                <Typography variant="h3" fontWeight={"bold"}>
                  Send booking email
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={gridStyle}
                onClick={() => {
                  navigate("/emails-booking/cancellation");
                }}
              >
                <img
                  alt="New Booking"
                  src="/assets/calendar-times-svgrepo-com.svg"
                  width={100}
                  height={100}
                />
                <Typography variant="h3" fontWeight={"bold"}>
                  Send cancellation email
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={gridStyle}
                onClick={() => {
                  navigate("/emails-booking/resend");
                }}
              >
                <Replay sx={{ width: "100px", height: "100px" }} color="info" />
                <Typography variant="h3" fontWeight={"bold"}>
                  Resend an order e-mail
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </MDBox>
      </Box>
    </DashboardLayout>
  );
}

export default index;

const gridStyle = {
  border: "dashed 4px #3790EE",
  minHeight: "50vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  flexDirection: "column",
};
