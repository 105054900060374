import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosInstance from "helpers/axiosInstance";
import { baseURL } from "helpers/axiosInstance";
import React, { useState } from "react";
import { useSelector } from "react-redux";

function booking() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [bookingEmail, setBookingEmail] = useState({
    order_id: null,
    amount: null,
    currency: "AED",
    email: "",
    partial_refund: null,
    has_cancellation: false,
    total_adults: null,
    total_children: null,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [voucherFile, setVoucherFile] = useState(null);

  const handleChangeCurrency = (e) => {
    setBookingEmail({ ...bookingEmail, currency: e.target.value });
  };

  //   console.log(voucherFile);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data: resData } = await axiosInstance?.post("/ratehawk-orders/manual-booking", {
        ...bookingEmail,
      });

      alert("Email was sent successfully");
    } catch (error) {
      return alert("Something went wrong");
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box py={5}>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Typography variant="h1">New Booking Email</Typography>
          <Grid container spacing={2} pt={5}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Email"
                type="email"
                onChange={(e) => setBookingEmail({ ...bookingEmail, email: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Order ID"
                type="number"
                onChange={(e) => setBookingEmail({ ...bookingEmail, order_id: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Amount Charged"
                type="number"
                onChange={(e) => setBookingEmail({ ...bookingEmail, amount: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={bookingEmail?.currency}
                  label="Currency"
                  onChange={handleChangeCurrency}
                  size="medium"
                  sx={{ height: "44.3px" }}
                  required
                >
                  <MenuItem value={"AED"}>AED</MenuItem>
                  <MenuItem value={"USD"}>USD</MenuItem>
                  <MenuItem value={"BHD"}>BHD</MenuItem>
                  <MenuItem value={"EUR"}>EUR</MenuItem>
                  <MenuItem value={"GBP"}>GBP</MenuItem>
                  <MenuItem value={"INR"}>INR</MenuItem>
                  <MenuItem value={"KWD"}>KWD</MenuItem>
                  <MenuItem value={"OMR"}>OMR</MenuItem>
                  <MenuItem value={"QAR"}>QAR</MenuItem>
                  <MenuItem value={"SAR"}>SAR</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Total Adults"
                type="number"
                onChange={(e) => setBookingEmail({ ...bookingEmail, total_adults: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Total Children"
                type="number"
                onChange={(e) =>
                  setBookingEmail({ ...bookingEmail, total_children: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={<Checkbox />}
                label="Free cancellation"
                checked={bookingEmail?.has_cancellation}
                onChange={() => {
                  setBookingEmail({
                    ...bookingEmail,
                    has_cancellation: !bookingEmail?.has_cancellation,
                  });
                }}
              />
            </Grid>
            {bookingEmail?.has_cancellation && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Partial Refund"
                  type="number"
                  onChange={(e) =>
                    setBookingEmail({ ...bookingEmail, partial_refund: e.target.value })
                  }
                  required
                />
              </Grid>
            )}
          </Grid>

          <Box display="flex" justifyContent="center" pt={5}>
            <Button type="submit" variant="contained" sx={{ color: "#FFF" }}>
              Send Email
            </Button>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default booking;
