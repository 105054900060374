/* eslint-disable no-nested-ternary */
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchActivityCategory, updateActivityCategory } from "redux/Actions/ActivityCategory";
import { fetchMainCategories } from "redux/Actions/MainCategory";
import Select from "react-select";
import ConfirmationDialog from "components/Confirmation";

function Edit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [ActivityCategory, setActivityCategory] = useState(null);
  const [Options, setOptions] = useState(null);

  const activityCategory = useSelector((state) => state?.activity_category);
  const mainCategories = useSelector((state) => state?.main_categories);

  // console.log(mainCategories);
  useEffect(() => {
    dispatch(fetchMainCategories());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchActivityCategory({ activity_category_id: id, unmount: false }));
    }
    return () => {
      dispatch(fetchActivityCategory({ unmount: true }));
    };
  }, [id]);

  useEffect(() => {
    if (Object.keys(activityCategory)?.length > 0) {
      setActivityCategory({
        activity_category_id: activityCategory?.activity_category_id,
        name_en: activityCategory?.name_en,
        name_ar: activityCategory?.name_ar,
        main_category_id: activityCategory?.main_category_id,
      });
    }
  }, [activityCategory]);

  useEffect(() => {
    if (mainCategories?.length > 0) {
      setOptions(
        mainCategories?.map((val) => ({ value: val?.main_category_id, label: val?.name_en }))
      );
    }
  }, [mainCategories]);

  const handleActivityCategoryChange = (e) => {
    ActivityCategory[`${e.target.name}`] = e.target.value;
    setActivityCategory({ ...ActivityCategory });
  };

  const handleMainCategoryChange = (option) => {
    ActivityCategory.main_category_id = option?.value;
    setActivityCategory({ ...ActivityCategory });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateActivityCategory(ActivityCategory));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {activityCategory !== null &&
        (activityCategory?.length > 0 || Object?.keys(activityCategory).length > 0) ? (
          <Box component="form" onSubmit={handleSubmit}>
            <Typography>Edit Activity Category</Typography>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="filled"
                  label="Name English"
                  name="name_en"
                  type="text"
                  value={ActivityCategory?.name_en}
                  onChange={(e) => handleActivityCategoryChange(e)}
                  size="medium"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="filled"
                  label="Name Arabic"
                  name="name_ar"
                  inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
                  type="text"
                  value={ActivityCategory?.name_ar}
                  onChange={(e) => handleActivityCategoryChange(e)}
                  size="medium"
                  fullWidth
                />
              </Grid>
              {Options?.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Select
                    options={Options}
                    placeholder="Select Main Category..."
                    onChange={handleMainCategoryChange}
                    defaultValue={{
                      label: activityCategory?.main_category?.name_en,
                      value: ActivityCategory?.main_category_id,
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Box sx={{ width: "100%" }} py={3}>
              <Button
                size="large"
                sx={{
                  background: "#3E89D6",
                  color: "#FFF",
                  ":hover": {
                    background: "#7baadb",
                  },
                }}
                fullWidth
                color="error"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Box>
        ) : typeof activityCategory === "object" && activityCategory !== null ? (
          <Box
            sx={{
              width: "100%",
              hieght: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Loading ...</Typography>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              hieght: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Not found</Typography>
          </Box>
        )}
        <ConfirmationDialog message="Are you sure you want to close this page?" />
      </MDBox>
    </DashboardLayout>
  );
}

export default Edit;
