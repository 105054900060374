/* eslint-disable no-nested-ternary */
import { Close, Save } from "@mui/icons-material";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ConfirmationDialog from "components/Confirmation";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { fetchAllCountries } from "redux/Actions/Countries";
import {
  updateUserProfilePicture,
  updateUserProfileCover,
  updateUserProfile,
  fetchUserProfile,
} from "redux/Actions/User";

function Edit() {
  const { username } = useParams();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state?.profile);
  const countries = useSelector((state) => state?.countries);
  const [Profile, setProfile] = useState(null);
  const [options, setOptions] = useState(null);
  const [dob, setDob] = useState(null);

  const [Cover, setCover] = useState([]);
  const [CoverFile, setCoverFile] = useState(null);

  const [ProPic, setProPic] = useState([]);
  const [ProPicFile, setProPicFile] = useState(null);

  useEffect(() => {
    dispatch(fetchUserProfile({ username }));
    dispatch(fetchAllCountries());

    return () => {
      dispatch(fetchUserProfile({ username, unmount: true }));
    };
  }, []);

  useEffect(() => {
    setProfile({
      uuid: profile?.uuid,
      username: profile?.username,
      first_name: profile?.first_name,
      last_name: profile?.last_name,
      email: profile?.email,
      bio: profile?.profile?.bio,
      phone_number: profile?.phone_number,
      cover_picture: profile?.profile?.cover_picture,
      profile_picture: profile?.profile?.profile_picture,
      country_id: profile?.country_id,
      facebook_link: profile?.profile?.facebook_link,
      instagram_link: profile?.profile?.instagram_link,
      linktree_link: profile?.profile?.linktree_link,
      snapchat_link: profile?.profile?.snapchat_link,
      tiktok_link: profile?.profile?.tiktok_link,
      twitter_link: profile?.profile?.twitter_link,
    });
    setDob(moment(profile?.date_of_birth));
  }, [profile]);

  useEffect(() => {
    if (countries?.length > 0) {
      setOptions(countries?.map((val) => ({ value: val?.country_id, label: val?.name_en })));
    }
  }, [countries]);

  const handleChange = (e) => {
    Profile[`${e.target.name}`] = e.target.value;
    setProfile({ ...Profile });
  };

  const handleChangeDate = (date) => {
    Profile.date_of_birth = moment(date).format("YYYY-MM-DD");
    setProfile({ ...Profile });
    setDob(date);
  };

  const handleChangeCountry = (option) => {
    Profile.country_id = option?.value;
    setProfile({ ...Profile });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserProfile({ ...Profile, date_of_birth: moment(dob).format("YYYY-MM-DD") }));
  };

  const handleUpdateCover = (e) => {
    e.preventDefault();
    dispatch(updateUserProfileCover(CoverFile, Profile?.username));
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    // console.log("first");
    dispatch(updateUserProfilePicture(ProPicFile, Profile?.username));
  };
  // console.log(ProPicFile);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setCover(image);
        setCoverFile(files?.filter((val) => allowedTypes.includes(val?.type)));
        // setCoverFile(e.target.files);
      })
      .catch((error) => console.error(error));
  };

  const handleImage2Change = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setProPic(image);
        setProPicFile(files?.filter((val) => allowedTypes.includes(val?.type)));
        // setCoverFile(e.target.files);
      })
      .catch((error) => console.error(error));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {Profile && Object?.keys(Profile)?.length > 0 && (
        <Box sx={{ position: "relative" }}>
          {Profile?.cover_picture ? (
            <Box
              sx={{
                background: `url("${Profile?.cover_picture}") center center / cover no-repeat`,
                height: "300px",
                width: "100%",
                position: "relative",
                borderRadius: "15px",
              }}
            >
              <Box
                sx={{
                  background: "#212121",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "2px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setProfile({ ...Profile, cover_picture: null });
                }}
              >
                <Close color="error" />
              </Box>
            </Box>
          ) : !Profile?.cover_picture && Cover?.length > 0 ? (
            Cover?.map((val) => (
              <>
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "20px",
                    background: `url("${val}") center center / cover no-repeat`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  //   onClick={() => handleShowImage(val)}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "#212121",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "15px",
                      padding: "2px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCover([]);
                      setCoverFile(null);
                    }}
                  >
                    <Close color="error" />
                  </Box>
                </Box>
                <Button
                  size="large"
                  sx={{
                    background: "#3E89D6",
                    color: "#FFF",
                    ":hover": {
                      background: "#7baadb",
                    },
                    mt: 3,
                  }}
                  fullWidth
                  color="error"
                  onClick={handleUpdateCover}
                >
                  Change cover
                </Button>
              </>
            ))
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "300px",
                borderRadius: "20px",
                background: `linear-gradient(to right, #2193b0, #6dd5ed)`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Typography color="#FFF" sx={{ position: "absolute", top: "45%", left: "43%" }}>
                Upload Image
              </Typography>
              <input
                type="file"
                accept="image/jpeg,image/jpg,image/png"
                style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
                onChange={handleImageChange}
              />
            </Box>
          )}

          {Profile?.profile_picture ? (
            <Box
              sx={{
                background: `url("${Profile?.profile_picture}") center center / cover no-repeat`,
                height: "150px",
                width: "150px",
                position: "absolute",
                borderRadius: "50%",
                top: "11%",
                left: "2%",
                border: "solid 2px #212121",
              }}
            >
              <Box
                sx={{
                  background: "#212121",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "2px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setProfile({ ...Profile, profile_picture: null });
                }}
              >
                <Close color="error" />
              </Box>
            </Box>
          ) : !Profile?.profile_picture && ProPic?.length > 0 ? (
            ProPic?.map((val) => (
              <Box
                sx={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  background: `url("${val}") center center / cover no-repeat`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  cursor: "pointer",
                  top: "11%",
                  left: "2%",
                  border: "solid 2px #212121",
                }}
                //   onClick={() => handleShowImage(val)}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    background: "#212121",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "15px",
                    padding: "2px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setProPic([]);
                    setProPicFile(null);
                  }}
                >
                  <Close color="error" />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "10px",
                    right: "5px",
                    background: "#212121",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "50%",
                    // padding: "2px",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpdateProfile(e);
                  }}
                >
                  <Save color="primary" fontSize="medium" />
                </Box>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                background: "linear-gradient(to right, #f953c6, #b91d73)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "11%",
                left: "2%",
              }}
            >
              <Typography color="#FFF" sx={{ position: "absolute", top: "32%", left: "30%" }}>
                Upload Profile
              </Typography>
              <input
                type="file"
                accept="image/jpeg,image/jpg,image/png"
                style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
                onChange={handleImage2Change}
              />
            </Box>
          )}
          <Box pt={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "centers" }}>
                <Typography>{`Followers: ${profile?.profile?.followers}`}</Typography>
                <Typography>Following: {profile?.profile?.following}</Typography>
                <Typography>Posts: {profile?.profile?.posts}</Typography>
              </Box>
            </Grid>
          </Box>
          <Box pt={2}>
            <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="first_name"
                  label="First name"
                  size="medium"
                  variant="filled"
                  fullWidth
                  value={Profile?.first_name}
                  onChange={handleChange}
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <TextField
                  name="last_name"
                  label="Last name"
                  size="medium"
                  variant="filled"
                  fullWidth
                  value={Profile?.last_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="email"
                  label="E-mail"
                  size="medium"
                  variant="filled"
                  fullWidth
                  value={Profile?.email}
                  onChange={handleChange}
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Stack spacing={3}>
                    <DatePicker
                      defaultValue={moment()}
                      value={dob}
                      disableFuture
                      views={["year", "month", "day"]}
                      label="Date of birth"
                      renderInput={(params) => (
                        <TextField {...params} variant="filled" size="medium" />
                      )}
                      onChange={(date) => handleChangeDate(date)}
                    />
                    {/* <MobileDatePicker /> */}
                  </Stack>
                </LocalizationProvider>
              </Grid>
              {options?.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="body2">Country</Typography>
                  <Select
                    options={options}
                    placeholder="Select Country..."
                    value={
                      Profile?.country_id && {
                        value: Profile?.country_id,
                        label: countries?.filter(
                          (val) => val?.country_id === Profile?.country_id
                        )[0]?.name_en,
                      }
                    }
                    onChange={handleChangeCountry}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  name="phone_number"
                  label="Phone number"
                  size="medium"
                  variant="filled"
                  fullWidth
                  value={Profile?.phone_number}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Account</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  name="bio"
                  label="BIO"
                  size="medium"
                  variant="filled"
                  multiline
                  rows={4}
                  fullWidth
                  value={Profile?.bio}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Social Media</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="facebook_link"
                  label="Facebook URL"
                  size="medium"
                  variant="filled"
                  fullWidth
                  value={Profile?.facebook_link}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="instagram_link"
                  label="Instagram URL"
                  size="medium"
                  variant="filled"
                  fullWidth
                  value={Profile?.instagram_link}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="linktree_link"
                  label="Linktree URL"
                  size="medium"
                  variant="filled"
                  fullWidth
                  value={Profile?.linktree_link}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="snapchat_link"
                  label="Snapchat URL"
                  size="medium"
                  variant="filled"
                  fullWidth
                  value={Profile?.snapchat_link}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="tiktok_link"
                  label="Tiktok URL"
                  size="medium"
                  variant="filled"
                  fullWidth
                  value={Profile?.tiktok_link}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="twitter_link"
                  label="Twitter URL"
                  size="medium"
                  variant="filled"
                  fullWidth
                  value={Profile?.twitter_link}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: "100%" }} py={3}>
                  <Button
                    size="large"
                    sx={{
                      background: "#3E89D6",
                      color: "#FFF",
                      ":hover": {
                        background: "#7baadb",
                      },
                    }}
                    fullWidth
                    color="error"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      <ConfirmationDialog message="Are you sure you want to close this page?" />
    </DashboardLayout>
  );
}

export default Edit;
