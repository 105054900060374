import { FETCH_QUESTIONNAIRE } from "../types";

export default function QuestionnaireReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_QUESTIONNAIRE:
      return action.payload;
    default:
      return state;
  }
}
