/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import CardMedia from "@mui/material/CardMedia";
import { IconButton } from "@mui/material";
import { PlayArrow, VolumeOff, VolumeUp } from "@mui/icons-material";
// import useThemeMui from "@mui/material/styles/useTheme";

function CardMediaComponent({ post }) {
  const playerRef = useRef(null);
  const [paused, setPaused] = React.useState(null);
  // const theme = useThemeMui();
  // const { palette } = theme;
  const [muted, setMuted] = React.useState(true);

  useEffect(() => {
    if (!post.is_image) {
      const observer = new IntersectionObserver(
        (entries) => {
          // console.log(entries);
          entries.forEach((entry) => {
            if (!entry.isIntersecting) {
              playerRef?.current?.pause();
              setPaused(true);
            } else {
              // console.log("out");
              playerRef?.current?.play();
              setPaused(false);
            }
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 1.0,
        }
      );

      // eslint-disable-next-line no-unused-expressions
      playerRef != null && observer.observe(playerRef?.current);
    }
  }, [post]);

  // console.log(playerRef);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!post?.is_image && playerRef.current != null) {
        if (document.visibilityState === "hidden") {
          playerRef?.current.pause();
          setPaused(true);
        } else {
          // console.log(videoRef);
          // videoRef.current.play();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [post]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // background: palette.mode === "light" ? "#d3d3d3" : "#1e1e1e",
      }}
    >
      {!post?.is_image && (
        <CardMedia
          component={post?.is_image ? "img" : "video"}
          height="100%"
          sx={{ maxHeight: "550px" }}
          src={post?.url}
          // alt="Paella dish"
          loop
          muted={muted}
          onClick={() => {
            playerRef?.current?.pause();
            setPaused(true);
          }}
          onPlay={() => {
            setPaused(false);
            playerRef?.current?.play();
          }}
          onPause={() => {
            setPaused(true);
            playerRef?.current?.pause();
          }}
          autoPlay
          ref={!post?.is_image ? playerRef : null}
        />
      )}

      {post?.is_image && (
        <div
          style={{
            width: "100%",
            height: "550px",
            background: `url(${post?.url}) center center / contain no-repeat`,
          }}
        />
      )}
      {playerRef?.current != null && (
        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "45%",
            fontSize: "60px",
          }}
        >
          <IconButton sx={{ fontSize: "60px" }}>
            {paused && (
              <PlayArrow
                sx={{ color: "#212121" }}
                onClick={() => {
                  setPaused(false);
                  playerRef?.current?.play();
                }}
              />
            )}
          </IconButton>
        </div>
      )}
      {playerRef?.current != null && (
        <div
          style={{
            position: "absolute",
            top: "1%",
            left: "1%",
          }}
        >
          <IconButton>
            {!muted && (
              <IconButton sx={{ fontSize: "30px " }}>
                <VolumeUp
                  style={{ color: "#212121" }}
                  onClick={() => {
                    setMuted(true);
                  }}
                />
              </IconButton>
            )}
            {muted && (
              <IconButton sx={{ fontSize: "30px " }}>
                <VolumeOff
                  style={{ color: "#212121" }}
                  onClick={() => {
                    setMuted(false);
                  }}
                />
              </IconButton>
            )}
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default CardMediaComponent;
