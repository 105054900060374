/* eslint-disable no-nested-ternary */
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import ConfirmationDialog from "components/Confirmation";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createQuestionnaireQuestion } from "redux/Actions/Questionnaire";

function Edit() {
  const dispatch = useDispatch();
  const [Question, setQuestion] = useState({
    questionnaire_question_id: "",
    question_en: "",
    question_ar: "",
    type: "select",
    weight: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [Options, setOptions] = useState([{ value: "select", label: "select" }]);

  //   const mainCategories = useSelector((state) => state?.main_categories);

  // console.log(mainCategories);

  const handleQuestionChange = (e) => {
    Question[`${e.target.name}`] = e.target.value;
    setQuestion({ ...Question });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createQuestionnaireQuestion(Question));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Box component="form" onSubmit={handleSubmit}>
          <Typography>Create Question</Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6}>
              <TextField
                variant="filled"
                label="Question English"
                name="question_en"
                type="text"
                value={Question?.question_en}
                onChange={(e) => handleQuestionChange(e)}
                size="medium"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="filled"
                label="Question Arabic"
                name="question_ar"
                type="text"
                inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
                value={Question?.question_ar}
                onChange={(e) => handleQuestionChange(e)}
                size="medium"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="filled"
                label="Weight"
                name="weight"
                value={Question?.weight}
                onChange={(e) => handleQuestionChange(e)}
                size="medium"
                type="number"
                fullWidth
              />
            </Grid>
            <Box sx={{ width: "100%" }} py={3}>
              <Button
                size="large"
                sx={{
                  background: "#3E89D6",
                  color: "#FFF",
                  ":hover": {
                    background: "#7baadb",
                  },
                }}
                fullWidth
                color="error"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Box>
        <ConfirmationDialog message="Are you sure you want to close this page?" />
      </MDBox>
    </DashboardLayout>
  );
}

export default Edit;
