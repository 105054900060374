/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { Close, Delete } from "@mui/icons-material";
// import { useDispatch } from "react-redux";
import { changeActivityPage, deleteActivity, hideActivity } from "redux/Actions/Activities";
import { Box, Switch, Typography } from "@mui/material";
import moment from "moment";

export default function data(activities, navigate, dispatch, parentRows, setRows) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={`${image}`} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const currentTime = moment();

  const handleDeleteActivity = (id) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      "Are you sure you want to delete this activity? , This will delete all data associated with it"
    );
    if (confirmed) {
      dispatch(deleteActivity({ activity_id: id }));
    }
  };

  const handleHideActivity = (id, hidden) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      hidden
        ? "Are you sure you want unhide this activity? "
        : "Are you sure you want to hide this activity?"
    );
    if (confirmed) {
      // const newArray = [...activities];
      // const newRows = newArray?.map((val) => {
      //   if (val?.activity_id === id) {
      //     return { ...val, is_hidden: !hidden };
      //   }
      //   return val;
      // });
      // Promise.all(newRows).then((res) => {
      //   const { rows } = data(res, navigate, dispatch, parentRows, setRows);
      //   setRows(rows);
      // });
      dispatch(hideActivity({ activity_id: id, is_hidden: !hidden }));
    }
  };

  return {
    columns: [
      { Header: "ID", accessor: "id", width: "10%", align: "left" },
      { Header: "Name", accessor: "name", width: "44%", align: "left" },
      { Header: "Edit", accessor: "edit", align: "center" },
      { Header: "Status", accessor: "closed", align: "center" },
      { Header: "Hide", accessor: "hide", align: "center" },
      { Header: "Delete", accessor: "delete", align: "center" },
    ],

    rows: activities?.map((value, index) => ({
      id: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.activity_id}
        </MDTypography>
      ),
      name: (
        <Author
          image={value?.horizontal_thumbnail}
          name={`${value?.name_en} - ${value?.name_ar}`}
          email={value?.city?.map((city) => city?.name_en)?.join()}
        />
      ),
      hide: (
        <Switch
          checked={value?.is_hidden}
          onChange={() => handleHideActivity(value?.activity_id, value?.is_hidden)}
        />
      ),
      closed: !value?.seasonal_closing_start_time ? (
        <Typography variant="caption" fontWeight={"bold"}>
          Open
        </Typography>
      ) : currentTime >= moment(value?.seasonal_closing_start_time) &&
        currentTime <= moment(value?.seasonal_closing_end_time) ? (
        <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Close fontSize="small" color="error" />
          <Typography variant="caption" fontWeight={"bold"} color="#F44537">
            Closed
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography variant="caption" fontWeight={"bold"}>
            Open
          </Typography>
        </Box>
      ),
      edit: (
        <MDTypography
          onClick={() => {
            dispatch(changeActivityPage({ index }));
            navigate(`/activities/${value?.activity_id}`);
          }}
          variant="caption"
          color="text"
          fontWeight="medium"
          sx={{
            cursor: "pointer",
          }}
        >
          Edit
        </MDTypography>
      ),
      delete: (
        <Delete
          onClick={() => handleDeleteActivity(value?.activity_id)}
          fontSize="medium"
          color="error"
          fontWeight="medium"
          sx={{
            cursor: "pointer",
          }}
        />
      ),
      name_en: value?.name_en,
      name_ar: value?.name_ar,
      country_id: value?.branch[0]?.city?.country_id,
    })),
  };
}
