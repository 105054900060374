/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { deleteActivityCategory } from "redux/Actions/ActivityCategory";

export default function data(countries, navigate, dispatch) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const handleDeleteActivityCategory = (id) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete this activity category?");

    if (confirmed) {
      dispatch(deleteActivityCategory({ activity_category_id: id }));
    }
  };
  return {
    columns: [
      { Header: "ID", accessor: "id", width: "10%", align: "left" },
      { Header: "Name", accessor: "name", width: "44%", align: "left" },
      { Header: "Edit", accessor: "edit", align: "center" },
      { Header: "Delete", accessor: "delete", align: "center" },
    ],

    rows: countries?.map((value) => ({
      id: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.activity_category_id}
        </MDTypography>
      ),
      name: (
        <Author
          image={value?.main_category?.image}
          name={`${value?.name_en} - ${value?.name_ar}`}
          email={value?.city?.map((city) => city?.name_en)?.join()}
        />
      ),
      edit: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/activity_categories/${value?.activity_category_id}`);
          }}
        >
          Edit
        </MDTypography>
      ),
      delete: (
        <IconButton
          onClick={() => {
            handleDeleteActivityCategory(value?.activity_category_id);
          }}
        >
          <Delete color="error" />
        </IconButton>
      ),
    })),
  };
}
