import { FETCH_POSTS } from "../types";
import instance from "../../helpers/axiosInstance";

export const fetchPost =
  (condition = {}) =>
  async (dispatch) => {
    try {
      if (condition.unmount) {
        return dispatch({
          type: FETCH_POSTS,
          payload: {},
        });
      }

      const { data } = await instance.post("/posts/fetch", condition);

      return dispatch({
        type: FETCH_POSTS,
        payload: data?.posts.length > 0 || Object.keys(data?.posts).length > 0 ? data?.posts : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_POSTS,
        payload: null,
      });
    }
  };
export const deletePost =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.delete("/posts", { data: condition });
      console.log(data);

      if (data?.status >= 200 && data?.status <= 205) {
        return alert("Deleted Post successfully");
      }

      return alert("Something went wrong");
    } catch (error) {
      return alert("Something went wrong");
    }
  };

export const fetchCity = () => {};
