import instance from "../../helpers/axiosInstance";
import { fetchActivity } from "./Activities";

export const updateWorkingHoursBulk =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.put("/working-hours/bulk", { working_hours: condition });
      console.log("data", data);
    } catch (error) {
      console.log("error", error);
    }
  };
export const deleteWorkingHours =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.delete("/working-hours", { data: condition });
      console.log("data", data);
      alert("Deleted working day successfully");
      // dispatch(fetchActivity({ activity_id: activityId }));
    } catch (error) {
      console.log("error", error);
      alert("Failed to delete working day");
    }
  };

export const createWorkingHour =
  (condition = {}) =>
  async (dispatch) => {
    try {
      const { data } = await instance.post("/working-hours", condition);
      console.log("data", data);
      if (condition?.activity_id) {
        dispatch(fetchActivity({ activity_id: condition?.activity_id }));
      }
      alert("Created working day successfully");
    } catch (error) {
      console.log("error", error);
      alert("Failed to create working day");
    }
  };

export const fetchAllWorkingHours = () => {};
