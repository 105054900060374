/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

export default function DialogLeavingPage({
  showDialog,
  setShowDialog,
  cancelNavigation,
  confirmNavigation,
}) {
  const handleDialogClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog fullWidth open={showDialog} onClose={handleDialogClose}>
      <DialogTitle>Leaving Edit activity page</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to proceed?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            border: "solid 1px #f21d44",
            color: "#212121",
            ":hover": {
              background: "#f25e7a",
            },
          }}
          variant="outlined"
          onClick={cancelNavigation}
        >
          No
        </Button>
        <Button
          sx={{
            background: "#3E89D6",
            color: "#FFF",
            ":hover": {
              background: "#6da5de",
            },
          }}
          variant="contained"
          onClick={confirmNavigation}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
