export const FETCH_ACTIVITY = "FETCH_ACTIVITY";
export const MODIFY_ACTIVITY_STATE = "MODIFY_ACTIVITY_STATE";
export const FETCH_ACTIVITY_PAGE = "FETCH_ACTIVITY_PAGE";
export const FETCH_ALL_ACTIVITY = "FETCH_ALL_ACTIVITY";

export const FETCH_BOOKING = "FETCH_BOOKING";
export const FETCH_ALL_BOOKINGS = "FETCH_ALL_BOOKINGS";

export const FETCH_ALL_ORDERS = "FETCH_ALL_ORDERS";

export const FETCH_ALL_ACTIVITY_CATEGORIES = "FETCH_ALL_ACTIVITY_CATEGORIES";
export const FETCH_ACTIVITY_CATEGORY = "FETCH_ACTIVITY_CATEGORY";
export const FETCH_ALL_MAIN_CATEGORIES = "FETCH_ALL_MAIN_CATEGORIES";
export const FETCH_MAIN_CATEGORY = "FETCH_MAIN_CATEGORY";

export const FETCH_QUESTIONNAIRE = "FETCH_QUESTIONNAIRE";
export const FETCH_QUESTION = "FETCH_QUESTION";

export const FETCH_REPORTS = "FETCH_REPORTS";

export const FETCH_UTILS = "FETCH_UTILS";

export const FETCH_POSTS = "FETCH_POSTS";

export const FETCH_COUNTRY = "FETCH_COUNTRY";
export const FETCH_ALL_COUNTRIES = "FETCH_ALL_COUNTRIES";

export const FETCH_CITY = "FETCH_CITY";
export const FETCH_ALL_CITIES = "FETCH_ALL_CITIES";

export const FETCH_ALL_PLANS = "FETCH_ALL_PLANS";
export const FETCH_PLAN = "FETCH_PLAN";

export const SIGN_IN = "SIGN_IN";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
