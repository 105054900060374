// import axios from "axios";
import axios from "axios";
import { FETCH_USERS, FETCH_USER_PROFILE, SIGN_IN } from "../types";
import instance, { baseURL } from "../../helpers/axiosInstance";
// import { displayResponse } from "./Response";

export const signinUser =
  (userData = {}, handleSignin) =>
  async (dispatch) => {
    try {
      const { data } = await instance.post("/users/sign-in/crm", userData);

      console.log(data);

      dispatch({
        type: SIGN_IN,
        payload: data?.user?.user,
      });

      alert("Signed in successfuly");
      handleSignin(data);
    } catch (error) {
      // handleSignin(error);
      console.log(error);
      alert(error.response.data?.error);
      dispatch({
        type: SIGN_IN,
        payload: null,
      });
    }
  };

export const signoutUser = (conditon) => (dispatch) => {
  localStorage.removeItem("jadwelny_web_XSDFR");
  window.location.reload();

  dispatch({
    type: SIGN_IN,
    payload: null,
  });
};

export const fetchUser = () => async (dispatch) => {
  try {
    const { data } = await instance.post("/users/fetch");
    dispatch({
      type: SIGN_IN,
      payload: data?.user,
    });
  } catch (error) {
    dispatch({
      type: SIGN_IN,
      payload: null,
    });
  }
};

export const fetchUserProfile = (conditon) => async (dispatch) => {
  try {
    if (conditon?.unmount === true) {
      return dispatch({
        type: FETCH_USER_PROFILE,
        payload: null,
      });
    }
    const { data } = await axios.post(`${baseURL}/users/fetch-admin`, conditon);
    return dispatch({
      type: FETCH_USER_PROFILE,
      payload: data?.user,
    });
  } catch (error) {
    return dispatch({
      type: FETCH_USER_PROFILE,
      payload: null,
    });
  }
};

export const deleteUser = (conditon) => async (dispatch) => {
  try {
    await axios.delete(`${baseURL}/users`, { data: conditon });

    const { data } = await axios.get(`${baseURL}/users`);

    dispatch({
      type: FETCH_USERS,
      payload: data?.users,
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateUserProfile = (conditon) => async () => {
  try {
    const { data } = await axios.put(`${baseURL}/users/admin`, conditon);

    if (data?.status >= 200 && data?.status <= 205) {
      return alert("Updated user successfully");
    }
    return alert("Something went wrong");
  } catch (error) {
    return alert("Something went wrong");
  }
};

export const verifyUser = (conditon, verified) => async (dispatch) => {
  try {
    if (verified) {
      const { data } = await instance.post("/profiles/unverify-user", conditon);
      if (data?.status >= 200 && data?.status <= 205) {
        alert("user unverified");
        return dispatch(fetchUsers());
      }
    } else {
      const { data } = await instance.post("/profiles/verify-user", conditon);
      if (data?.status >= 200 && data?.status <= 205) {
        alert("user unverified");
        return dispatch(fetchUsers());
      }
    }

    return alert("Something went wrong");
  } catch (error) {
    return alert("Something went wrong");
  }
};

export const createUserProfile = (conditon, profile, cover) => async () => {
  try {
    const formData = new FormData();
    const formData2 = new FormData();

    if (profile?.length > 0) {
      formData.append(`images`, profile[0], profile[0]?.name);

      const profileUp = await axios({
        method: "POST",
        url: `${baseURL}/upload?img_dir=profile`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // eslint-disable-next-line no-param-reassign
      conditon = { ...conditon, profile_picture: profileUp.data.upload[0] };
    }
    if (cover?.length > 0) {
      formData2.append(`images`, cover[0], cover[0]?.name);

      const coverUp = await axios({
        method: "POST",
        url: `${baseURL}/upload?img_dir=profile`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData2,
      });

      // eslint-disable-next-line no-param-reassign
      conditon = { ...conditon, cover_picture: coverUp.data.upload[0] };
    }

    const { data } = await axios.post(`${baseURL}/users/sign-up`, conditon);

    if (data?.status >= 200 && data?.status <= 205) {
      return alert("Updated user successfully");
    }
    return alert("Something went wrong");
  } catch (error) {
    return alert("Something went wrong");
  }
};

export const updateUserProfileCover = (cover, username) => async () => {
  try {
    const formData2 = new FormData();

    if (cover?.length > 0) {
      formData2.append(`images`, cover[0], cover[0]?.name);

      const coverUp = await axios({
        method: "POST",
        url: `${baseURL}/upload?img_dir=profile`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData2,
      });

      // eslint-disable-next-line no-param-reassign
      const { data } = await axios.put(`${baseURL}/users/admin`, {
        cover_picture: coverUp.data.upload[0],
        username,
      });
      if (data?.status >= 200 && data?.status <= 205) {
        return alert("Updated user successfully");
      }
      return alert("Something went wrong");
    }
    return alert("Please provide an image");
  } catch (error) {
    return alert("Something went wrong");
  }
};

export const updateUserProfilePicture = (pic, username) => async () => {
  try {
    const formData2 = new FormData();

    if (pic?.length > 0) {
      formData2.append(`images`, pic[0], pic[0]?.name);

      const coverUp = await axios({
        method: "POST",
        url: `${baseURL}/upload?img_dir=profile`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData2,
      });

      console.log(coverUp);
      // eslint-disable-next-line no-param-reassign
      const { data } = await axios.put(`${baseURL}/users/admin`, {
        profile_picture: coverUp.data.upload[0],
        username,
      });
      console.log(data);
      if (data?.status >= 200 && data?.status <= 205) {
        return alert("Updated user successfully");
      }
      return alert("Something went wrong");
    }
    return alert("Please provide an image");
  } catch (error) {
    return alert("Something went wrong");
  }
};

export const fetchUsers = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseURL}/users`);
    dispatch({
      type: FETCH_USERS,
      payload: data?.users,
    });
  } catch (error) {
    dispatch({
      type: FETCH_USERS,
      payload: null,
    });
  }
};
