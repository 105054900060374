import axios from "axios";

const jwt = localStorage.getItem("jadwelny_web_XSDFR");

export default axios.create({
  baseURL: "https://api.jadwelny.com/jadwelny-api/v1",
  headers: {
    Authorization: `Bearer ${jwt}`,
  },
});

// export default axios.create({
//   baseURL: "https://api.jadwelny.com/jadwelny-api/v1",
//   headers: {
//     Authorization: `Bearer ${jwt}`,
//   },
// });

export const baseURL = "https://api.jadwelny.com/jadwelny-api/v1";
// export const baseURL = "https://api.jadwelny.com/jadwelny-api/v1";
