/* eslint-disable no-nested-ternary */
import { Close, Delete, ExpandMore, Save } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  // FormControl,
  Grid,
  IconButton,
  Modal,
  // InputLabel,
  // MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select from "react-select";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateActivity, fetchActivity } from "redux/Actions/Activities";
import { fetchActivityCategories } from "redux/Actions/ActivityCategory";
import {
  uploadVerticalBranchMedia,
  uploadHorizontalBranchMedia,
  deleteBranchMedia,
  deleteBranchMedias,
  updateBranchesWithWorkingHoursBulk,
} from "redux/Actions/Branch";
import ConfirmationDialog from "components/Confirmation";
import { fetchMainCategories } from "redux/Actions/MainCategory";
import { updateACR, createACR, deleteACR } from "redux/Actions/ActivityCategoryRelations";
import Loader from "examples/Loader";
import CreatableSelect from "react-select/creatable";

import useNavigatingAway from "examples/useNavigationAway";
import DialogLeavingPage from "examples/DialogLeavingPage";
import BranchComponent from "./BranchComponent";
import AddBranchComp from "./AddBranch";
import ImagePreviewComponent from "./ImagePreview";
import moment from "moment";
import { DatePicker, LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { deleteBranch } from "redux/Actions/Branch";

function Edit() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "10px",
    transform: "translate(-50%, -50%)",
    width: "10px",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    borderRadius: "15px",
  };
  const dispatch = useDispatch();
  const { id } = useParams();

  const activity = useSelector((state) => state.activity);
  const activityCategories = useSelector((state) => state.activity_categories);
  const mainCategories = useSelector((state) => state.main_categories);

  const [Activity, setActivity] = useState({});
  const [AddBranch, setAddBranch] = useState(false);

  const [previewImages, setPreviewImages] = useState([]);
  const [previewImages1, setPreviewImages1] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [horizontalImageChange, setHorizontalImageChange] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [verticalImageChange, setVerticalImageChange] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [Files, setFiles] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [horizontalImageFile, setHorizontalImageFile] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [Files1, setFiles1] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [verticalImageFile, setVerticalImageFile] = useState(null);

  const [open, setOpen] = useState(null);
  const [Image, setImage] = useState(null);

  const [ActivityCategoryOptions, setActivityCategoryOptions] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [MainCategoriesOptions, setMainCategoriesOptions] = useState(null);

  const [ACROptions, setACROptions] = useState(null);
  const [ActivityCategoryRelations, setActivityCategoryRelations] = useState(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [newACR, setNewACR] = useState({});
  const [addNewACR, setAddNewACR] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [Branch, setBranch] = useState(null);
  const [DirtyFields, setDirtyFields] = useState(null);
  // const [isLeaving, setIsLeaving] = useState(false);
  // const [CitiesOptions, setCitiesOptions] = useState(null);

  // dialog
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  // const [ActivityChange, setActivityChange] = useState(false);
  // const [BranchChange, setBranchChange] = useState(false);
  // const [BranchMediaChange, setBranchMediaChange] = useState(false);
  // const handleLeavePage = () => {
  //   setIsLeaving(true);
  // };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleShowImage = (img) => {
    setOpen(true);
    setImage(img);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setPreviewImages(image);
        setFiles(files.filter((val) => allowedTypes.includes(val?.type)));
        setDirtyFields({ ...DirtyFields, vertical_branches: true });
      })
      .catch((error) => console.error(error));
  };
  const handleImageChange1 = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setPreviewImages1(image);
        setFiles1(files.filter((val) => allowedTypes.includes(val?.type)));
        setDirtyFields({ ...DirtyFields, horizontal_branches: true });
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteBranch = (branch) => {
    const confirm = window.confirm("Are you sure you want to delete this branch?");
    if (!confirm) return;
    const arr = Branch.filter((val) => val?.branch_id !== branch?.branch_id);
    setBranch(arr);

    dispatch(deleteBranch(branch));
  };

  const handleHorizontalImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setHorizontalImageChange(image);
        setHorizontalImageFile(files.filter((val) => allowedTypes.includes(val?.type)));
        setDirtyFields({ ...DirtyFields, horizontal_thumbnail: true });
      })
      .catch((error) => console.error(error));
  };

  const handleVerticalImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setVerticalImageChange(image);
        setVerticalImageFile(files.filter((val) => allowedTypes.includes(val?.type)));
        setDirtyFields({ ...DirtyFields, verical_thumbnail: true });
      })
      .catch((error) => console.error(error));
  };

  // eslint-disable-next-line camelcase
  const handleDeleteImage = (e, branch_media_id) => {
    e.preventDefault();
    e.stopPropagation();
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Do you really want to delete this image ?")) {
      // eslint-disable-next-line camelcase
      dispatch(deleteBranchMedia({ branch_media_id }));
      setTimeout(() => {
        dispatch(fetchActivity({ activity_id: activity?.activity_id, unmount: false }));
      }, 300);
    }
  };

  const handleDeletePreview = (index, e) => {
    e.stopPropagation();
    const newArray = [...previewImages];
    const newFiles = [...Files];
    newArray?.splice(index, 1);
    newFiles?.splice(index, 1);
    setPreviewImages(newArray);
    setFiles(newFiles);
  };

  const handleDeletePreview1 = (index, e) => {
    e.stopPropagation();
    const newArray = [...previewImages1];
    const newFiles = [...Files1];
    newArray?.splice(index, 1);
    newFiles?.splice(index, 1);
    setPreviewImages1(newArray);
    setFiles1(newFiles);
  };

  const handleSubmitVerticalImage = (e, branchId) => {
    e.preventDefault();
    setLoading(true);
    dispatch(uploadVerticalBranchMedia(Files, branchId, activity?.activity_id, setLoading));
    setPreviewImages([]);
    setFiles(null);
    delete DirtyFields.vertical_branches;
    setDirtyFields({ ...DirtyFields });
  };
  const handleSubmitHorizontalImage = (e, branchId) => {
    e.preventDefault();
    setLoading(true);
    dispatch(uploadHorizontalBranchMedia(Files1, branchId, activity?.activity_id, setLoading));
    setPreviewImages1([]);
    setFiles1(null);
    delete DirtyFields.horizontal_branches;
    setDirtyFields({ ...DirtyFields });
  };

  const handleActivityCategoryChange = (option) => {
    Activity.activity_category_id = option?.value;

    setActivity({ ...Activity });

    setDirtyFields({ ...DirtyFields, activity_category_id: true });
  };

  const handleDeleteAllVerticalImages = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete all vertical images ?");
    if (!confirmed) return;
    setPreviewImages([]);
    setFiles(null);
    if (
      activity?.branch[0]?.branch_media?.filter((val) => val?.dimension_type === "vertical")
        .length > 0
    ) {
      dispatch(
        deleteBranchMedias(
          activity?.branch[0]?.branch_media?.filter((val) => val?.dimension_type === "vertical"),
          activity?.activity_id
        )
      );
    }
  };

  const handleDeleteAllHorizontalImages = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete all vertical images ?");
    if (!confirmed) return;
    setPreviewImages1([]);
    setFiles1(null);
    if (
      activity?.branch[0]?.branch_media?.filter((val) => val?.dimension_type === "horizontal")
        .length > 0
    ) {
      dispatch(
        deleteBranchMedias(
          activity?.branch[0]?.branch_media?.filter((val) => val?.dimension_type === "horizontal"),
          activity?.activity_id
        )
      );
    }
  };

  useEffect(() => {
    if (activity && Object.keys(activity)?.length > 0) {
      setActivity({
        activity_id: activity?.activity_id,
        name_en: activity?.name_en,
        name_ar: activity?.name_ar,
        horizontal_thumbnail: activity?.horizontal_thumbnail,
        vertical_thumbnail: activity?.vertical_thumbnail,
        description_en: activity?.description_en,
        description_ar: activity?.description_ar,
        restriction_en: activity?.restriction_en,
        restriction_ar: activity?.restriction_ar,
        availability_en: activity?.availability_en,
        availability_ar: activity?.availability_ar,
        duration_mins: activity?.duration_mins,
        phone_number: activity?.phone_number,
        facebook_link: activity?.facebook_link,
        instagram_link: activity?.instagram_link,
        twitter_link: activity?.twitter_link,
        tiktok_link: activity?.tiktok_link,
        snapchat_link: activity?.snapchat_link,
        whatsapp_link: activity?.whatsapp_link,
        website_link: activity?.website_link,
        threads_link: activity?.threads_link,
        youtube_link: activity?.youtube_link,
        linkedin_link: activity?.linkedin_link,
        target_audience_from: activity?.target_audience_from,
        target_audience_to: activity?.target_audience_to,
        activity_category_id: activity?.activity_category_id,
        keywords: activity?.keywords,
        seasonal_closing_start_time: activity?.seasonal_closing_start_time,
        seasonal_closing_end_time: activity?.seasonal_closing_end_time,
      });

      if (activity?.activity_category_relation?.length > 0) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        setActivityCategoryRelations([...activity?.activity_category_relation]);
      }
      if (activity?.keywords?.length > 0 && keywords?.length === 0) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        setKeywords([...activity?.keywords?.map((val) => ({ value: val, label: val }))]);
      }
      if (activity?.branch?.length > 0) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        setBranch([...activity?.branch]);
      }
    }
  }, [activity]);

  const handleActivityChange = (e) => {
    Activity[`${e.target.name}`] = e.target.value;
    setActivity({ ...Activity });

    setDirtyFields({ ...DirtyFields, activity_field: true });
  };

  const handleChangeKeywords = (val) => {
    setKeywords(val);
    Activity.keywords = keywords?.map((newVal) => newVal?.value);
    setActivity({ ...Activity });
    setDirtyFields({ ...DirtyFields, keywords: true });
  };

  const handleActivityCategoryRelationChange = (e, index) => {
    ActivityCategoryRelations[index][`${e.target.name}`] = e.target.value;
    setActivityCategoryRelations([...ActivityCategoryRelations]);
    setDirtyFields({ ...DirtyFields, Acr: true });
  };
  const handleActivityCategoryRelationChangeMain = (option, index) => {
    ActivityCategoryRelations[index].main_category_id = option.value;
    ActivityCategoryRelations[index].main_category.name_en = option.label;
    setActivityCategoryRelations([...ActivityCategoryRelations]);
    setDirtyFields({ ...DirtyFields, Acr: true });
  };

  const handleNewActivityCategoryRelationChangeMain = (option) => {
    const obj = {
      main_category_id: option.value,
      main_category: {
        name_en: option.label,
      },
    };

    setNewACR(obj);
    setDirtyFields({ ...DirtyFields, Acr: true });
  };

  const handleCreateACR = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(createACR({ ...newACR, activity_id: activity?.activity_id }));
    setNewACR({});
    setAddNewACR(false);
    delete DirtyFields.Acr;

    setDirtyFields({ ...DirtyFields });
  };

  const handleNewActivityCategoryRelationChange = (e) => {
    newACR[`${e.target.name}`] = e.target.value;
    setNewACR(setNewACR);
    setDirtyFields({ ...DirtyFields, Acr: true });
  };

  useEffect(() => {
    dispatch(fetchActivity({ activity_id: id, unmount: false }));
  }, [id]);

  useEffect(() => {
    dispatch(fetchActivityCategories({ unmount: false }));
    dispatch(fetchMainCategories({ unmount: false }));
    return () => {
      dispatch(fetchActivity({ unmount: true }));
    };
  }, []);

  useEffect(() => {
    if (activityCategories?.length > 0) {
      setActivityCategoryOptions(
        activityCategories?.map((val) => ({
          value: val?.activity_category_id,
          label: `${val?.main_category?.name_en} - ${val?.name_en}`,
        }))
      );
    }
  }, [activityCategories]);

  useEffect(() => {
    if (mainCategories?.length > 0) {
      setMainCategoriesOptions(
        mainCategories?.map((val) => ({
          value: val?.main_category_id,
          label: val?.name_en,
        }))
      );
    }
  }, [mainCategories]);

  useEffect(() => {
    if (
      activity &&
      activity?.activity_category_relation?.length > 0 &&
      mainCategories?.length > 0
    ) {
      // eslint-disable-next-line array-callback-return, consistent-return
      const ACROps = mainCategories?.filter((val) => {
        const index = activity?.activity_category_relation?.filter(
          (obj) => val?.main_category_id === obj?.main_category_id
        );
        return index?.length <= 0;
      });
      setACROptions(ACROps?.map((val) => ({ value: val?.main_category_id, label: val?.name_en })));
    } else if (
      activity &&
      activity?.activity_category_relation?.length === 0 &&
      mainCategories?.length > 0
    ) {
      const ACROps = mainCategories?.map((val) => ({
        value: val?.main_category_id,
        label: val?.name_en,
      }));
      setACROptions(ACROps);
    }
  }, [activity, mainCategories]);

  useEffect(() => {
    if (Activity && Object.keys(Activity)?.length > 0) {
      Activity.keywords = keywords?.map((val) => val?.value);
      setActivity(Activity);
    }
  }, [keywords]);

  useEffect(() => {
    if (DirtyFields && Object.keys(DirtyFields).length > 0) {
      setCanShowDialogLeavingPage(true);
    } else if (!DirtyFields || Object.keys(DirtyFields).length <= 0) {
      setCanShowDialogLeavingPage(false);
    }
  }, [DirtyFields]);

  const handleUpdateACR = (event, val) => {
    event.preventDefault();

    dispatch(updateACR({ ...val }));
    delete DirtyFields.Acr;
    setDirtyFields({ ...DirtyFields });
  };

  const handleDeleteACR = (event, val) => {
    event.preventDefault();

    setActivityCategoryRelations([
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...ActivityCategoryRelations?.filter(
        (obj) => obj?.activity_category_relation_id !== val?.activity_category_relation_id
      ),
    ]);
    dispatch(deleteACR(val));
    delete DirtyFields.Acr;
    setDirtyFields({ ...DirtyFields });
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!Activity?.horizontal_thumbnail && horizontalImageChange?.length < 1) {
      return alert("Please add horizontal image");
    }
    if (!Activity?.vertical_thumbnail && verticalImageChange?.length < 1) {
      return alert("Please add vertical image");
    }
    console.log(Activity);
    setLoading(true);
    dispatch(
      updateActivity(
        Activity,
        verticalImageFile,
        horizontalImageFile,
        activity?.activity_id,
        setLoading
      )
    );
    dispatch(updateBranchesWithWorkingHoursBulk(Branch));
    delete DirtyFields?.verical_thumbnail;
    delete DirtyFields?.horizontal_thumbnail;
    delete DirtyFields?.activity_field;
    delete DirtyFields?.activity_category_id;
    delete DirtyFields?.branch;
    delete DirtyFields?.keywords;
    setDirtyFields({ ...DirtyFields });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {activity !== null && (activity?.length > 0 || Object?.keys(activity).length > 0) ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              {
                // eslint-disable-next-line no-nested-ternary
                Activity?.horizontal_thumbnail ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "300px",
                      borderRadius: "20px",
                      background: `url("${Activity.horizontal_thumbnail}") center center / cover no-repeat`,
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => handleShowImage(Activity.horizontal_thumbnail)}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        background: "#212121",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "15px",
                        padding: "2px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setActivity({ ...Activity, horizontal_thumbnail: null });
                      }}
                    >
                      <Close color="error" />
                    </Box>
                  </Box>
                ) : !Activity?.horizontal_thumbnail && horizontalImageChange?.length > 0 ? (
                  horizontalImageChange?.map((val) => (
                    <Box
                      sx={{
                        width: "100%",
                        height: "300px",
                        borderRadius: "20px",
                        background: `url("${val}") center center / cover no-repeat`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShowImage(val)}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "#212121",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "15px",
                          padding: "2px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setHorizontalImageChange([]);
                          setHorizontalImageFile(null);
                        }}
                      >
                        <Close color="error" />
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "300px",
                      borderRadius: "20px",
                      background: `linear-gradient(to right, #2193b0, #6dd5ed)`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Typography color="#FFF" sx={{ position: "absolute", top: "45%", left: "35%" }}>
                      Upload horizontal image
                    </Typography>
                    <input
                      type="file"
                      accept="image/jpeg,image/jpg,image/png"
                      style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
                      onChange={handleHorizontalImageChange}
                    />
                  </Box>
                )
              }
            </Grid>
            <Grid item xs={3}>
              {
                // eslint-disable-next-line no-nested-ternary
                Activity?.vertical_thumbnail ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "300px",
                      borderRadius: "20px",
                      background: `url("${Activity.vertical_thumbnail}") center center / cover no-repeat`,
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onClick={() => handleShowImage(Activity.vertical_thumbnail)}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        background: "#212121",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "15px",
                        padding: "2px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setActivity({ ...Activity, vertical_thumbnail: null });
                      }}
                    >
                      <Close color="error" />
                    </Box>
                  </Box>
                ) : !Activity?.vertical_thumbnail && verticalImageChange?.length > 0 ? (
                  verticalImageChange?.map((val) => (
                    <Box
                      sx={{
                        width: "100%",
                        height: "300px",
                        borderRadius: "20px",
                        background: `url("${val}") center center / cover no-repeat`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShowImage(val)}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "#212121",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "15px",
                          padding: "2px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setVerticalImageChange([]);
                          setVerticalImageFile(null);
                        }}
                      >
                        <Close color="error" />
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "300px",
                      borderRadius: "20px",
                      background: `linear-gradient(to right, #2193b0, #6dd5ed)`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Typography color="#FFF" sx={{ position: "absolute", top: "40%", left: "25%" }}>
                      Upload Vertical Image
                    </Typography>
                    <input
                      type="file"
                      accept="image/jpeg,image/jpg,image/png"
                      style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
                      onChange={handleVerticalImageChange}
                    />
                  </Box>
                )
              }
            </Grid>
          </Grid>

          <Box pt={2} onSubmit={handleSubmit} component="form">
            <Typography>Details</Typography>
            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                variant="filled"
                label="Name English"
                name="name_en"
                type="text"
                value={Activity?.name_en}
                onChange={(e) => handleActivityChange(e)}
                size="medium"
                sx={{ width: "50%" }}
              />
              <TextField
                variant="filled"
                label="Name Arabic"
                inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
                name="name_ar"
                value={Activity.name_ar}
                onChange={(e) => handleActivityChange(e)}
                size="medium"
                sx={{ width: "50%" }}
              />
            </Box>
            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                multiline
                sx={{ width: "50%" }}
                rows={6}
                name="description_en"
                variant="filled"
                label="Description English"
                value={Activity.description_en}
                onChange={(e) => handleActivityChange(e)}
              />
              <TextField
                multiline
                rows={6}
                sx={{ width: "50%" }}
                variant="filled"
                name="description_ar"
                inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
                label="Description Arabic"
                value={Activity.description_ar}
                onChange={(e) => handleActivityChange(e)}
              />
            </Box>
            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                multiline
                rows={6}
                variant="filled"
                label="Restriction English"
                name="restriction_en"
                value={Activity.restriction_en}
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />

              <TextField
                fullWidth
                multiline
                rows={6}
                variant="filled"
                label="Restriction Arabic"
                inputProps={{ style: { textAlign: "right" } }}
                name="restriction_ar"
                value={Activity.restriction_ar}
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />
            </Box>
            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                multiline
                rows={6}
                variant="filled"
                label="Availability English"
                name="availability_en"
                value={Activity.availability_en}
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />

              <TextField
                fullWidth
                multiline
                rows={6}
                variant="filled"
                label="Availability Arabic"
                inputProps={{ style: { textAlign: "right" } }}
                name="availability_ar"
                value={Activity.availability_ar}
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />
            </Box>

            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                variant="filled"
                label="Duration in minutes"
                name="duration_mins"
                value={Activity.duration_mins}
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Phone number"
                name="phone_number"
                value={Activity.phone_number}
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />
            </Box>
            <Typography pt={2}>Seasonal closing time</Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={
                    Activity?.seasonal_closing_start_time
                      ? moment(Activity?.seasonal_closing_start_time)
                      : null
                  }
                  name="seasonal_closing_start_time"
                  renderInput={(props) => <TextField {...props} variant="filled" fullWidth />}
                  inputFormat="DD/MM/YYYY"
                  onChange={(date) => {
                    if (!date) {
                      setDirtyFields({ ...DirtyFields, activity_field: true });
                      return setActivity({
                        ...Activity,
                        seasonal_closing_start_time: null,
                      });
                    }
                    setActivity({
                      ...Activity,
                      seasonal_closing_start_time: moment(date).format(),
                    });
                    setDirtyFields({ ...DirtyFields, activity_field: true });
                  }}
                  label="Seasonal closing start time"
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={
                    Activity?.seasonal_closing_end_time
                      ? moment(Activity?.seasonal_closing_end_time)
                      : null
                  }
                  name="seasonal_closing_end_time"
                  renderInput={(props) => <TextField {...props} variant="filled" fullWidth />}
                  inputFormat="DD/MM/YYYY"
                  onChange={(date) => {
                    if (!date) {
                      setDirtyFields({ ...DirtyFields, activity_field: true });
                      return setActivity({
                        ...Activity,
                        seasonal_closing_end_time: null,
                      });
                    }
                    setActivity({
                      ...Activity,
                      seasonal_closing_end_time: moment(date).format(),
                    });
                    setDirtyFields({ ...DirtyFields, activity_field: true });
                  }}
                  label="Seasonal closing end time"
                />
              </LocalizationProvider>
            </Box>
            <Box pt={2}>
              <Typography>Key-words</Typography>
              <CreatableSelect
                isClearable
                placeholder="Add keywords"
                noOptionsMessage={() => "No key-words"}
                isMulti
                onChange={(val) => handleChangeKeywords(val)}
                options={keywords}
                value={keywords}
              />
            </Box>
            <Typography pt={2}>Precentages with main categories</Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={() => setAddNewACR(!addNewACR)}>{`${
                addNewACR ? "cancel" : "Add new Activity relation"
              }`}</Button>
            </Box>
            {addNewACR && ACROptions?.length > 0 && (
              <Box>
                <Box pt={2} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Box sx={{ width: "50%" }}>
                    <Select
                      options={ACROptions}
                      placeholder="Select Main category..."
                      onChange={(option) => handleNewActivityCategoryRelationChangeMain(option)}
                    />
                  </Box>
                  <TextField
                    fullWidth
                    type="number"
                    variant="filled"
                    label="Percentage"
                    size="small"
                    name="percentage"
                    InputProps={{ inputProps: { type: "number", min: 1, max: 100 } }}
                    value={newACR?.percentage}
                    onChange={handleNewActivityCategoryRelationChange}
                    sx={{ width: "50%" }}
                  />
                  <IconButton
                    aria-label="update"
                    onClick={(e) => handleCreateACR(e)}
                    sx={{
                      background: "#3E89D6",
                      color: "#FFF",
                      width: "40px",
                      height: "40px",
                      ":hover": {
                        background: "#7baadb",
                      },
                    }}
                  >
                    <Save />
                  </IconButton>
                </Box>
              </Box>
            )}
            {ActivityCategoryRelations?.length > 0 &&
              ActivityCategoryRelations?.map((val, index) => (
                <Box>
                  <Box pt={2} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box sx={{ width: "50%" }}>
                      <Select
                        options={MainCategoriesOptions}
                        placeholder="Select Activity Category..."
                        onChange={(option) =>
                          handleActivityCategoryRelationChangeMain(option, index)
                        }
                        value={{ value: val?.main_category_id, label: val?.main_category?.name_en }}
                      />
                    </Box>
                    <TextField
                      fullWidth
                      type="number"
                      variant="filled"
                      label="Percentage"
                      size="small"
                      name="percentage"
                      InputProps={{ inputProps: { type: "number", min: 1, max: 100 } }}
                      value={val?.percentage}
                      onChange={(e) => handleActivityCategoryRelationChange(e, index)}
                      sx={{ width: "50%" }}
                    />
                    <IconButton
                      aria-label="Update"
                      onClick={(e) => handleUpdateACR(e, val)}
                      sx={{
                        background: "#3E89D6",
                        color: "#FFF",
                        width: "40px",
                        height: "40px",
                        ":hover": {
                          background: "#7baadb",
                        },
                      }}
                    >
                      <Save />
                    </IconButton>
                    <IconButton
                      aria-label="Delete"
                      onClick={(e) => handleDeleteACR(e, val)}
                      sx={{
                        background: "#ffbaba",
                        color: "#ff0000",
                        width: "40px",
                        height: "40px",
                        ":hover": {
                          background: "#ffe6e6",
                        },
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            <Typography pt={2}>Social Media</Typography>
            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                variant="filled"
                label="Facebook link"
                name="facebook_link"
                value={Activity.facebook_link}
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Instagram link"
                name="instagram_link"
                value={Activity.instagram_link}
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />
              {/* <Link to="/another-page">Go to Another Page</Link> */}
              {/* <Button onClick={handleLeavePage}>Leave Page</Button> */}
            </Box>
            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                variant="filled"
                label="Twitter Link"
                value={Activity.twitter_link}
                name="twitter_link"
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Tiktok link"
                value={Activity.tiktok_link}
                name="tiktok_link"
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />
            </Box>

            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                variant="filled"
                label="Snapchat Link"
                value={Activity.snapchat_link}
                name="snapchat_link"
                onChange={(e) => handleActivityChange(e)}
                sx={{ width: "50%" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Whatsapp Link"
                value={Activity.whatsapp_link}
                onChange={(e) => handleActivityChange(e)}
                name="whatsapp_link"
                sx={{ width: "50%" }}
              />
            </Box>
            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                variant="filled"
                label="Website Link"
                value={Activity.website_link}
                onChange={(e) => handleActivityChange(e)}
                name="website_link"
                sx={{ width: "50%" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Threads Link"
                value={Activity.threads_link}
                onChange={(e) => handleActivityChange(e)}
                name="threads_link"
                sx={{ width: "50%" }}
              />
            </Box>
            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                variant="filled"
                label="Youtube Link"
                value={Activity.youtube_link}
                onChange={(e) => handleActivityChange(e)}
                name="youtube_link"
                sx={{ width: "50%" }}
              />

              <TextField
                fullWidth
                variant="filled"
                label="Linked-in Link"
                value={Activity.linkedin_link}
                onChange={(e) => handleActivityChange(e)}
                name="linkedin_link"
                sx={{ width: "50%" }}
              />
            </Box>
            <Typography pt={2}>More information</Typography>
            <Box pt={2} sx={{ display: "flex", gap: 2 }}>
              <TextField
                variant="filled"
                label="Target audience from"
                value={Activity.target_audience_from}
                onChange={(e) => handleActivityChange(e)}
                name="target_audience_from"
                sx={{ width: "50%" }}
              />
              <TextField
                variant="filled"
                label="Target audience to"
                value={Activity.target_audience_to}
                onChange={(e) => handleActivityChange(e)}
                name="target_audience_to"
                sx={{ width: "50%" }}
              />
            </Box>
            {ActivityCategoryOptions?.length > 0 && (
              <>
                <Typography pt={2}>Category</Typography>
                <Grid item xs={12} md={6} pt={2}>
                  <Select
                    options={ActivityCategoryOptions}
                    placeholder="Select Activity Category..."
                    onChange={handleActivityCategoryChange}
                    value={
                      ActivityCategoryOptions?.filter(
                        (val) => val?.value === Activity?.activity_category_id
                      )[0]
                    }
                  />
                </Grid>
              </>
            )}

            <Grid pt={2}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }} pb={2}>
                <Button
                  onClick={() => {
                    if (AddBranch && DirtyFields && Object.keys(DirtyFields).length > 0) {
                      delete DirtyFields.add_branch;
                      setDirtyFields({ ...DirtyFields });
                    } else {
                      setDirtyFields({ ...DirtyFields, add_branch: true });
                    }
                    setAddBranch(!AddBranch);
                  }}
                >
                  {AddBranch ? "Cancel" : "Add new branch"}
                </Button>
              </Box>
              {AddBranch && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>New Branch</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AddBranchComp
                      activity={activity}
                      DirtyFields={DirtyFields}
                      setDirtyFields={setDirtyFields}
                    />
                  </AccordionDetails>
                </Accordion>
              )}

              {Branch?.length > 0 &&
                Branch?.map((val, index) => (
                  <Box sx={{ display: "flex", gap: "10px", alignItems: "flex-start", pt: 1 }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{`${val?.name_en} ${val?.name_ar}`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <BranchComponent
                          branch={val}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          Branch={Branch}
                          branchIndex={index}
                          setBranch={setBranch}
                          activity={activity}
                          DirtyFields={DirtyFields}
                          setDirtyFields={setDirtyFields}
                        />
                      </AccordionDetails>
                    </Accordion>
                    {index !== 0 && (
                      <IconButton color="error" onClick={() => handleDeleteBranch(val)}>
                        <Delete color="error" />
                      </IconButton>
                    )}
                  </Box>
                ))}
            </Grid>

            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }} py={3}>
              <Button
                size="large"
                sx={{
                  background: "#3E89D6",
                  color: "#FFF",
                  width: "50%",
                  marginX: "auto",
                  ":hover": {
                    background: "#7baadb",
                  },
                }}
                fullWidth
                color="error"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Box>

          <Typography pt={2}>Vertical Images</Typography>
          {((activity?.branch[0]?.branch_media?.length > 0 &&
            activity?.branch[0]?.branch_media?.filter((val) => val?.dimension_type === "vertical")
              .length > 0) ||
            previewImages?.length > 0) && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <Button
                onClick={() => {
                  handleDeleteAllVerticalImages(activity?.branch[0]?.branch_media);
                }}
                color="error"
                sx={{
                  display: "flex",
                  gap: 1,
                  color: "red",
                  ":hover": {
                    color: "#f53838",
                  },
                }}
              >
                Delete All
                <Delete />
              </Button>
            </Box>
          )}
          {true && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                background: "#FFF",
                overflow: "scroll",
              }}
              py={2}
              px={2}
              spacing={5}
            >
              {activity?.branch?.length > 0 &&
                activity?.branch[0]?.branch_media?.length > 0 &&
                activity?.branch[0]?.branch_media
                  ?.filter((val) => val?.dimension_type === "vertical")
                  ?.map((val) => (
                    <Box
                      sx={{
                        background: `url("${val.url}") center center / cover no-repeat`,
                        width: "150px",
                        height: "150px",
                        position: "relative",
                        borderRadius: "10px",
                        minWidth: "150px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShowImage(val?.url)}
                    >
                      <Box
                        sx={{
                          background: "#121212",
                          padding: "5px",
                          position: "absolute",
                          borderRadius: "50%",
                          height: "30px",
                          width: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          right: "2px",
                          top: "2px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleDeleteImage(e, val?.branch_media_id)}
                      >
                        <Close fontSize="medim" color="error" />
                      </Box>
                    </Box>
                  ))}
              {previewImages?.map((val, index) => (
                <Box
                  sx={{
                    background: `url("${val}") center center / cover no-repeat`,
                    width: "150px",
                    height: "150px",
                    position: "relative",
                    borderRadius: "10px",
                    minWidth: "150px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShowImage(val)}
                >
                  <Box
                    sx={{
                      background: "#121212",
                      padding: "5px",
                      position: "absolute",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      right: "2px",
                      top: "2px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleDeletePreview(index, e)}
                  >
                    <Close fontSize="medim" color="error" />
                  </Box>
                </Box>
              ))}
              <Box
                sx={{
                  background: `#212121`,
                  width: "150px",
                  height: "150px",
                  position: "relative",
                  borderRadius: "10px",
                  padding: "10px",
                  minWidth: "150px",
                }}
              >
                <Box
                  p={2}
                  sx={{
                    border: "dashed 2px #3E89D6",
                    width: "100%",
                    height: "100%",
                    padding: "0",
                  }}
                >
                  <p style={{ position: "absolute", top: "40%", right: "30%", color: "#FFF" }}>
                    Upload
                  </p>
                  <input
                    type="file"
                    accept="image/jpeg,image/png,image/jpeg"
                    multiple
                    style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
                    onChange={handleImageChange}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {previewImages?.length > 0 && (
            <Box sx={{ width: "100%" }} py={3}>
              <Button
                size="large"
                sx={{
                  background: "#3E89D6",
                  color: "#FFF",
                  width: "30%",
                  ":hover": {
                    background: "#7baadb",
                  },
                }}
                color="error"
                type="submit"
                onClick={(e) => handleSubmitVerticalImage(e, activity?.branch[0].branch_id)}
              >
                Upload
              </Button>
            </Box>
          )}

          <Typography pt={2}>Horizontal Images</Typography>
          {((activity?.branch[0]?.branch_media?.length > 0 &&
            activity?.branch[0]?.branch_media?.filter((val) => val?.dimension_type === "horizontal")
              .length > 0) ||
            previewImages1?.length > 0) && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <Button
                onClick={() => {
                  handleDeleteAllHorizontalImages(activity?.branch[0]?.branch_media);
                }}
                color="error"
                sx={{
                  display: "flex",
                  gap: 1,
                  color: "red",
                  ":hover": {
                    color: "#f53838",
                  },
                }}
              >
                Delete All
                <Delete />
              </Button>
            </Box>
          )}
          {true && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                background: "#FFF",
                overflow: "scroll",
              }}
              py={2}
              px={2}
              spacing={5}
            >
              {activity?.branch?.length > 0 &&
                activity?.branch[0]?.branch_media?.length > 0 &&
                activity?.branch[0]?.branch_media
                  ?.filter((val) => val?.dimension_type === "horizontal")
                  ?.map((val) => (
                    <Box
                      sx={{
                        background: `url("${val.url}") center center / cover no-repeat`,
                        width: "150px",
                        height: "150px",
                        position: "relative",
                        borderRadius: "10px",
                        minWidth: "150px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShowImage(val?.url)}
                    >
                      <Box
                        sx={{
                          background: "#121212",
                          padding: "5px",
                          position: "absolute",
                          borderRadius: "50%",
                          height: "30px",
                          width: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          right: "2px",
                          top: "2px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleDeleteImage(e, val?.branch_media_id)}
                      >
                        <Close fontSize="medim" color="error" />
                      </Box>
                    </Box>
                  ))}
              {previewImages1?.map((val, index) => (
                <Box
                  sx={{
                    background: `url("${val}") center center / cover no-repeat`,
                    width: "150px",
                    height: "150px",
                    position: "relative",
                    borderRadius: "10px",
                    minWidth: "150px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShowImage(val)}
                >
                  <Box
                    sx={{
                      background: "#121212",
                      padding: "5px",
                      position: "absolute",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      right: "2px",
                      top: "2px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleDeletePreview1(index, e)}
                  >
                    <Close fontSize="medim" color="error" />
                  </Box>
                </Box>
              ))}
              <Box
                sx={{
                  background: `#212121`,
                  width: "150px",
                  height: "150px",
                  position: "relative",
                  borderRadius: "10px",
                  padding: "10px",
                  minWidth: "150px",
                }}
              >
                <Box
                  p={2}
                  sx={{
                    border: "dashed 2px #3E89D6",
                    width: "100%",
                    height: "100%",
                    padding: "0",
                  }}
                >
                  <p style={{ position: "absolute", top: "40%", right: "30%", color: "#FFF" }}>
                    Upload
                  </p>
                  <input
                    type="file"
                    accept="image/jpeg,image/jpg,image/png"
                    multiple
                    style={{
                      opacity: "0",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      border: "solid 2px",
                    }}
                    onChange={(e) => handleImageChange1(e)}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {previewImages1?.length > 0 && (
            <Box sx={{ width: "100%" }} py={3}>
              <Button
                size="large"
                sx={{
                  background: "#3E89D6",
                  color: "#FFF",
                  width: "30%",
                  ":hover": {
                    background: "#7baadb",
                  },
                }}
                fullWidth
                color="error"
                type="submit"
                onClick={(e) => handleSubmitHorizontalImage(e, activity?.branch[0]?.branch_id)}
              >
                Upload
              </Button>
            </Box>
          )}
        </>
      ) : typeof activity === "object" && activity !== null ? (
        <Loader />
      ) : (
        <h1>Not found</h1>
      )}
      {open && <ImagePreviewComponent open={open} handleClose={handleClose} Image={Image} />}
      <ConfirmationDialog message="Are you sure you want to close this page?" />
      {loading && (
        <Modal open={loading}>
          <Box sx={{ ...style, position: "relative" }}>
            <img
              style={{ width: "80px", height: "80px" }}
              src="/Ellipsis-1s-200px.gif"
              alt="Loader"
            />
          </Box>
        </Modal>
      )}
      {/* <Prompt when message="Are you sure you want to leave?" /> */}
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      {/* <Prompt when={isLeaving} message="Are you sure you want to leave this page?" /> */}
    </DashboardLayout>
  );
}

export default Edit;
