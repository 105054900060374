/* eslint-disable no-unused-vars */
import { Box, Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import countriesTableData from "layouts/countries/data/countriesTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCountries } from "redux/Actions/Countries";

function Country() {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state?.countries);

  // const [Columns, setColumns] = useState([]);
  const [Rows, setRows] = useState([]);
  const [Columns, setColumns] = useState([]);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    dispatch(fetchAllCountries({ unmount: false }));

    return () => {
      dispatch(fetchAllCountries({ unmount: true }));
    };
  }, []);

  useEffect(() => {
    if (countries?.length > 0) {
      const { columns, rows } = countriesTableData(countries);
      setRows(rows);
      setColumns(columns);
      // console.log(rows);
      // console.log(columns);
    }
  }, [countries]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const arr =
      countries?.length > 0
        ? [...countries]?.filter((val) =>
            val?.name_en.toLowerCase().includes(search?.toLowerCase())
          )
        : [];

    if (arr?.length > 0) {
      const { rows } = countriesTableData(arr);
      return setRows(rows);
    }
  }, [search]);

  // const rows = [];
  const columns = [
    { Header: "author", accessor: "author", width: "45%", align: "left" },
    { Header: "function", accessor: "function", align: "left" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "employed", accessor: "employed", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Countries Table
                </MDTypography>
                <Box
                  sx={{ width: "30%", display: "flex", justifyContent: "space-between", gap: 2 }}
                >
                  <TextField
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search..."
                    InputProps={{
                      sx: {
                        "& input": {
                          background: "#FFF",
                          borderRadius: "8px",
                        },
                      },
                    }}
                  />
                </Box>
              </MDBox>
              {Rows?.length > 0 && (
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: Columns, rows: Rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Country;
