import { Box } from "@mui/material";
import React from "react";

function Loader() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "80vh",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img style={{ width: "80px", height: "80px" }} src="/Ellipsis-1s-200px.gif" alt="Loader" />
    </Box>
  );
}

export default Loader;
