import { FETCH_ALL_PLANS, FETCH_PLAN } from "../types";
import instance from "../../helpers/axiosInstance";

export const fetchAllPlans = () => async (dispatch) => {
  try {
    const { data } = await instance.get("/user-plans");

    return dispatch({
      type: FETCH_ALL_PLANS,
      payload:
        data?.user_plans.length > 0 || Object.keys(data?.user_plans).length > 0
          ? data?.user_plans
          : null,
    });
  } catch (error) {
    return dispatch({
      type: FETCH_ALL_PLANS,
      payload: null,
    });
  }
};

export const fetchPlan =
  (condition = {}) =>
  async (dispatch) => {
    try {
      if (condition.unmount) {
        return dispatch({
          type: FETCH_PLAN,
          payload: null,
        });
      }
      const { data } = await instance.post("/user-plans/admin/fetch", condition);

      return dispatch({
        type: FETCH_PLAN,
        payload:
          data?.user_plan.length > 0 || Object.keys(data?.user_plan).length > 0
            ? data?.user_plan
            : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_PLAN,
        payload: null,
      });
    }
  };
