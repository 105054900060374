import { FETCH_ACTIVITY_CATEGORY, FETCH_ALL_ACTIVITY_CATEGORIES } from "../types";
import instance from "../../helpers/axiosInstance";

export const fetchActivityCategories =
  (condition = {}) =>
  async (dispatch) => {
    try {
      const { data } = await instance.post("/acivity-categories/fetch-all", condition);

      return dispatch({
        type: FETCH_ALL_ACTIVITY_CATEGORIES,
        payload:
          data?.activity_categories.length > 0 || Object.keys(data?.activity_categories).length > 0
            ? data?.activity_categories
            : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_ALL_ACTIVITY_CATEGORIES,
        payload: null,
      });
    }
  };

export const fetchActivityCategory =
  (condition = {}) =>
  async (dispatch) => {
    try {
      if (condition?.unmount) {
        return dispatch({
          type: FETCH_ACTIVITY_CATEGORY,
          payload: {},
        });
      }
      const { data } = await instance.post("/acivity-categories/fetch", condition);

      return dispatch({
        type: FETCH_ACTIVITY_CATEGORY,
        payload:
          data?.activity_category.length > 0 || Object.keys(data?.activity_category).length > 0
            ? data?.activity_category
            : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_ACTIVITY_CATEGORY,
        payload: null,
      });
    }
  };

export const updateActivityCategory =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.put("/acivity-categories", condition);

      if (data?.status >= 200 && data?.status <= 205) {
        return alert("Updated activity category successfully");
      }
      return alert("Something went wrong");
    } catch (error) {
      return alert("Something went wrong");
    }
  };

export const deleteActivityCategory =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.delete("/acivity-categories", { data: condition });

      if (data?.status >= 200 && data?.status <= 205) {
        return alert("Deleted activity category successfully");
      }
      return alert("Something went wrong");
    } catch (error) {
      return alert("Something went wrong");
    }
  };
export const createActivityCategory =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.post("/acivity-categories", condition);

      if (data?.status >= 200 && data?.status <= 205) {
        return alert("created activity category successfully");
      }
      return alert("Something went wrong");
    } catch (error) {
      return alert("Something went wrong");
    }
  };

export const none = () => {};
