import axios from "axios";
import instance, { baseURL } from "../../helpers/axiosInstance";
import { fetchActivity } from "./Activities";

export const updateBranch =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.put("/branches", condition);
      console.log("data", data);
      alert("Updated branch successfully");
    } catch (error) {
      console.log("error", error);
      alert("failed to update branch");
    }
  };

export const createBranchWithWorkingHours =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.post("/branches", condition);
      console.log("data", data);
      alert("branch created successfully");
    } catch (error) {
      console.log("error", error);
      alert("branch failed to create successfully");
    }
  };

export const deleteBranch =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.delete("/branches", {
        data: condition,
      });
      console.log("data", data);
      alert("Branch deleted successfully");
    } catch (error) {
      console.log("error", error);
      alert("branch failed to delete");
    }
  };

export const deleteBranchMedia =
  (condition = {}) =>
  async () => {
    try {
      const { data } = await instance.delete("/branch-media", {
        data: condition,
      });
      console.log("data", data);
      alert("media deleted successfully");
    } catch (error) {
      console.log("error", error);
      alert("media failed to delete successfully");
    }
  };

export const deleteBranchMedias = (branchMedia, activityId) => async (dispatch) => {
  try {
    if (branchMedia?.length <= 0) {
      return;
    }

    const mapFunc = branchMedia?.map(async (val) => {
      try {
        const { data } = await instance.delete("/branch-media", {
          data: val,
        });
        return data;
      } catch (error) {
        // eslint-disable-next-line consistent-return
        return null;
      }
    });

    await Promise.all(mapFunc).then((res) => {
      console.log("data", res);
      alert("media deleted successfully");
      dispatch(fetchActivity({ activity_id: activityId }));
    });
  } catch (error) {
    console.log("error", error);
    alert("media failed to delete successfully");
  }
};

export const uploadVerticalBranchMedia =
  (images, branchId, activityId, loading) => async (dispatch) => {
    try {
      const formData = new FormData();

      await Promise.all(
        // eslint-disable-next-line array-callback-return
        Array.from(images).map((val) => {
          formData.append(`images`, val, val?.name);
        })
      ).then(async () => {
        const res = await axios({
          method: "POST",
          url: `${baseURL}/upload?img_dir=activity`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });

        const finalResult = res?.data?.upload?.map(async (val) => {
          const response = await instance.post("/branch-media", {
            is_image: true,
            url: val,
            dimension_type: "vertical",
            branch_id: branchId,
          });
          return response;
        });
        await Promise.all(finalResult).then(() => {
          loading(false);
          alert("Media uploaded succeessfully");

          dispatch(fetchActivity({ activity_id: activityId }));
        });
      });
    } catch (error) {
      loading(false);
      console.log(error);
      alert("failed to  upload media");
    }
  };

export const uploadHorizontalBranchMedia =
  (images, branchId, activityId, loading) => async (dispatch) => {
    try {
      const formData = new FormData();

      await Promise.all(
        // eslint-disable-next-line array-callback-return
        Array.from(images).map((val) => {
          formData.append(`images`, val, val?.name);
        })
      ).then(async () => {
        const res = await axios({
          method: "POST",
          url: `${baseURL}/upload?img_dir=activity`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });

        const finalResult = res?.data?.upload?.map(async (val) => {
          const response = await instance.post("/branch-media", {
            is_image: true,
            url: val,
            dimension_type: "horizontal",
            branch_id: branchId,
          });
          return response;
        });
        await Promise.all(finalResult).then(() => {
          loading(false);
          alert("Media uploaded succeessfully");

          dispatch(fetchActivity({ activity_id: activityId }));
        });
      });
    } catch (error) {
      loading(false);
      console.log(error);
      alert("failed to  upload media");
    }
  };

export const updateBranchesWithWorkingHoursBulk = (branches) => async () => {
  try {
    const { data } = await instance.put("/branches/bulk", { branches });
    console.log("data", data);
    alert("Updated branches successfully");
  } catch (error) {
    console.log("error", error);
    alert("failed to update branch");
  }
};

export const fetchAllWorkingHours = () => {};
