import { Box, Button, Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import bookingsTableData from "layouts/Bookings/data/bookingsTableData";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllActivity } from "redux/Actions/Activities";
import { fetchActivityCategories } from "redux/Actions/ActivityCategory";
import { fetchAllBookings } from "redux/Actions/Bookings";

function Booking() {
  const [Rows, setRows] = useState([]);
  const [Columns, setColumns] = useState([]);
  const dispatch = useDispatch();

  const activities = useSelector((state) => state?.activities);
  const bookings = useSelector((state) => state?.bookings);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllActivity({ unmount: false }));
    dispatch(fetchActivityCategories({ unmount: false }));
    dispatch(fetchAllBookings({ unmount: false }));

    return () => {
      dispatch(fetchAllActivity({ unmount: true }));
    };
  }, []);

  useEffect(() => {
    if (bookings?.length > 0) {
      const { columns, rows } = bookingsTableData(bookings, navigate, dispatch, Rows, setRows);
      setRows(rows);
      setColumns(columns);
    }
  }, [bookings]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Box py={5}>
          <MDBox>
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  >
                    <MDTypography variant="h6" color="white">
                      Bookings
                    </MDTypography>
                    <Box>
                      <Button
                        sx={{ background: "#000", ":hover": { background: "#212121" } }}
                        onClick={() => navigate("/booking/new")}
                      >
                        New
                      </Button>
                    </Box>
                  </MDBox>
                  {Rows?.length > 0 && (
                    <MDBox pt={3}>
                      <DataTable
                        table={{ columns: Columns, rows: Rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </MDBox>
                  )}
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default Booking;
