/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

export default function data(countries) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "ID", accessor: "id", width: "10%", align: "left" },
      { Header: "Country", accessor: "country", width: "44%", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: countries?.map((value) => ({
      id: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.country_id}
        </MDTypography>
      ),
      country: (
        <Author
          image={value?.image}
          name={`${value?.name_en} - ${value?.name_ar}`}
          email={value?.city?.map((city) => city?.name_en)?.join()}
        />
      ),
      action: (
        <MDTypography variant="caption" color="text" fontWeight="medium" sx={{ cursor: "pointer" }}>
          Edit
        </MDTypography>
      ),
    })),
  };
}
