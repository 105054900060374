import { FETCH_ACTIVITY_CATEGORY } from "../types";

export default function ActivityCategoryReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ACTIVITY_CATEGORY:
      return action.payload;
    default:
      return state;
  }
}
