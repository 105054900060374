import { Avatar, Badge, Box, Divider, Grid, Paper, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axiosInstance from "helpers/axiosInstance";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchPlan } from "redux/Actions/Plans";
import instance from "../../helpers/axiosInstance";

const aStyle = {
  color: "#3E89D6",
};

function View() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userPlan = useSelector((state) => state?.user_plan);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    dispatch(fetchPlan({ user_plan_id: id, unmount: false }));

    instance.post("/payment/admin/check-total", { user_plan_id: id }).then(({ data }) => {
      setTotalValue(data?.payment?.total_value);
    });

    return () => {
      dispatch(fetchPlan({ unmount: true }));
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} spacing={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "25px",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">Activities for plan</Typography>
            {userPlan?.is_paid && <Badge color="success" badgeContent="paid"></Badge>}
            {userPlan && !userPlan?.is_paid && (
              <Badge sx={{ ml: 2 }} color="warning" badgeContent="unpaid"></Badge>
            )}
          </Box>
          <Box mt={2}>
            {userPlan?.plan?.PlanActivities?.length > 0
              ? userPlan?.plan?.PlanActivities?.map((value, index) => (
                  <Box
                    component={Paper}
                    elevation={5}
                    mt={index === 0 ? 0 : 2}
                    sx={{ padding: "10px 15px" }}
                  >
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Avatar src={value?.branch?.activity?.horizontal_thumbnail} />
                      <Typography>{`${value?.branch?.activity?.name_en} - ${value?.branch?.name_en}`}</Typography>
                    </Box>
                    <Divider color="#212121" sx={{ border: "groove 0.2px #212121" }} />
                    <Box>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography>{`FROM: ${moment(value?.start_time).format(
                          "LLL"
                        )}`}</Typography>
                        <Typography>{`TO: ${moment(value?.end_time).format("LLL")}`}</Typography>
                      </Box>
                    </Box>
                    <Divider color="#212121" sx={{ border: "groove 0.2px #212121" }} />
                    <Box>
                      <Typography>Adult price: {value?.branch?.adult_price} AED</Typography>
                      <Typography>Teenager price: {value?.branch?.teenager_price} AED</Typography>
                      <Typography>Children price: {value?.branch?.children_price} AED</Typography>
                    </Box>
                    {(value?.branch?.supplier_website || value?.branch?.supplier_phone_number) && (
                      <>
                        <Divider color="#212121" sx={{ border: "groove 0.2px #212121" }} />
                        <Box>
                          <Typography>Supplier details</Typography>
                          {value?.branch?.supplier_phone_number && (
                            <Typography>{`Supplier phone: ${value?.branch?.supplier_phone_number}`}</Typography>
                          )}
                          {value?.branch?.supplier_website && (
                            <Typography>
                              <a
                                target="_blank"
                                href={value?.branch?.supplier_website}
                                rel="noreferrer"
                                style={aStyle}
                              >
                                Supplier website
                              </a>
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}
                    <Divider color="#212121" sx={{ border: "groove 0.2px #212121" }} />
                    <Typography>Provider details</Typography>
                    <Box>
                      {value?.branch?.activity?.phone_number && (
                        <Typography>{`Phone: ${value?.branch?.activity?.phone_number}`}</Typography>
                      )}
                      {value?.branch?.activity?.facebook_link && (
                        <Typography>
                          <a
                            target="_blank"
                            href={value?.branch?.activity?.facebook_link}
                            rel="noreferrer"
                            style={aStyle}
                          >
                            Facebook
                          </a>
                        </Typography>
                      )}
                      {value?.branch?.activity?.whatsapp_link && (
                        <Typography>
                          <a
                            target="_blank"
                            href={value?.branch?.activity?.whatsapp_link}
                            rel="noreferrer"
                            style={aStyle}
                          >
                            Whatsapp
                          </a>
                        </Typography>
                      )}
                      {value?.branch?.activity?.website_link && (
                        <Typography>
                          <a
                            target="_blank"
                            href={value?.branch?.activity?.website_link}
                            rel="noreferrer"
                            style={aStyle}
                          >
                            {" "}
                            Website
                          </a>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ))
              : ""}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h3">User details</Typography>
          {userPlan && (
            <Box component={Paper} elevation={2} py={2} px={2} mt={2}>
              <Box sx={{ width: "100%" }} />
              {userPlan?.user?.first_name && (
                <Typography variant="body2">{`Name: ${userPlan?.user?.first_name} ${userPlan?.user?.last_name}`}</Typography>
              )}
              {userPlan?.user?.username && (
                <Typography variant="body2">{`username: ${userPlan?.user?.username}`}</Typography>
              )}
              {userPlan?.user?.email && (
                <Typography variant="body2">{`email: ${userPlan?.user?.email}`}</Typography>
              )}
              {userPlan?.user?.phone_number && (
                <Typography variant="body2">{`email: ${userPlan?.user?.phone_number}`}</Typography>
              )}
              {userPlan?.user?.date_of_birth && (
                <Typography variant="body2">{`date-of-birth: ${moment(
                  userPlan?.user?.date_of_birth
                ).format("DD/MM/YYYY")}`}</Typography>
              )}
            </Box>
          )}
          <Typography variant="h3" mt={1}>
            Total value
          </Typography>
          <Box component={Paper} elevation={2} py={2} px={2} mt={2}>
            <Typography>Total is: {totalValue}</Typography>
          </Box>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default View;
