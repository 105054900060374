/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";
import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MDTypography from "components/MDTypography";
import { deleteQuestionnaireQuestion } from "redux/Actions/Questionnaire";
// import MDAvatar from "components/MDAvatar";

export default function data(countries, navigate, dispatch) {
  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );
  const handleDeleteQuestion = (id) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete this question?");
    if (confirmed) {
      dispatch(deleteQuestionnaireQuestion({ questionnaire_question_id: id }));
    }
  };

  return {
    columns: [
      { Header: "ID", accessor: "id", width: "2%", align: "left" },
      { Header: "Question english", accessor: "q_en", width: "44%", align: "left" },
      { Header: "Question arabic", accessor: "q_ar", align: "right" },
      { Header: "Type", accessor: "type", align: "center" },
      { Header: "Weight", accessor: "weight", align: "center" },
      { Header: "edit", accessor: "action", align: "center" },
      { Header: "Delete", accessor: "delete", align: "center" },
    ],

    rows: countries?.map((value) => ({
      id: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.questionnaire_question_id}
        </MDTypography>
      ),
      q_en: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.question_en}
        </MDTypography>
      ),
      q_ar: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.question_ar}
        </MDTypography>
      ),
      type: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.type}
        </MDTypography>
      ),
      weight: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.weight}
        </MDTypography>
      ),
      action: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/questionnaire/${value?.questionnaire_question_id}`);
          }}
        >
          Edit
        </MDTypography>
      ),
      delete: (
        <IconButton
          onClick={() => {
            handleDeleteQuestion(value?.questionnaire_question_id);
          }}
        >
          <Delete color="error" />
        </IconButton>
      ),
    })),
  };
}
