/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
// import MDBox from "components/MDBox";
import { Delete } from "@mui/icons-material";
import { Switch } from "@mui/material";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { deleteUser } from "redux/Actions/User";
import { verifyUser } from "redux/Actions/User";
// import MDAvatar from "components/MDAvatar";

export default function data(users, navigate, dispatch) {
  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );
  function getAge(dateOfBirth) {
    const today = moment();
    const birthDate = moment(dateOfBirth, "YYYY-MM-DD");
    const age = today.diff(birthDate, "years");
    return age;
  }

  function handleVerifyUser(id, is_verified) {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(
      is_verified
        ? "Are you sure you want un verify this user? "
        : "Are you sure you want to verify this activity?"
    );
    if (confirmed) {
      dispatch(verifyUser({ profile_id: id }, is_verified));
    }
  }

  const handleDeleteUser = (uuid) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete this user?");
    if (confirmed) {
      dispatch(deleteUser({ uuid }));
    }
  };
  return {
    columns: [
      { Header: "ID", accessor: "id", width: "10%", align: "left" },
      { Header: "Name", accessor: "name", width: "20%", align: "left" },
      { Header: "Username", accessor: "username", width: "30%", align: "left" },
      { Header: "Verified", accessor: "verified", width: "30%", align: "left" },
      { Header: "Phone Number", accessor: "phone_number", width: "20%", align: "left" },
      { Header: "Age", accessor: "age", width: "10%", align: "center" },
      { Header: "Delete", accessor: "delete", align: "center" },
    ],

    rows: users?.map((value, index) => ({
      id: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {index + 1}
        </MDTypography>
      ),
      name: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {`${value?.first_name} ${value?.last_name}`}
        </MDTypography>
      ),
      username: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.username}
        </MDTypography>
      ),
      verified: (
        <Switch
          checked={value?.profile?.is_verified}
          onChange={() => handleVerifyUser(value?.profile?.profile_id, value?.profile?.is_verified)}
        />
      ),
      phone_number: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.phone_number || "-"}
        </MDTypography>
      ),
      age: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.date_of_birth ? getAge(value?.date_of_birth) : "-"}
        </MDTypography>
      ),
      action: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={() => navigate(`/users/${value?.username}`)}
          sx={{ cursor: "pointer" }}
        >
          Edit
        </MDTypography>
      ),
      delete: (
        <Delete
          onClick={() => handleDeleteUser(value?.uuid)}
          fontSize="medium"
          color="error"
          fontWeight="medium"
          sx={{
            cursor: "pointer",
          }}
        />
      ),
    })),
  };
}
