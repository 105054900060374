import { FETCH_ALL_COUNTRIES } from "../types";
import instance from "../../helpers/axiosInstance";

export const fetchAllCountries =
  (condition = {}) =>
  async (dispatch) => {
    try {
      if (condition.unmount) {
        return dispatch({
          type: FETCH_ALL_COUNTRIES,
          payload: {},
        });
      }

      const { data } = await instance.post("/countries/fetch-all", condition);

      return dispatch({
        type: FETCH_ALL_COUNTRIES,
        payload:
          data?.countries.length > 0 || Object.keys(data?.countries).length > 0
            ? data?.countries
            : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_ALL_COUNTRIES,
        payload: null,
      });
    }
  };

export const fetchCountry = () => {};
