/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { Close, Save } from "@mui/icons-material";
import { Box, Button, TextField, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateQuestionnaireAnswerIcon,
  updateQuestionnaireAnswer,
} from "redux/Actions/Questionnaire";

function Answers({ val, handleDeleteAnswer }) {
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState(null);

  const [Image, setImage] = useState(null);
  const [ImageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (Object.keys(val)?.length > 0) {
      setAnswer({
        questionnaire_answer_id: val.questionnaire_answer_id,
        icon: val?.icon,
        answer_en: val?.answer_en,
        answer_ar: val?.answer_ar,
      });
    }
  }, [val]);

  const handleAnswerChange = (e) => {
    answer[`${e.target.name}`] = e.target.value;
    setAnswer({ ...answer });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/svg+xml"];

    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types is: svg");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setImage(image);
        setImageFile(files.filter((value) => allowedTypes.includes(value?.type)));
      })
      .catch((error) => console.error(error));
  };

  const handleUpdateQAnswer = (e) => {
    e.preventDefault();
    dispatch(updateQuestionnaireAnswer(answer));
  };

  const updateIcon = (e) => {
    e.stopPropagation();
    dispatch(
      updateQuestionnaireAnswerIcon(
        { questionnaire_answer_id: answer?.questionnaire_answer_id },
        ImageFile
      )
    );
  };
  return (
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          border: "solid 1px #212121",
          padding: "10px 5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            background: "#212121",
            position: "absolute",
            right: "-10px",
            top: "-10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "2px",
            borderRadius: "10px",
            cursor: "pointer",
            zIndex: 500,
          }}
          onClick={() => {
            handleDeleteAnswer(false, answer?.questionnaire_answer_id);
          }}
        >
          <Close color="error" />
        </Box>
        {answer?.icon ? (
          <Box
            sx={{
              background: `url("${answer?.icon}") center center / cover no-repeat`,
              height: "70px",
              width: "70px",
              position: "relative",
              borderRadius: "15px",
            }}
          >
            <Box
              sx={{
                background: "#212121",
                position: "absolute",
                right: "-5px",
                top: "-5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "2px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setAnswer({ ...answer, icon: null });
              }}
            >
              <Close color="error" />
            </Box>
          </Box>
        ) : !answer?.icon && Image?.length > 0 ? (
          Image?.map((value) => (
            <Box
              sx={{
                width: "70px",
                height: "70px",
                borderRadius: "20px",
                background: `url("${value}") center center / cover no-repeat`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                cursor: "pointer",
              }}
              //   onClick={() => handleShowImage(val)}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "-5px",
                  right: "-5px",
                  background: "#212121",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "15px",
                  padding: "2px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setImage([]);
                  setImageFile(null);
                }}
              >
                <Close color="error" />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-5px",
                  right: "-5px",
                  background: "#212121",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "15px",
                  padding: "5px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  updateIcon(e);
                }}
              >
                <Save color="primary" />
              </Box>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "70px",
              borderRadius: "20px",
              background: `linear-gradient(to right, #2193b0, #6dd5ed)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Typography
              color="#FFF"
              variant="body"
              sx={{ position: "absolute", top: "30%", left: "35%" }}
            >
              Upload Icon
            </Typography>
            <input
              type="file"
              accept="image/svg+xml"
              style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
              onChange={handleImageChange}
            />
          </Box>
        )}
        <TextField
          variant="filled"
          label="Answer English"
          name="answer_en"
          value={answer?.answer_en}
          onChange={(e) => handleAnswerChange(e)}
          size="medium"
          type="text"
          multiline
          rows={2}
          fullWidth
          sx={{ margin: "10px 0" }}
        />
        <TextField
          variant="filled"
          label="Answer Arabic"
          name="answer_ar"
          value={answer?.answer_ar}
          onChange={(e) => handleAnswerChange(e)}
          size="medium"
          type="text"
          inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
          multiline
          rows={2}
          fullWidth
        />
        <Button
          size="large"
          sx={{
            width: "50%",
            background: "#3E89D6",
            color: "#FFF",
            ":hover": {
              background: "#7baadb",
            },
            margin: "10px auto",
          }}
          onClick={handleUpdateQAnswer}
        >
          Update
        </Button>
      </Box>
    </Grid>
  );
}

export default Answers;
