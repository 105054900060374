import { FETCH_UTILS } from "../types";

export default function UtilsReducer(state = null, action) {
  switch (action.type) {
    case FETCH_UTILS:
      return action.payload;

    default:
      return state;
  }
}
