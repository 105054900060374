import { Box, Modal } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "50vh",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: "15px",
};
// eslint-disable-next-line react/prop-types
function ImagePreview({ open, handleClose, Image }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            background: `url("${Image}") center center / contain no-repeat`,
            width: "100%",
            height: "100%",
            minWidth: "150px",
          }}
        />
      </Box>
    </Modal>
  );
}

export default ImagePreview;
