// import axios from "axios";
import axios from "axios";
import {
  FETCH_ACTIVITY,
  FETCH_ACTIVITY_PAGE,
  FETCH_ALL_ACTIVITY,
  MODIFY_ACTIVITY_STATE,
} from "../types";
import instance, { baseURL } from "../../helpers/axiosInstance";
// import { uploadHorizontalBranchMedia, uploadVerticalBranchMedia } from "./Branch";

export const fetchActivity =
  (condition = {}) =>
  async (dispatch) => {
    try {
      if (condition.unmount) {
        return dispatch({
          type: FETCH_ACTIVITY,
          payload: {},
        });
      }

      const { data } = await instance.post("/activities/fetch/admin", condition);
      return dispatch({
        type: FETCH_ACTIVITY,
        payload:
          data?.activities.length > 0 || Object.keys(data?.activities).length > 0
            ? data?.activities
            : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_ACTIVITY,
        payload: null,
      });
    }
  };

export const fetchAllActivity =
  (condition = {}) =>
  async (dispatch) => {
    try {
      if (condition.unmount) {
        return dispatch({
          type: FETCH_ALL_ACTIVITY,
          payload: {},
        });
      }

      const { data } = await instance.get("/activities");

      return dispatch({
        type: FETCH_ALL_ACTIVITY,
        payload:
          data?.activities.length > 0 || Object.keys(data?.activities).length > 0
            ? data?.activities
            : null,
      });
    } catch (error) {
      return dispatch({
        type: FETCH_ALL_ACTIVITY,
        payload: null,
      });
    }
  };

export const updateActivity =
  (condition = {}, vertical, horizontal, activityId, loading) =>
  async (dispatch) => {
    const formData = new FormData();
    const formData2 = new FormData();
    try {
      if (vertical?.length > 0) {
        formData.append(`images`, vertical[0], vertical[0]?.name);

        const vert = await axios({
          method: "POST",
          url: `${baseURL}/upload?img_dir=activity`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        });

        // eslint-disable-next-line no-param-reassign
        condition = { ...condition, vertical_thumbnail: vert.data.upload[0] };
      }
      if (horizontal?.length > 0) {
        formData2.append(`images`, horizontal[0], horizontal[0]?.name);

        const hor = await axios({
          method: "POST",
          url: `${baseURL}/upload?img_dir=activity`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData2,
        });

        console.log(hor);
        // eslint-disable-next-line no-param-reassign
        condition = { ...condition, horizontal_thumbnail: hor.data.upload[0] };
      }
      const { data } = await instance.put("/activities", condition);
      if (data?.status >= 200 && data?.status <= 205) {
        loading(false);
        alert("Updated succeessfully");
        setTimeout(() => {
          dispatch(fetchActivity({ activity_id: activityId }));
        }, 500);
      }
    } catch (error) {
      loading(false);
      alert("Failed to update");
      console.log("error", error);
    }
  };

export const createActivity =
  (body = {}, vertical, horizontal, verticalFiles, horizontalFiles, setLoading) =>
  async () => {
    try {
      const formData = new FormData();
      const formData2 = new FormData();

      // eslint-disable-next-line array-callback-return
      const verticalImage = Array?.from(vertical)?.map((val) => {
        formData.append(`images`, val, val?.name);
      });

      // eslint-disable-next-line array-callback-return
      const horizontalImage = Array?.from(horizontal)?.map((val) => {
        formData2.append(`images`, val, val?.name);
      });

      Promise.all(verticalImage).then();
      Promise.all(horizontalImage).then();

      const image1 = await instance({
        method: "POST",
        url: `${baseURL}/upload?img_dir=activity`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      const image2 = await instance({
        method: "POST",
        url: `${baseURL}/upload?img_dir=activity`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData2,
      });

      const activity = await instance.post("/activities", {
        ...body,
        vertical_thumbnail: image1?.data?.upload[0],
        horizontal_thumbnail: image2?.data?.upload[0],
      });

      if (activity?.data?.status >= 200 && activity?.data?.status <= 205) {
        // vertical part
        const formDataVert = new FormData();

        // eslint-disable-next-line array-callback-return
        const verticalImages = Array.from(verticalFiles).map((val) => {
          formDataVert.append(`images`, val, val?.name);
        });

        Promise.all(verticalImages);

        Promise.all(verticalImages).then(async () => {
          await axios({
            method: "POST",
            url: `${baseURL}/upload?img_dir=activity`,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: formDataVert,
          }).then((res) => {
            res?.data?.upload?.map(async (val) => {
              await instance
                .post("/branch-media", {
                  is_image: true,
                  url: val,
                  dimension_type: "vertical",
                  branch_id: activity?.data?.activities?.branch?.branch_id,
                })
                .then(() => {})
                .catch(() => {});
            });
          });
        });

        const formDataHor = new FormData();

        // eslint-disable-next-line array-callback-return
        const horizontalImages = Array.from(horizontalFiles).map((val) => {
          formDataHor.append(`images`, val, val?.name);
        });

        Promise.all(horizontalImages).then(async () => {
          await axios({
            method: "POST",
            url: `${baseURL}/upload?img_dir=activity`,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: formDataHor,
          }).then((res) => {
            res?.data?.upload?.map(async (val) => {
              await instance
                .post("/branch-media", {
                  is_image: true,
                  url: val,
                  dimension_type: "horizontal",
                  branch_id: activity?.data?.activities?.branch?.branch_id,
                })
                .then(() => {})
                .catch(() => {});
            });
          });
        });

        //   // horizontal part
        setLoading(false);
        alert("Activity created successfully");
      }
    } catch (error) {
      setLoading(false);
      alert("Failed to create activity");
      console.log(error);
    }
  };

export const createActivityBulk =
  (body = {}, vertical, horizontal, verticalFiles, horizontalFiles, setLoading) =>
  // eslint-disable-next-line consistent-return
  async () => {
    try {
      // console.log("hor length", horizontalFiles?.length);
      // console.log("hor arr length", Array?.from(horizontalFiles)?.length);
      const formData = new FormData();
      const formData2 = new FormData();

      if (!vertical || vertical?.length < 1) {
        setLoading(false);
        return alert("Please provide vertical image");
      }
      if (!horizontal || horizontal?.length < 1) {
        setLoading(false);

        return alert("Please provide horizontal image");
      }
      // eslint-disable-next-line array-callback-return
      const verticalImage = Array?.from(vertical)?.map((val) => {
        formData.append(`images`, val, val?.name);
      });

      // eslint-disable-next-line array-callback-return
      const horizontalImage = Array?.from(horizontal)?.map((val) => {
        formData2.append(`images`, val, val?.name);
      });

      Promise.all(verticalImage).then();
      Promise.all(horizontalImage).then();

      const image1 = await instance({
        method: "POST",
        url: `${baseURL}/upload?img_dir=activity`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      const image2 = await instance({
        method: "POST",
        url: `${baseURL}/upload?img_dir=activity`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData2,
      });

      const activity = await instance.post("/activities/admin", {
        ...body,
        vertical_thumbnail: image1?.data?.upload[0],
        horizontal_thumbnail: image2?.data?.upload[0],
      });
      const activityDetail = await instance.post("/activities/fetch", {
        activity_id: activity?.data?.activities?.activity_id,
      });

      if (activity?.data?.status >= 200 && activity?.data?.status <= 205) {
        console.log("STATUS IS 200");
        if (
          !activityDetail?.data?.activities?.branch ||
          activityDetail?.data?.activities?.branch?.length <= 0
        ) {
          console.log("WE ARE HERE");
          setLoading(false);
          return alert(
            "Branch was not created due to an error in branch fields, please make sure to add branch names, and prices should be a number from 0 to any number, longitude and latitude are required and city as well"
          );
        }
        if (verticalFiles && verticalFiles?.length > 0) {
          console.log("VERTICAL FILES");
          // vertical part

          // eslint-disable-next-line array-callback-return
          const verticalImages = Array.from(verticalFiles).map(async (val) => {
            const newFormDataVertical = new FormData();
            newFormDataVertical.append(`images`, val, val?.name);

            await axios({
              method: "POST",
              url: `${baseURL}/upload?img_dir=activity`,
              headers: {
                "Content-Type": "multipart/form-data",
              },
              data: newFormDataVertical,
            })
              .then((res) => {
                console.log(activityDetail?.data);
                console.log(res.data);
                res?.data?.upload?.map(async (val) => {
                  await instance
                    .post("/branch-media", {
                      is_image: true,
                      url: val,
                      dimension_type: "vertical",
                      branch_id: activityDetail?.data?.activities?.branch[0]?.branch_id,
                    })
                    .then(() => {})
                    .catch(() => {});
                });
              })
              .catch((err) => {
                console.log(err);
              });
          });
          await Promise.all(verticalImages);
        }

        if (horizontalFiles && horizontalFiles?.length > 0) {
          const horizontalImages = Array.from(horizontalFiles).map(async (val) => {
            const newFormDataHorizontal = new FormData();
            newFormDataHorizontal.append(`images`, val, val?.name);

            await axios({
              method: "POST",
              url: `${baseURL}/upload?img_dir=activity`,
              headers: {
                "Content-Type": "multipart/form-data",
              },
              data: newFormDataHorizontal,
            })
              .then((res) => {
                console.log(res.data);
                res?.data?.upload?.map(async (val) => {
                  await instance
                    .post("/branch-media", {
                      is_image: true,
                      url: val,
                      dimension_type: "horizontal",
                      branch_id: activityDetail?.data?.activities?.branch[0]?.branch_id,
                    })
                    .then(() => {})
                    .catch(() => {});
                });
              })
              .catch((err) => {
                console.log(err);
              });
          });
          await Promise.all(horizontalImages);

          // eslint-disable-next-line array-callback-return
          // const horizontalImages = Array.from(horizontalFiles).map((val) => {
          //   formDataHor.append(`images`, val, val?.name);
          // });

          // Promise.all(horizontalImages).then(async () => {
          //   await axios({
          //     method: "POST",
          //     url: `${baseURL}/upload?img_dir=activity`,
          //     headers: {
          //       "Content-Type": "multipart/form-data",
          //     },
          //     data: formDataHor,
          //   }).then((res) => {
          //     res?.data?.upload?.map(async (val) => {
          //       await instance
          //         .post("/branch-media", {
          //           is_image: true,
          //           url: val,
          //           dimension_type: "horizontal",
          //           branch_id: activityDetail?.data?.activities?.branch[0]?.branch_id,
          //         })
          //         .then(() => {})
          //         .catch(() => {});
          //     });
          //   });
          // });
        }

        //   // horizontal part
        setLoading(false);
        alert("Activity created successfully");
      }
    } catch (error) {
      setLoading(false);
      alert("Failed to create activity");
      console.log(error);
    }
  };

export const deleteActivity =
  (condition = {}) =>
  async (dispatch) => {
    try {
      const { data } = await instance.delete("/activities", { data: condition });

      console.log(data);

      dispatch(fetchAllActivity());
      alert("Activity deleted successfully");
    } catch (error) {
      alert("Something went wrong");
    }
  };

export const hideActivity =
  (condition = {}) =>
  async (dispatch) => {
    try {
      await instance.put("/activities", condition);

      const { data } = await instance.post("/activities/fetch/admin", condition);

      dispatch({ type: MODIFY_ACTIVITY_STATE, payload: data?.activities });
      alert("Activity Hidden successfully");
    } catch (error) {
      alert("Something went wrong");
    }
  };

export const changeActivityPage =
  (condition = {}) =>
  (dispatch) => {
    if (condition?.index && !condition?.unmount) {
      let pageIndex = condition.index / 10;
      pageIndex = Math.floor(pageIndex);
      dispatch({ type: FETCH_ACTIVITY_PAGE, payload: pageIndex });
    } else {
      dispatch({ type: FETCH_ACTIVITY_PAGE, payload: null });
    }
  };
