import React, { useEffect, useState } from "react";
import instance from "../../helpers/axiosInstance";
import moment from "moment";
import { Box, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Bar } from "react-chartjs-2";

function DashboardPage() {
  const [sales, setSales] = useState([]);
  const [usersSignedup, setUsersSignedup] = useState([]);
  const [userCount, setUserCount] = useState(null);
  const [chartUpdateDate, setChartUpdateDate] = useState(null);
  const [userLocationsData, setUserLocationsData] = useState({
    labels: [],
    response: [],
  });

  useEffect(() => {
    instance.post("/admin/users").then(({ data }) => {
      setUserCount(data?.users?.users_count);
    });

    instance.post("/admin/users").then(({ data }) => {
      setUserCount(data?.users?.users_count);
    });

    instance.post("/dashboard/users").then(({ data }) => {
      setUsersSignedup(data?.users?.sales);
      // setChartUpdateDate(moment());
    });

    instance.post("/dashboard/users/locations").then(({ data }) => {
      setUserLocationsData(data?.users);
    });

    instance.post("/dashboard/revenue").then(({ data }) => {
      setSales(data?.plans?.sales);

      setChartUpdateDate(moment());
    });
  }, []);

  function calculatePercentageIncrease(currentRevenue, previousRevenue, array) {
    if (array?.length < 2) return;

    const revenueDifference = currentRevenue - previousRevenue;
    const percentageIncrease = (revenueDifference / previousRevenue) * 100;

    return percentageIncrease;
  }

  const options = {
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="person"
                title="Users"
                count={userCount || 0}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="success"
                title="Monthly Sales"
                description={
                  <>
                    {sales?.datasets?.data?.length < 2 ? null : (
                      <>
                        (
                        <strong>
                          {calculatePercentageIncrease(
                            sales?.datasets?.data[sales?.datasets?.data?.length - 1],
                            sales?.datasets?.data[sales?.datasets?.data?.length - 2]
                          ).toFixed(1)}
                          %
                        </strong>
                        ){" "}
                        {calculatePercentageIncrease(
                          sales?.datasets?.data[sales?.datasets?.data?.length - 1],
                          sales?.datasets?.data[sales?.datasets?.data?.length - 2]
                        ).toFixed(2) > 0
                          ? "increase"
                          : "decrease"}{" "}
                        in monthly sales.
                      </>
                    )}
                  </>
                }
                date={`updated ${moment(chartUpdateDate).fromNow()}`}
                chart={sales}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <ReportsLineChart
                color="info"
                title="Monthly Users"
                description={
                  <>
                    {usersSignedup?.datasets?.data?.length < 2 ? null : (
                      <>
                        (
                        <strong>
                          {calculatePercentageIncrease(
                            usersSignedup?.datasets?.data[
                              usersSignedup?.datasets?.data?.length - 1
                            ],
                            usersSignedup?.datasets?.data[usersSignedup?.datasets?.data?.length - 2]
                          ).toFixed(1)}
                          %
                        </strong>
                        ){" "}
                        {calculatePercentageIncrease(
                          usersSignedup?.datasets?.data[usersSignedup?.datasets?.data?.length - 1],
                          usersSignedup?.datasets?.data[usersSignedup?.datasets?.data?.length - 2]
                        ).toFixed(2) > 0
                          ? "increase"
                          : "decrease"}{" "}
                        in new users.
                      </>
                    )}
                  </>
                }
                date={`updated ${moment(chartUpdateDate).fromNow()}`}
                chart={usersSignedup}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Bar
            data={{
              labels: userLocationsData?.labels,
              datasets: [
                {
                  label: "Number Of Users",
                  data: userLocationsData?.response,
                  backgroundColor: "rgba(75, 192, 192, 0.6)",
                },
              ],
            }}
            options={options}
          />
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default DashboardPage;
