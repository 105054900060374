/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
import { Avatar } from "@mui/material";

export default function data(countries, navigate) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Avatar
        name={name}
        src={image}
        sx={{
          background: !image && "#212121",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          cursor: "pointer",
          ":hover": {
            boxShadow: "unset",
            background: !image && "#000",
            color: "#D3D3D3",
            transition: "300ms",
          },
        }}
      >
        {image && name[0]?.toUpperCase()}
      </Avatar>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "ID", accessor: "id", width: "10%", align: "left" },
      { Header: "User with report", accessor: "name", width: "44%", align: "left" },
      { Header: "Reason", accessor: "reason", width: "44%", align: "left" },
      { Header: "View post", accessor: "view", align: "center" },
    ],

    rows: countries?.map((value) => ({
      id: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.id}
        </MDTypography>
      ),
      name: (
        <Author
          image={value?.user?.profile?.profile_picture}
          name={`${value?.user?.username}`}
          email={`${value?.user?.first_name} ${value?.user?.last_name}`}
        />
      ),
      reason: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {value?.reason}
        </MDTypography>
      ),
      view: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/post/${value?.post_id}`);
          }}
        >
          View post
        </MDTypography>
      ),
    })),
  };
}
