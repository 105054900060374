/* eslint-disable no-unused-vars */
import { Close, Delete, ExpandMore, Replay, Reply } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Select from "react-select";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivity, createActivity, createActivityBulk } from "redux/Actions/Activities";
import { fetchActivityCategories } from "redux/Actions/ActivityCategory";
import { uploadVerticalBranchMedia, uploadHorizontalBranchMedia } from "redux/Actions/Branch";
// import BranchComponent from "./BranchComponent";
import { fetchAllCities } from "redux/Actions/Cities";
import moment from "moment";
import ConfirmationDialog from "components/Confirmation";
import { fetchMainCategories } from "redux/Actions/MainCategory";
import CreatableSelect from "react-select/creatable";
import useNavigatingAway from "examples/useNavigationAway";
import DialogLeavingPage from "examples/DialogLeavingPage";
import NewBranchComp from "./NewBranch";
import ImagePreviewComponent from "./ImagePreview";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function Edit() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "10px",
    transform: "translate(-50%, -50%)",
    width: "10px",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    borderRadius: "15px",
  };

  const dispatch = useDispatch();

  const activityCategories = useSelector((state) => state.activity_categories);
  const mainCategories = useSelector((state) => state.main_categories);

  const [Activity, setActivity] = useState({});
  const [AddBranch, setAddBranch] = useState(false);

  const [Branch, setBranch] = useState([{ name_en: "", name_ar: "" }]);

  const [previewImages, setPreviewImages] = useState([]);
  const [previewImages1, setPreviewImages1] = useState([]);
  const [horizontalPreview, setHorizontalPreview] = useState([]);
  const [verticalPreview, setVerticalPreview] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [Files, setFiles] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [Files1, setFiles1] = useState(null);
  const [horFile, setHorFile] = useState(null);
  const [vertFile, setVertFile] = useState(null);
  const [open, setOpen] = useState(null);
  const [Image, setImage] = useState(null);
  const [Options, setOptions] = useState(null);
  const [ACROptions, setACROptions] = useState(null);
  const [Selected, setSelected] = useState(null);
  const [ACR, setACR] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [seasonal, setSeasonal] = useState(false);

  const [Loading, setLoading] = useState(false);

  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(true);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);

  const handleClose = (e) => {
    setOpen(!open);
  };

  const handleShowImage = (img) => {
    setOpen(true);
    setImage(img);
  };

  const handleChangeKeywords = (val) => {
    setKeywords(val);
  };

  useEffect(() => {
    if (Activity && Object.keys(Activity)?.length > 0) {
      Activity.keywords = keywords?.map((val) => val?.value);
      setActivity(Activity);
    }
  }, [keywords]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setPreviewImages(image);
        setFiles(files.filter((val) => allowedTypes.includes(val?.type)));
      })
      .catch((error) => console.error(error));
  };

  const handleImageChange1 = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setPreviewImages1(image);
        setFiles1(files.filter((val) => allowedTypes.includes(val?.type)));
      })
      .catch((error) => console.error(error));
  };

  const handleHorizontalImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setHorizontalPreview(image);
        setHorFile(files.filter((val) => allowedTypes.includes(val?.type)));
      })
      .catch((error) => console.error(error));
  };

  const handleVerticalImageChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const promises = files.map(
      (file) =>
        // eslint-disable-next-line consistent-return
        new Promise((resolve, reject) => {
          const fileType = file.type;
          if (!allowedTypes.includes(fileType)) {
            // reject();
            // eslint-disable-next-line no-promise-executor-return
            return alert("Only allowed types are: jpg, jpeg, png");
          }
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        })
    );

    Promise.all(promises)
      .then((image) => {
        setVerticalPreview(image);
        setVertFile(files.filter((val) => allowedTypes.includes(val?.type)));
      })
      .catch((error) => console.error(error));
  };
  // eslint-disable-next-line camelcase

  // eslint-disable-next-line no-unused-vars
  const handleSubmitVerticalImage = (e, branchId) => {
    e.preventDefault();
    dispatch(uploadVerticalBranchMedia(Files, branchId));
  };
  const handleSubmitHorizontalImage = (e, branchId) => {
    e.preventDefault();
    dispatch(uploadHorizontalBranchMedia(Files1, branchId));
  };

  const handleActivityChange = (e) => {
    Activity[`${e.target.name}`] = e.target.value;
    setActivity({ ...Activity });
  };

  const handleActivityCategoryChange = (option) => {
    Activity.activity_category_id = option?.value;
    setActivity({ ...Activity });
  };

  useEffect(() => {
    dispatch(fetchActivityCategories({ unmount: false }));
    dispatch(fetchMainCategories({ unmount: false }));
    dispatch(fetchAllCities({ unmount: false }));
  }, []);
  useEffect(
    () => () => {
      dispatch(fetchActivity({ unmount: true }));
    },
    []
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(updateActivity(Activity));
  };

  const handleAddBranch = () => {
    const arr = [...Branch];
    arr.push({
      name_en: "",
      name_ar: "",
      working_days: [
        {
          day_of_week: 1,
          opening_time: "09:00:00",
          closing_time: "16:00:00",
        },
        {
          day_of_week: 2,
          opening_time: "09:00:00",
          closing_time: "16:00:00",
        },
        {
          day_of_week: 3,
          opening_time: "09:00:00",
          closing_time: "16:00:00",
        },
        {
          day_of_week: 4,
          opening_time: "09:00:00",
          closing_time: "16:00:00",
        },
        {
          day_of_week: 5,
          opening_time: "09:00:00",
          closing_time: "16:00:00",
        },
        {
          day_of_week: 6,
          opening_time: "09:00:00",
          closing_time: "16:00:00",
        },
        {
          day_of_week: 7,
          opening_time: "09:00:00",
          closing_time: "16:00:00",
        },
      ],
    });
    setBranch(arr);
  };

  const handleAddACR = () => {
    const arr = [...ACR];

    arr.push({
      percentage: 0,
    });
    setACR(arr);
  };

  const handleRemoveACR = (index) => {
    const arr = [...ACR];

    arr.splice(index, 1);
    console.log(arr);

    setACR(arr);
  };

  useEffect(() => {
    if (activityCategories?.length > 0) {
      setOptions(
        activityCategories?.map((val) => ({
          value: val?.activity_category_id,
          label: `${val?.main_category?.name_en} - ${val?.name_en}`,
        }))
      );
    }
  }, [activityCategories]);

  useEffect(() => {
    if ((!Options || Options?.length) === 0 && activityCategories?.length > 0) {
      setOptions(
        activityCategories?.map((val) => ({
          value: val?.activity_category_id,
          label: `${val?.main_category?.name_en} - ${val?.name_en}`,
        }))
      );
    } else if (
      (!Options || Options?.length) &&
      (!activityCategories || activityCategories?.length === 0)
    ) {
      dispatch(fetchActivityCategories({ unmount: false }));
    }
  }, [Options]);

  const handleSubmitAll = (e) => {
    e.preventDefault();
    if (Object.keys(Activity)?.length < 1 || Branch?.length < 1) {
      alert("Please add all details");
      return;
    }
    // console.log({
    //   ...Activity,
    //   Branch,
    // });
    // if (
    //   !Branch?.branch_name_ar ||
    //   !Branch?.branch_name_en ||
    //   !Branch?.latitude ||
    //   !Branch?.longitude ||
    //   !Branch?.adult_price ||
    //   !Branch?.children_price ||
    //   !Branch?.city_id
    // ) {
    //   // eslint-disable-next-line no-alert, consistent-return
    //   return alert("Please fill all branch details");
    // }
    setLoading(true);
    const body = {
      ...Activity,
      branches: [...Branch],
      activity_category_relation: [...ACR],
      // working_days: WorkingHours,
    };
    // console.log(body);
    dispatch(createActivityBulk(body, vertFile, horFile, Files, Files1, setLoading));
  };

  const deleteImageFromPreview = (index, e) => {
    e.stopPropagation();
    const newArray = [...previewImages];
    const newFiles = [...Files];
    newArray?.splice(index, 1);
    newFiles?.splice(index, 1);
    setPreviewImages(newArray);
    setFiles(newFiles);
  };
  const deleteImageFromPreview1 = (index, e) => {
    e.stopPropagation();
    const newArray = [...previewImages1];
    const newFiles = [...Files1];
    newArray?.splice(index, 1);
    newFiles?.splice(index, 1);
    setPreviewImages1(newArray);
    setFiles1(newFiles);
  };

  const handleNewActivityCategoryRelationChangeMain = (option, index) => {
    const arr = [...ACR];
    arr[index].main_category_id = option?.value;
    arr[index].value = option?.value;
    arr[index].label = option?.label;
    setACR(arr);
  };

  const handleNewActivityCategoryRelationChange = (e, index) => {
    const arr = [...ACR];
    arr[index][`${e.target.name}`] = e.target.value;
    setACR(arr);
  };

  useEffect(() => {
    if (mainCategories && mainCategories?.length) {
      const arr = mainCategories?.map((val) => ({
        value: val?.main_category_id,
        label: val?.name_en,
      }));
      setACROptions(arr);
    }
  }, [mainCategories]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Box pb={2} component="form" onSubmit={handleSubmitAll}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {!horizontalPreview ||
              (horizontalPreview?.length < 1 && (
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "20px",
                    background: `linear-gradient(to right, #2193b0, #6dd5ed)`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Typography color="#FFF" sx={{ position: "absolute", top: "45%", left: "35%" }}>
                    Upload horizontal image
                  </Typography>
                  <input
                    type="file"
                    accept="image/jpeg,image/jpg,image/png"
                    style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
                    onChange={handleHorizontalImageChange}
                  />
                </Box>
              ))}
            {horizontalPreview?.length > 0 &&
              horizontalPreview?.map((val) => (
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "20px",
                    background: `url("${val}") center center / cover no-repeat`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShowImage(val)}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "#212121",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "15px",
                      padding: "2px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setHorizontalPreview([]);
                      setHorFile(null);
                    }}
                  >
                    <Close color="error" />
                  </Box>
                </Box>
              ))}
          </Grid>
          <Grid item xs={3}>
            {!verticalPreview ||
              (verticalPreview?.length < 1 && (
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "20px",
                    background: `linear-gradient(to right, #2193b0, #6dd5ed)`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Typography color="#FFF" sx={{ position: "absolute", top: "40%", left: "25%" }}>
                    Upload vertical image
                  </Typography>
                  <input
                    type="file"
                    accept="image/jpeg,image/jpg,image/png"
                    style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
                    onChange={handleVerticalImageChange}
                  />
                </Box>
              ))}
            {verticalPreview?.length > 0 &&
              verticalPreview?.map((val) => (
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "20px",
                    background: `url("${val}") center center / cover no-repeat`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShowImage(val)}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "#212121",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "15px",
                      padding: "2px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setVerticalPreview([]);
                      setVertFile(null);
                    }}
                  >
                    <Close color="error" />
                  </Box>
                </Box>
              ))}
          </Grid>
        </Grid>
        <Box pt={2}>
          <Typography>Activity details</Typography>
          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              variant="filled"
              label="Name English"
              name="activity_name_en"
              type="text"
              value={Activity?.activity_name_en}
              required
              onChange={(e) => handleActivityChange(e)}
              size="medium"
              sx={{ width: "50%" }}
            />
            <TextField
              variant="filled"
              label="Name Arabic"
              name="activity_name_ar"
              value={Activity.activity_name_ar}
              onChange={(e) => handleActivityChange(e)}
              inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
              size="medium"
              required
              sx={{ width: "50%", textAlign: "right" }}
            />
          </Box>
          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              multiline
              sx={{ width: "50%" }}
              rows={6}
              name="description_en"
              variant="filled"
              label="Description English"
              required
              value={Activity.description_en}
              onChange={(e) => handleActivityChange(e)}
            />
            <TextField
              multiline
              rows={6}
              sx={{ width: "50%" }}
              variant="filled"
              name="description_ar"
              inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
              label="Description Arabic"
              required
              value={Activity.description_ar}
              onChange={(e) => handleActivityChange(e)}
            />
          </Box>
          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={6}
              variant="filled"
              label="Restriction English"
              name="restriction_en"
              value={Activity.restriction_en}
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />

            <TextField
              fullWidth
              multiline
              rows={6}
              variant="filled"
              label="Restriction Arabic"
              inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
              name="restriction_ar"
              value={Activity.restriction_ar}
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />
          </Box>
          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={6}
              variant="filled"
              label="Availability English"
              name="availability_en"
              value={Activity.availability_en}
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />

            <TextField
              fullWidth
              multiline
              rows={6}
              variant="filled"
              label="Availability Arabic"
              inputProps={{ style: { textAlign: "right", direction: "rtl" } }}
              name="availability_ar"
              value={Activity.availability_ar}
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />
          </Box>
          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              fullWidth
              variant="filled"
              label="Duration in minutes"
              name="duration_mins"
              required
              value={Activity.duration_mins}
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />

            <TextField
              fullWidth
              variant="filled"
              label="Phone number"
              name="phone_number"
              value={Activity.phone_number}
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />
          </Box>

          <>
            <Typography pt={2}>Seasonal closing time</Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={
                    Activity?.seasonal_closing_start_time
                      ? moment(Activity?.seasonal_closing_start_time)
                      : null
                  }
                  name="seasonal_closing_start_time"
                  renderInput={(props) => (
                    <TextField {...props} variant="filled" fullWidth required={false} />
                  )}
                  inputFormat="DD/MM/YYYY"
                  defaultValue={null}
                  onChange={(date) => {
                    setActivity({
                      ...Activity,
                      seasonal_closing_start_time: moment(date).format(),
                    });
                  }}
                  label="Seasonal closing start time"
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={
                    Activity?.seasonal_closing_end_time
                      ? moment(Activity?.seasonal_closing_end_time)
                      : null
                  }
                  name="seasonal_closing_end_time"
                  renderInput={(props) => (
                    <TextField {...props} variant="filled" fullWidth required={false} />
                  )}
                  inputFormat="DD/MM/YYYY"
                  defaultValue={null}
                  onChange={(date) => {
                    setActivity({
                      ...Activity,
                      seasonal_closing_end_time: moment(date).format(),
                    });
                  }}
                  label="Seasonal closing end time"
                />
              </LocalizationProvider>
            </Box>
          </>

          <Typography pt={2}>Social Media</Typography>
          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              fullWidth
              variant="filled"
              label="Facebook link"
              name="facebook_link"
              value={Activity.facebook_link}
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />

            <TextField
              fullWidth
              variant="filled"
              label="Instagram link"
              name="instagram_link"
              value={Activity.instagram_link}
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />
          </Box>
          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              fullWidth
              variant="filled"
              label="Twitter Link"
              value={Activity.twitter_link}
              name="twitter_link"
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />

            <TextField
              fullWidth
              variant="filled"
              label="Tiktok link"
              value={Activity.tiktok_link}
              name="tiktok_link"
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />
          </Box>

          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              fullWidth
              variant="filled"
              label="Snapchat Link"
              value={Activity.snapchat_link}
              name="snapchat_link"
              onChange={(e) => handleActivityChange(e)}
              sx={{ width: "50%" }}
            />

            <TextField
              fullWidth
              variant="filled"
              label="Whatsapp Link"
              value={Activity.whatsapp_link}
              onChange={(e) => handleActivityChange(e)}
              name="whatsapp_link"
              sx={{ width: "50%" }}
            />
          </Box>
          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              fullWidth
              variant="filled"
              label="Website Link"
              value={Activity.website_link}
              onChange={(e) => handleActivityChange(e)}
              name="website_link"
              sx={{ width: "50%" }}
            />

            <TextField
              fullWidth
              variant="filled"
              label="Threads Link"
              value={Activity.threads_link}
              onChange={(e) => handleActivityChange(e)}
              name="threads_link"
              sx={{ width: "50%" }}
            />
          </Box>
          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              fullWidth
              variant="filled"
              label="Youtube Link"
              value={Activity.youtube_link}
              onChange={(e) => handleActivityChange(e)}
              name="youtube_link"
              sx={{ width: "50%" }}
            />

            <TextField
              fullWidth
              variant="filled"
              label="Linked Link"
              value={Activity.linkedin_link}
              onChange={(e) => handleActivityChange(e)}
              name="linkedin_link"
              sx={{ width: "50%" }}
            />
          </Box>
          <Box
            pt={2}
            sx={{ display: "flex", justifyContent: "flex-start", gap: 2, alignItems: "center" }}
          >
            <Typography>Category</Typography>
            <IconButton
              onClick={() => {
                setOptions(null);
                dispatch(fetchActivityCategories({ unmount: false }));
              }}
            >
              <Replay />
            </IconButton>
          </Box>
          {activityCategories?.length > 0 && (
            <Grid item xs={12} md={6}>
              <Select
                options={Options}
                placeholder="Select Sub Category..."
                onChange={handleActivityCategoryChange}
                required
              />
            </Grid>
          )}
          <Box pt={2}>
            <Typography>Key-words</Typography>
            <CreatableSelect
              isClearable
              placeholder="Add keywords"
              noOptionsMessage={() => "No key-words"}
              isMulti
              onChange={(val) => handleChangeKeywords(val)}
              options={keywords}
              value={keywords}
            />
          </Box>
          <Typography pt={2}>Precentages with main categories</Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => handleAddACR()}>Add new Activity relation</Button>
          </Box>
          {ACR?.length > 0 &&
            ACROptions?.length > 0 &&
            ACR?.map((val, index) => (
              <Box>
                <Box pt={2} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Box sx={{ width: "50%" }}>
                    <Select
                      // eslint-disable-next-line array-callback-return, consistent-return
                      options={ACROptions?.filter((main) => {
                        const ACRIndex = ACR?.filter(
                          (obj) => main?.value === obj?.main_category_id
                        );
                        if (ACRIndex?.length === 0) {
                          return main;
                        }
                      })}
                      placeholder="Select Main category..."
                      onChange={(option) =>
                        handleNewActivityCategoryRelationChangeMain(option, index)
                      }
                      value={val?.label && val?.value ? val : null}
                    />
                  </Box>
                  <TextField
                    fullWidth
                    type="number"
                    variant="filled"
                    label="Percentage"
                    size="small"
                    name="percentage"
                    InputProps={{ inputProps: { type: "number", min: 1, max: 100 } }}
                    value={val?.percentage}
                    onChange={(e) => handleNewActivityCategoryRelationChange(e, index)}
                    sx={{ width: "50%" }}
                  />
                  <IconButton
                    aria-label="Delete"
                    onClick={(e) => handleRemoveACR(index)}
                    sx={{
                      background: "#ffbaba",
                      color: "#ff0000",
                      width: "40px",
                      height: "40px",
                      ":hover": {
                        background: "#ffe6e6",
                      },
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            ))}
          <Typography pt={2}>More information</Typography>
          <Box pt={2} sx={{ display: "flex", gap: 2 }}>
            <TextField
              variant="filled"
              label="Target audience from"
              type="number"
              value={Activity.target_audience_from}
              onChange={(e) => handleActivityChange(e)}
              name="target_audience_from"
              sx={{ width: "50%" }}
            />
            <TextField
              variant="filled"
              label="Target audience to"
              type="number"
              value={Activity.target_audience_to}
              onChange={(e) => handleActivityChange(e)}
              name="target_audience_to"
              sx={{ width: "50%" }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={() => handleAddBranch()}>Add a branch</Button>
        </Box>
        <Grid pt={2}>
          {Branch?.length > 0 &&
            Branch?.map((val, index, branchArray) => (
              <Accordion key={val?.activity_category_id}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>New Branch</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <NewBranchComp
                    Branch={branchArray}
                    oneBranch={val}
                    branchIdx={index}
                    setBranch={setBranch}
                    Selected={Selected}
                  />
                </AccordionDetails>
              </Accordion>
            ))}

          {/* {activity?.branch?.length > 0 &&
            activity?.branch?.map((val) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{`${val?.name_en} ${val?.name_ar}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <BranchComponent branch={val} />
                </AccordionDetails>
              </Accordion>
            ))} */}
        </Grid>
        <Typography pt={2}>Vertical Images</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            background: "#FFF",
            overflow: "scroll",
          }}
          py={2}
          px={2}
          spacing={5}
        >
          {previewImages?.map((val, index) => (
            <Box
              sx={{
                background: `url("${val}") center center / cover no-repeat`,
                width: "150px",
                height: "150px",
                position: "relative",
                borderRadius: "10px",
                minWidth: "150px",
                cursor: "pointer",
              }}
              onClick={() => handleShowImage(val)}
            >
              <Box
                sx={{
                  background: "#121212",
                  padding: "5px",
                  position: "absolute",
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  right: "2px",
                  top: "2px",
                  cursor: "pointer",
                }}
                onClick={(e) => deleteImageFromPreview(index, e)}
              >
                <Close fontSize="medim" color="error" />
              </Box>
            </Box>
          ))}
          <Box
            sx={{
              background: `#212121`,
              width: "150px",
              height: "150px",
              position: "relative",
              borderRadius: "10px",
              padding: "10px",
              minWidth: "150px",
            }}
          >
            <Box
              p={2}
              sx={{
                border: "dashed 2px #3E89D6",
                width: "100%",
                height: "100%",
                padding: "0",
              }}
            >
              <p style={{ position: "absolute", top: "40%", right: "30%", color: "#FFF" }}>
                Upload
              </p>
              <input
                type="file"
                accept="image/jpeg,image/jpg,video/mp4,video/x-m4v"
                multiple
                style={{ opacity: "0", width: "100%", height: "100%", cursor: "pointer" }}
                onChange={handleImageChange}
              />
            </Box>
          </Box>
        </Box>

        <Typography pt={2}>Horizontal Images</Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            background: "#FFF",
            overflow: "scroll",
          }}
          py={2}
          px={2}
          spacing={5}
        >
          {previewImages1?.map((val, index) => (
            <Box
              sx={{
                background: `url("${val}") center center / cover no-repeat`,
                width: "150px",
                height: "150px",
                position: "relative",
                borderRadius: "10px",
                minWidth: "150px",
                cursor: "pointer",
              }}
              onClick={() => handleShowImage(val)}
            >
              <Box
                sx={{
                  background: "#121212",
                  padding: "5px",
                  position: "absolute",
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  right: "2px",
                  top: "2px",
                  cursor: "pointer",
                }}
                onClick={(e) => deleteImageFromPreview1(index, e)}
              >
                <Close fontSize="medim" color="error" />
              </Box>
            </Box>
          ))}
          <Box
            sx={{
              background: `#212121`,
              width: "150px",
              height: "150px",
              position: "relative",
              borderRadius: "10px",
              padding: "10px",
              minWidth: "150px",
            }}
          >
            <Box
              p={2}
              sx={{
                border: "dashed 2px #3E89D6",
                width: "100%",
                height: "100%",
                padding: "0",
              }}
            >
              <p style={{ position: "absolute", top: "40%", right: "30%", color: "#FFF" }}>
                Upload
              </p>
              <input
                type="file"
                accept="image/jpeg,image/jpg,image/png"
                multiple
                style={{
                  opacity: "0",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  border: "solid 2px",
                }}
                onChange={(e) => handleImageChange1(e)}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "100%" }} py={3}>
          <Button
            size="large"
            sx={{
              background: "#3E89D6",
              color: "#FFF",
              ":hover": {
                background: "#7baadb",
              },
            }}
            fullWidth
            color="error"
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </Box>
      {open && <ImagePreviewComponent open={open} handleClose={handleClose} Image={Image} />}
      {Loading && (
        <Modal open={Loading}>
          <Box sx={{ ...style, position: "relative" }}>
            <img src="/Spinner-1s-200px.gif" style={{ width: "50px", height: "50px" }} alt="" />
          </Box>
        </Modal>
      )}
      <ConfirmationDialog message="Are you sure you want to close this page?" />
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
    </DashboardLayout>
  );
}

export default Edit;
